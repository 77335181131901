import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import { isNil } from 'lodash';
import {subscribe, unsubscribe} from '../helpers/eventbus';

// Connects to data-controller="ai-showcase-index"
// Infinite scroll example: https://codepen.io/deap82/pen/LerMVo
export default class extends Controller {
  static targets = ['item'];

  intersectionObserver = null;
  page =  1;
  exponentialBackoff = 1;

  connect() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.0
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      // console.log('entries', entries);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // entry.target.firstElementChild.classList.remove('d-none');
          this.onItemVisible(entry.target, entry);
        }else{
          // console.log('else', entry.target, entry);
          entry.target.firstElementChild.classList.add('d-none');
        }
      });
    }, options);

    // Observe all items
    this.itemTargets.forEach((target) => {
      this.itemTargetConnected(target);
    });

    // Allow show modal to ask us to load the next page so user can keep clicking Next
    this.onFetchNextPageSubscription = subscribe('ai-showcase-fetchNextPage', this.fetchNextPage.bind(this));
  }

  disconnect() {
    unsubscribe('ai-showcase-fetchNextPage', this.onFetchNextPageSubscription);
  }

  // This gets run per target named "item" being connected - it runs before connect() above
  // and then it runs again as additional image "items" are added to the page via infinite scroll / turbo stream
  itemTargetConnected(target){
    if(this.intersectionObserver){
      this.intersectionObserver.observe(target);
    }
  }

  onItemVisible(itemEl) {
    // show image when it's in the viewport (it already downloads once placeholder exists)
    itemEl.firstElementChild.classList.remove('d-none');

    if(!isNil(itemEl.dataset.loadNextPageUponView)){
      this.fetchNextPage();
    }
  }

  // {force} instead of just `force` so that when this is called with a generic object param from the subscription event, it's not always forced
  async fetchNextPage({force}={}){
    // This ensures we only trigger starting one next page load at a time
    // This function also gets called from another controller, which will not have the specific el, so look it up
    let elWithTrigger = this.itemTargets.find(el => !isNil(el.dataset.loadNextPageUponView));
    if(!force && !elWithTrigger){
      return;
    }

    // Remove the trigger so we don't trigger again (until next page loads which will include an el that has this attribute again)
    if(elWithTrigger){
      delete elWithTrigger.dataset.loadNextPageUponView;
    }

    // preserve search param
    let urlParams = new URLSearchParams(window.location.search);
    let search = urlParams.get('search');
    let page = this.page + 1;

    let query = {
      page
    };
    if(search){
      query.search = search;
    }

    try{
      await post('/ai/community/next_page_results', {
        query,
        responseKind: 'turbo-stream'
      });
      this.page = page; // now that the results are here we're on this page
      this.exponentialBackoff = 1;

    }catch (e) {
      // Auto retry with exponential backup
      let timeout = this.exponentialBackoff;
      // console.log('Error fetching next page, retrying in ' + timeout + ' seconds');
      this.exponentialBackoff = this.exponentialBackoff * 2;
      setTimeout(() => {
        // console.log('Retrying fetchNextPage');
        this.fetchNextPage({force: true});
      }, timeout * 1000);

      throw e;
    }
  }

}
