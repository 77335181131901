import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import { emit } from '../helpers/eventbus';

// Connects to data-controller="community-showcase-modal"
export default class extends Controller {
  static targets = ['frame', 'modal', 'loaderTemplate', 'autoClick', 'item', 'nextButton', 'previousButton'];
  static values = {};

  hasInitiallyOpenedModal = false;

  attachmentId = null;
  nextAttachmentId = null;
  previousAttachmentId = null;

  returnPath = null;

  initialize() {
    if (!this.hasModalTarget) {
      return;
    }
    this.modal = new Modal(this.modalTarget);
    this.modalTarget.addEventListener('hidden.bs.modal', this.resetLoader.bind(this));
  }

  // Automatically called when 'item' exists in DOM now and is connected to this controller
  itemTargetConnected(){
    this.refreshNextPreviousItems();
  }

  refreshNextPreviousItems(){
    if(!this.hasNextButtonTarget || !this.hasPreviousButtonTarget) return;

    this.refreshAttachmentIds();

    // Show or Hide next/previous buttons based on if we know which IDs they go to
    if(this.nextAttachmentId){
      this.nextButtonTarget.classList.remove('d-none');
    }else{
      this.nextButtonTarget.classList.add('d-none');
    }

    if(this.previousAttachmentId){
      this.previousButtonTarget.classList.remove('d-none');
    }else{
      this.previousButtonTarget.classList.add('d-none');
    }
  }

  // Determine which Item ID is next/previous based on the grid elements behind the modal
  refreshAttachmentIds(){
    this.nextAttachmentId = null;
    this.previousAttachmentId = null;

    if(!this.attachmentId) return;

    let elements = this.itemTargets;
    let curIndex = elements.findIndex(el => el.dataset.attachmentId === this.attachmentId);
    if(curIndex < 0) return;

    let nextEl = elements[curIndex + 1];
    let previousEl = elements[curIndex - 1];
    if(nextEl) this.nextAttachmentId = nextEl.dataset.attachmentId;
    if(previousEl) this.previousAttachmentId = previousEl.dataset.attachmentId;

    // Start loading next page if we're within ~7 items of the end of the page
    if(curIndex + 7 > elements.length){
      emit('ai-showcase-fetchNextPage', { });
    }

  }

  async viewNextItem() {
    this.viewItemId(this.nextAttachmentId, 'fadeInRight');
  }

  async viewPreviousItem() {
    this.viewItemId(this.previousAttachmentId, 'fadeInLeft');
  }

  async viewItem(event){
    event.preventDefault();

    let attachmentId = event.currentTarget.dataset.attachmentId;
    this.viewItemId(attachmentId);
  }

  async viewItemId(attachmentId, animation = '') {
    if (!attachmentId) return;

    // Only save pre-modal path if we're not already in the modal (like clicking Next)
    if(!this.returnPath){
      this.returnPath = location.pathname + location.search;
    }

    // Create our own background immediately as the turboframe starts loading
    this.createAdditionalBackdrop();

    // `/ai/community/item/31244`
    let path = `/ai/community/item/${attachmentId}`;
    if (this.hasFrameTarget && path) {
      this.attachmentId = attachmentId;
      this.replaceHistoryState(path);

      this.frameTarget.src = `${path}?animation=${animation}`;
      // wait for the trubo-frame to load before showing modal
      await this.frameTarget.loaded;

      this.modal.show();
      // the intention is to remove the most recent created backdrop (that the modal itself added), so it's removed before it has a chance to fade in
      // (We already have our own backdrop, so we don't need another one anyway, and leaving it causes a strobe effect)
      this.removeModalBackdrop();

      this.refreshNextPreviousItems();
    }
  }

  closeModal(){
    this.modal.hide();
  }

  createAdditionalBackdrop(){
    if(this.backdropDiv) return;

    this.backdropDiv = document.createElement('div');
    this.backdropDiv.classList.add('modal-backdrop', 'fade', 'show');
    document.body.classList.add('modal-open');
    document.body.appendChild(this.backdropDiv);
  }

  removeAdditionalBackdrop(){
    if(!this.backdropDiv) return;

    this.backdropDiv.remove();
    document.body.classList.remove('modal-open');
  }

  removeModalBackdrop(){
    let modalBackdrops = document.querySelectorAll('.modal-backdrop');
    // remove last modalBackdrops
    if (modalBackdrops && modalBackdrops.length > 1) {
      modalBackdrops[modalBackdrops.length - 1].remove();
    }
  }

  resetLoader() {
    this.removeAdditionalBackdrop();
    this.replaceHistoryState(this.returnPath);
    // Clear return path so we know to store it again next time we open the modal
    this.returnPath = null;
    this.frameTarget.innerHTML = this.loaderTemplateTarget.innerHTML;
  }

  replaceHistoryState(path) {
    let currentState = history.state || {};

    history.replaceState(currentState, '', path);
  }

  autoClickTargetConnected() {
    if (!this.hasInitiallyOpenedModal) {
      this.autoClickTarget.click();
      this.hasInitiallyOpenedModal = true;
    }
  }

}
