import { Controller } from '@hotwired/stimulus';

// We want to show a special el when the image url is either empty, the el is missing, or if the browser fails to load the image
export default class extends Controller {
  // 'missing' is the el that should be shown once image is determined unloadable
  static targets = ['image', 'missing'];

  connect() {
    // if there is no image el, the image is considered missing
    if (!this.hasImageTarget) {
      this.onMissing();
      return;
    }

    let imgSrc = this.getImageSrc();
    // this.imageTarget.src likes to default to current browser url, which is never going to be an image here
    if (!imgSrc || imgSrc === window.location.href) {
      this.onMissing();
    } else {
      this.addErrorHandler();
      if (this.getImageSizes()) {
        this.imageTarget.sizes = this.getImageSizes();
      }
      if (imgSrc) {
        this.imageTarget.src = imgSrc;
      }
      if (this.getImageSrcSet()) {
        this.imageTarget.srcset = this.getImageSrcSet();
      }
    }
  }

  getImageSrc() {
    return this.imageTarget.dataset.src;
  }
  getImageSrcSet() {
    return this.imageTarget.dataset.srcset;
  }
  getImageSizes() {
    return this.imageTarget.dataset.sizes;
  }

  addErrorHandler() {
    this.imageTarget.onerror = () => {
      this.onMissing();
    };
  }

  onMissing() {
    if (this.hasImageTarget) {
      this.imageTarget.classList.add('d-none');
    }
    if (this.hasMissingTarget) {
      this.missingTarget.classList.remove('d-none');
    }
  }
}
