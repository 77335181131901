import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="ai-manage-show-toggler"
export default class extends Controller {
  static targets = [
    'showImageButton',
    'showUserButton',
    'showHistoryButton',
    'showImageContent',
    'showUserContent',
    'showHistoryContent'
  ];

  connect() {
    let activeTab = localStorage.getItem('ManageActiveTab');
    if (!activeTab) {
      return;
    }

    switch (activeTab) {
      case 'history':
        this.showHistoryTab(null);
        break;
      case 'user':
        this.showUserTab(null);
        break;
      case 'image':
        this.showImageTab(null);
        break;
      default:
        break;
    }
  }

  showHistoryTab(event) {
    event?.preventDefault();
    this.showImageButtonTarget.classList.remove('active');
    this.showUserButtonTarget.classList.remove('active');
    this.showHistoryButtonTarget.classList.add('active');
    this.setLocalStorage('history');

    this.showHistoryContentTarget.classList.remove('d-none');
    this.showImageContentTarget.classList.add('d-none');
    this.showUserContentTarget.classList.add('d-none');
  }

  showImageTab(event) {
    event?.preventDefault();
    this.showHistoryButtonTarget.classList.remove('active');
    this.showUserButtonTarget.classList.remove('active');
    this.showImageButtonTarget.classList.add('active');
    this.setLocalStorage('image');

    this.showImageContentTarget.classList.remove('d-none');
    this.showHistoryContentTarget.classList.add('d-none');
    this.showUserContentTarget.classList.add('d-none');
  }

  showUserTab(event) {
    event?.preventDefault();
    this.showHistoryButtonTarget.classList.remove('active');
    this.showImageButtonTarget.classList.remove('active');
    this.showUserButtonTarget.classList.add('active');
    this.setLocalStorage('user');

    this.showUserContentTarget.classList.remove('d-none');
    this.showHistoryContentTarget.classList.add('d-none');
    this.showImageContentTarget.classList.add('d-none');
  }

  setLocalStorage(value) {
    localStorage.setItem('ManageActiveTab', value);
  }
}
