import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['rangeSection','fixedType','negotiableType'];

  static values = {
    resetOnHideModalId: String,
  };

  connect() {
    // This is triggered when clearing the form
    this.fixedTypeTarget.addEventListener('custom-change', ()=> {
      this.typeChanged();
    });
    this.typeChanged();
  }

  typeChanged(){
    this.setRangeSectionDisabled(this.negotiableTypeTarget.checked);
  }

  uncheckBothTypes(event){
    event.preventDefault();
    this.fixedTypeTarget.checked = false;
    this.negotiableTypeTarget.checked = false;
    this.typeChanged();
  }

  setRangeSectionDisabled(disable){
    let disabledClasses = this.rangeSectionTarget.dataset.disabledClass.split(' ');
    disabledClasses.forEach((disabledClass)=>{
      if(disable){
        this.rangeSectionTarget.classList.add(disabledClass);
      }else{
        this.rangeSectionTarget.classList.remove(disabledClass);
      }
    });

    this.rangeInputs.forEach((input)=>{
      input.disabled = disable;
    });

    if(disable){
      this.compensationHiddenInputs.forEach((input)=>{
        input.value = '';
        input.dispatchEvent(new CustomEvent('custom-change', {}));
      });
    }
  }

  get compensationHiddenInputs(){
    return [
      this.element.querySelector('input[name="filters[total_compensation_in_cents]lte"]'),
      this.element.querySelector('input[name="filters[total_compensation_in_cents]gte"]')
    ];
  }

  get rangeInputs(){
    return this.element.querySelectorAll('input[type=range]');
  }
}
