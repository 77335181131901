import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'select',
    'speakerName',
    'speakerLanguage',
    'speakerStyle',
    'portraitSignedId',
    'audioSignedId',
    'scriptInput',
    'audioButton'
  ];

  connect() {}

  updateSpeakerLanguage() {
    if (this.speakerLanguageTarget.value) {
      const language = this.speakerLanguageTarget.value;
      const name = this.speakerNameTarget.value;
      const script = this.scriptInputTarget.value;
      if (this.hasAudioButtonTarget) {
        this.audioButtonTarget.disabled = true;
      }
      post('/ai/video/selected_language', {
        query: { language, script, name },
        responseKind: 'turbo-stream'
      });
    }
  }

  updateSpeakerName() {
    if (this.speakerNameTarget.value) {
      const name = this.speakerNameTarget.value;
      const language = this.speakerLanguageTarget.value;
      const script = this.scriptInputTarget.value;
      if (this.hasAudioButtonTarget) {
        this.audioButtonTarget.disabled = true;
      }
      post('/ai/video/selected_speaker', {
        query: { name, language, script },
        responseKind: 'turbo-stream'
      });
    }
  }

  updateSpeakerVoicePreviewUrl() {
    if (this.speakerStyleTarget.value) {
      const name = this.speakerNameTarget.value;
      const language = this.speakerLanguageTarget.value;
      const style = this.speakerStyleTarget.value;
      const script = this.scriptInputTarget.value;
      this.audioButtonTarget.disabled = true;
      post('/ai/video/selected_style', {
        query: { name, language, style, script },
        responseKind: 'turbo-stream'
      });
    }
  }

  onScriptInputChange(event) {
    const portraitValue = this.hasPortraitSignedIdTarget && this.portraitSignedIdTarget.value;
    const style = this.speakerStyleTarget.value;
    const name = this.speakerNameTarget.value;
    let hasPortrait = false;
    if (portraitValue) {
      hasPortrait = true;
    }
    const value = event.currentTarget.value;
    const language = this.speakerLanguageTarget.value;
    if(this.hasAudioButtonTarget)
    {
      this.audioButtonTarget.disabled = true;
    }
    post('/ai/video/script_input_changed', {
      query: { value, hasPortrait, language, name, style },
      responseKind: 'turbo-stream'
    });
  }

  onPortraitSelected() {
    const scriptInputValue = this.hasScriptInputTarget && this.scriptInputTarget.value;
    const audioValue = this.hasAudioSignedIdTarget && this.audioSignedIdTarget.value;
    let hasScriptInput = false;
    let hasAudio = false;
    let audioSignedId = null;
    let scriptInput = null;
    if (scriptInputValue && scriptInputValue.length >= 1) {
      hasScriptInput = true;
      scriptInput = scriptInputValue;
    }
    if (audioValue) {
      hasAudio = true;
      audioSignedId = audioValue;
    }
    post('/ai/video/portrait_selected', {
      query: { hasScriptInput, hasAudio, audioSignedId, scriptInput },
      responseKind: 'turbo-stream'
    });
  }

  checkPortraitExistence() {
    const portraitValue = this.hasPortraitSignedIdTarget && this.portraitSignedIdTarget.value;
    let hasPortrait = false;
    if (portraitValue) {
      hasPortrait = true;
    }
    post('/ai/video/check_portrait_existence', {
      query: { hasPortrait },
      responseKind: 'turbo-stream'
    });
  }
}
