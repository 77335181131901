import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab', 'label', 'details_footer', 'details_header', 'details_dropdown'];
  static values = {
    orderInvoiceText: String,
    orderStatusText: String,
    listingDetailsText: String,
    listingStateBoolean: Boolean
  };

  // Uncertain why we're removing this item...
  // leaving this here in case we need it back.
  //
  //connect() {
  // localStorage.removeItem('selectedTabItem');
  //}

  setTab(event) {
    this.labelTarget.innerText = event.target.innerText;
    this.tabTargets.forEach((tab) => {
      if (tab.classList.contains(event.params.item)) {
        tab.classList.add('active');
        localStorage.setItem('selectedTabItem', event.params.item);
      } else {
        tab.classList.remove('active');
      }
    });
  }

  tabTargetConnected(tabLink) {
    const selectedTabItem = localStorage.getItem('selectedTabItem');
    if (selectedTabItem) {
      if (tabLink.classList.contains(selectedTabItem)) {
        tabLink.classList.add('active');
      } else {
        tabLink.classList.remove('active');
      }
      if (selectedTabItem === 'order-invoice-js') {
        this.labelTarget.innerText = this.orderInvoiceTextValue;
      } else if (selectedTabItem === 'listing-details-js') {
        this.labelTarget.innerText = this.listingDetailsTextValue;
      } else {
        this.labelTarget.innerText = this.orderStatusTextValue;
      }
    }
  }
}
