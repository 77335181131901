export const filtersFromParams = () => {
  let filters = {};
  let url = new URL(window.location.href);
  for (const [key, value] of url.searchParams.entries()) {
    if (key.includes('date')) {
      continue;
    }

    let isMultiple = key.endsWith('[]');

    if (isMultiple) {
      let k = key.replace('filters', '').slice(0, -2).replace('[', '').replace(']', '');
      if (Array.isArray(filters[k])) {
        filters[k].push(value);
      } else {
        filters[k] = [value];
      }
    } else {
      let k = key.replace('filters', '').replace('[', '').replace(']', '');
      filters[k] = value;
    }
  }
  return filters;
};

export const dateFromFlatpickr = (flatpickrController) => {
  if (!flatpickrController) {
    return {};
  }

  let date = {};
  if (flatpickrController && flatpickrController.endDate) {
    date['to'] = flatpickrController.endDate;
  }

  if (flatpickrController && flatpickrController.startDate) {
    date['since'] = flatpickrController.startDate;
  }
  return date;
};
