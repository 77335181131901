import { Controller } from '@hotwired/stimulus';
import Lottie from 'lottie-web';
// Connects to data-controller="lottie"
export default class extends Controller {
  static values = {
    param: String,
    local: true,
    loop: Boolean,
  };

  startedPlaying = false;

  connect() {
    let url = new URL(window.location);
    if (this.element) {
      this.lottie = Lottie.loadAnimation({
        container: this.element, // the dom element that will contain the animation
        renderer: 'svg',
        loop: this.loopValue,
        autoplay: false,
        path: this.localValue
          ? `${url.origin}/lottie/${this.paramValue}.json` // the path to the animation json
          : `https:///lottie.host/${this.paramValue}.json` // expecting something like 56f41f35-6759-4c5b-b2a7-94a64f5209ba/XZRNEGm9tC
      });

      // Only start playing when the lottie is in the viewport

      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.6 // This percentage of the el must be visible before it's considered 'intersecting'
      };
      this.intersectionObserver = new IntersectionObserver((entries) => {
        // console.log('entries', entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting && this.lottie && !this.startedPlaying) {
            this.startedPlaying = true;
            this.lottie.play();
          }
        });
      }, options);
      this.intersectionObserver.observe(this.element);
    }
  }
}
