import { Controller } from '@hotwired/stimulus';
import introJs from 'intro.js';
import { post } from '@rails/request.js';

// Connects to data-controller="intro-tutorial"
export default class extends Controller {
  static values = {
    tutorials: Object,
    pageLoadTutorialId: String,
    userData: Array,
    dataCount: Number
  };

  connect() {
    const tutorial_id = this.pageLoadTutorialIdValue;
    const current_user_tutorial_ids = this.userDataValue;
    const item_count = this.dataCountValue;

    if (!current_user_tutorial_ids.includes(tutorial_id)) {
      if (['AFTER_LISTING_CREATED'].includes(tutorial_id)) {
        if (item_count > 0) {
          // only start the tutorial for the above ids
          // if the passed data count is greater than 0
          this.start_tutorial_for_given_id(tutorial_id);
        }
      } else {
        // if the tutorial id is not listed above, start the tutorial
        this.start_tutorial_for_given_id(tutorial_id);
      }
    }
  }

  startTutorial(event) {
    const tutorial_id = event.currentTarget.dataset.tutorialId;
    this.start_tutorial_for_given_id(tutorial_id);
  }

  start_tutorial_for_given_id(tutorial_id) {
    const steps = this.tutorialsValue[tutorial_id].main_steps;
    // check if tutorial config has responsive steps
    if (this.responsiveElements(tutorial_id).length) {
      this.responsiveElements(tutorial_id).forEach((elem) => {
        // If we have responsive elements and they are visible
        // inject the step at its correct position for the responsive element
        if (elem.dataset.step && elem.offsetHeight > 0) {
          steps.splice(
            Number(elem.dataset.step) - 1,
            0,
            this.responsiveStep(elem.dataset.intro, tutorial_id)
          );
        }
      });
    }

    const filteredSteps = steps.filter((step) => {
      // here we will filter out steps that have a skip-if
      // attribute and its value is true.
      const element_id = step.element;
      if (document.querySelector(`[data-intro="${element_id}"]`)) {
        const element = document.querySelector(`[data-intro="${element_id}"]`);
        const skipIfValue = element.getAttribute('data-skip-if');
        return !(skipIfValue && skipIfValue.toLowerCase() === 'true');
      }
      // Include steps where element_id or its associated element is not valid
      return true;
    });

    filteredSteps.forEach(this.addElement.bind(this));
    introJs()
      .setOptions({
        steps: filteredSteps,
        ...(['LISTING_PREVIEW', 'VOICE_LIBRARY'].includes(tutorial_id)
          ? { scrollToElement: false }
          : {})
      })
      .onexit(function () {
        post('/users/update_intro_tutorial_ids', {
          query: { tutorial_id }
        });
        document
          .querySelectorAll('[data-intro="left_panel"]')[0]
          .classList.remove('left-panel-hidden');
        document.getElementById('offcanvasRightPanel')?.classList?.remove('show');
        document.getElementById('aiVoice')?.classList?.remove('show');
      })
      .onbeforechange((targetPanel, step) => {
        if (['LISTING_PREVIEW', 'VOICE_LIBRARY'].includes(tutorial_id)) {
          document.body.style.overflow = 'hidden';
        }
        // Every time a tutorial step is activated we close the left Panel.
        const leftPanel = document.querySelectorAll('[data-intro="left_panel"]')[0];
        leftPanel?.classList?.add('left-panel-hidden');

        if (steps[step].right_panel?.element) {
          // If the IntroJs step has a right_panel property, we may want to either show or hide the right panel
          const rightPanel = document.getElementById('offcanvasRightPanel');
          const rightPanelInitialShowState = rightPanel?.classList?.contains('show');

          if (steps[step].right_panel.show) {
            // If the rightPanel should be shown, show the right panel
            rightPanel?.classList?.add('show');

            const targetPanelId = steps[step].right_panel.element;
            const targetPanel = document.getElementById(targetPanelId);

            // Store all available section buttons in variables
            const promptBuilderButton = document.querySelector(
              '[data-ai-right-panel-options-toggler-target="promptBuilderButton"]'
            );
            const modelOptionsButton = document.querySelector(
              '[data-ai-right-panel-options-toggler-target="modelOptionsButton"]'
            );

            // Dynamically get the button we want to be active using the target element id
            const activeButton = document.querySelector(
              `[data-ai-right-panel-options-toggler-target="${targetPanelId}Button"]`
            );

            // Remove the active class from all buttons
            modelOptionsButton?.classList?.remove('active');
            promptBuilderButton?.classList?.remove('active');
            // Add the active class to the button we want to be active
            activeButton?.classList?.add('active');

            // Make sure the target panel is visible
            targetPanel?.classList.remove('d-none');
            // If the target element id is the prompt builder, we need to hide the model options and vise versa
            if (targetPanelId === 'promptBuilder') {
              document.getElementById('modelOptions')?.classList?.add('d-none');
            } else {
              document.getElementById('promptBuilder')?.classList?.add('d-none');
            }
            // Only wait for the rightPanel open animation if the rightPanel is not shown initially
            if (!rightPanelInitialShowState) {
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                }, 500);
              });
            }
          } else {
            // If rightPanel show is false, hide the right panel
            const rightPanel = document.getElementById('offcanvasRightPanel');
            rightPanel?.classList?.remove('show');
          }
        } else if (steps[step].right_fly_out) {
          // If the IntroJs step has a right_fly_out property, we may want to either show or hide the flyOut & additional right panel
          const rightFlyOut = document.getElementById(steps[step].right_fly_out.element);
          const rightPanel = document.getElementById('offcanvasRightPanel');
          let rightFlyOutInitialState = rightFlyOut?.classList?.contains('show');

          if (steps[step].right_fly_out.show) {
            // If the rightFlyOut should be shown, hide the right panel
            // and show the rightFlyOut.
            // The show property is passed as part of a json object.
            // ie. { element: 'element_id', show: true }
            // in intro_ja_helper.rb where we define the tutorial steps.
            rightPanel?.classList?.remove('show');
            rightFlyOut?.classList?.add('show');
          } else {
            // If the flyOut should be hidden, show the right panel
            // and hide the flyOut.
            // we also need to reset the rightFlyOutInitialState to decide
            // if we need to wait for the flyOut open animation below.
            rightPanel?.classList?.add('show');
            rightFlyOut?.classList?.remove('show');
            rightFlyOutInitialState = !rightFlyOutInitialState;
          }

          // Only wait for the rightFlyOut open animation if the rightFlyOut is not shown initially
          if (!rightFlyOutInitialState) {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 500);
            });
          }
        }
        if (steps[step].left_panel) {
          // Open the left panel since that is where the element is
          leftPanel?.classList?.remove('left-panel-hidden');
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 500);
          });
        }
      })
      .onafterchange((targetElement) => {
        let introTooltip = document.querySelector('.introjs-tooltip');

        const isFloatingElement =
          targetElement && targetElement.classList.contains('introjsFloatingElement');
        const isMobile = window.innerWidth < 770;

        if (isFloatingElement) {
          if (isMobile) {
            introTooltip.style.transform = 'translateY(200px) scale(0.94)';
          } else {
            introTooltip.style.transform = '';
          }
        } else {
          if (isMobile) {
            introTooltip.style.transform = 'translateY(-10px) scale(0.94)';
          } else {
            introTooltip.style.transform = '';
          }
        }
      })
      .onbeforeexit(() => {
        document.body.style.overflow = 'auto';
      })
      .start();
  }

  addElement(item) {
    const element_id = item.element;

    // Check if item has the 'element' property
    if ('element' in item) {
      // Check if element_id is a valid string and not empty
      if (typeof element_id === 'string' && element_id.trim() !== '') {
        const element = document.querySelector(`[data-intro="${element_id}"]`);

        item.element = element;
      }
    }
  }

  responsiveElements(tutorial_id) {
    return this.tutorialsValue[tutorial_id].responsive_steps
      ? this.tutorialsValue[tutorial_id].responsive_steps.map(({ element }) =>
        document.querySelector(`[data-intro="${element}"]`)
      )
      : [];
  }

  responsiveStep(element_selector, tutorial_id) {
    return this.tutorialsValue[tutorial_id].responsive_steps.find(
      ({ element }) => element === element_selector
    );
  }
}
