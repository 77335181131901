import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textarea', 'dropdownMask'];
  static values = {
    preventEnterSubmit: Boolean
  };
  connect() {
    this.setStyles({
      resize: 'none',
      overflow: 'hidden'
    });
    this.resize();

    this.interceptEnter();
  }

  interceptEnter() {
    this.textareaTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        if (this.hasPreventEnterSubmitValue && !this.preventEnterSubmitValue) {
          // submit form by hitting the submit button, which triggers logic like graying out that button
          let form = this.element.closest('form');
          let submitButton = form.querySelector('button:not([type="button"])');
          if (submitButton) {
            submitButton.click();
          }
          event.preventDefault();
        }
      }
    });
  }

  resize() {
    this.setStyles({ height: '5px' }); // Force textarea to shrink back to its minimum height
    this.setStyles({ height: this.scrollHeight });

    if (this.hasDropdownMaskTarget) {
      if (this.textareaTarget.clientHeight > 40) {
        this.dropdownMaskTarget.classList.add('show');
      } else {
        this.dropdownMaskTarget.classList.remove('show');
      }
    }
  }

  get scrollHeight() {
    return this.textareaTarget.scrollHeight + 'px';
  }

  setStyles(styles) {
    for (let property in styles) {
      this.textareaTarget.style[property] = styles[property];
    }
  }
}
