import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['phoneVerificationParam'];
  modal = null;

  connect() {
    if (
      this.hasPhoneVerificationParamTarget &&
      this.phoneVerificationParamTarget.value === 'true'
    ) {
      let ele = document.getElementById('phone-verification');
      if (ele) {
        this.modal = new Modal(ele);
        this.modal.show();
      }
    }
  }
}
