import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="locked-ai-assistant-membership-modal"
export default class extends Controller {
  static targets = ['showUpgradeModal'];

  showUpgradeModal(event) {
    event.preventDefault();
    if (this.hasShowUpgradeModalTarget) {
      post('/ai/assistants/show_upgrade_modal', {
        body: {
          assistant_name: this.showUpgradeModalTarget?.dataset?.assistantName
        },
        responseKind: 'turbo-stream'
      });
    }
  }
}
