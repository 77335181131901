import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    textStreaming: { type: Boolean, default: false },
    scrollIntoView: { type: Boolean, default: true }
  };

  connect() {
    // this is temporary check to make sure scroll is not broken anywhere else i.e order chat etc
    if (this.scrollIntoViewValue) {
      this.element.scrollIntoView();
    }

    this.toggleTextStreamingClass();
    this.addTableWrappers();
    this.convertLinks();
  }

  toggleTextStreamingClass() {
    if (this.textStreamingValue) {
      this.element.parentElement.classList.add('text-streaming');
    } else {
      this.element.parentElement.classList.remove('text-streaming');
    }
  }

  addTableWrappers() {
    this.element.querySelectorAll('table').forEach((table) => {
      let elem = document.createElement('div');
      elem.classList.add('table-wrap');
      elem.appendChild(table.cloneNode(true));
      table.parentNode.replaceChild(elem, table);
    });
  }

  convertLinks() {
    this.element.querySelectorAll('a').forEach((link) => {
      if (link.href) {
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
      }
    });
  }
}
