import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'orgName',
    'orgLogoUrl',
    'orgDomain',
    'orgId',
    'displayOrg',
    'orgDomainLabel',
    'orgNameLabel',
    'searchResultList'
  ];

  submit(event) {
    event.preventDefault();
    this.setHiddenFields(event);

    if (this.isOrganizationsPage) {
      this.displaySelectedBrand();
      this.enableUpdateOrganizationButton();
      this.hideSearchResults();
    } else if (this.isOpportunityEditPage) {
      let submitButton = document.querySelector('#opportunityUpdateButton');
      submitButton.click();
    } else {
      const form = this.brandSearchResultList.closest('form');
      form.submit();
    }
  }

  setHiddenFields(e) {
    if (!this.isOrganizationsPage) this.orgIdTarget.value = e.currentTarget.dataset.orgId;

    this.orgNameTarget.value = e.currentTarget.dataset.orgName;
    this.orgLogoUrlTarget.value = e.currentTarget.dataset.orgLogoUrl;
    this.orgDomainTarget.value = e.currentTarget.dataset.orgDomain;
  }

  displaySelectedBrand() {
    let imageTag = this.displayOrgTarget.getElementsByTagName('img')[0];
    imageTag.srcset = '';
    imageTag.src = this.orgLogoUrlTarget.value;
    this.orgDomainLabelTarget.innerHTML = this.orgDomainTarget.value;
    this.orgNameLabelTarget.innerHTML = this.orgNameTarget.value;
  }

  enableUpdateOrganizationButton() {
    this.updateButton.disabled = false;
  }

  hideSearchResults() {
    this.searchResultListTarget.innerHTML = '';
    document.getElementById('search-cover').value = this.orgNameTarget.value;
  }

  get isOrganizationsPage() {
    return window.location.pathname.includes('/organizations');
  }

  get isOpportunityEditPage() {
    return window.location.pathname.includes('/user/opportunities');
  }

  get brandSearchResultList() {
    return document.getElementById('brand-search-result-list');
  }

  get updateButton() {
    return document.getElementById('update-organization-button');
  }
}
