import { Controller } from '@hotwired/stimulus';
import { validEmail, validNewPassword, validInputValue } from '../helpers/input_validations';

export default class extends Controller {
  static targets = ['email', 'password', 'button', 'code', 'newPassword', 'name'];
  connect() {
    if (this.hasButtonTarget && (this.hasEmailTarget || this.hasPasswordTarget)) {
      this.validateForm();
    }
  }

  validateForm() {
    let status = false;
    if (this.hasEmailTarget && this.hasNewPasswordTarget && this.hasNameTarget) {
      setTimeout(() => {
        if (this.validateEmail() && this.validateNewPassword() && this.validateName()) {
          status = true;
          this.buttonTarget.removeAttribute('disabled');
        } else {
          this.buttonTarget.setAttribute('disabled', true);
        }
      }, 5);
    } else if (this.hasEmailTarget) {
      if (this.validateEmail()) {
        status = true;
        this.buttonTarget.removeAttribute('disabled');
      } else {
        this.buttonTarget.setAttribute('disabled', true);
      }
    } else if (this.hasCodeTarget) {
      if (this.validateCode()) {
        status = true;
        this.buttonTarget.removeAttribute('disabled');
      } else {
        this.buttonTarget.setAttribute('disabled', true);
      }
    } else if (this.hasPasswordTarget) {
      setTimeout(() => {
        if (this.validatePassword()) {
          status = true;
          this.buttonTarget.removeAttribute('disabled');
        } else {
          this.buttonTarget.setAttribute('disabled', true);
        }
      }, 5);
    } else if (this.hasNewPasswordTarget) {
      setTimeout(() => {
        if (this.validateNewPassword()) {
          status = true;
          this.buttonTarget.removeAttribute('disabled');
        } else {
          this.buttonTarget.setAttribute('disabled', true);
        }
      }, 5);
    }

    return status;
  }

  validateCode() {
    return validInputValue(this.codeTarget.value);
  }

  validateEmail() {
    const value = this.emailTarget.value;
    return validEmail(value);
  }

  validatePassword() {
    const value = this.passwordTarget.value;
    return validInputValue(value);
  }

  validateNewPassword() {
    return validNewPassword(this.newPasswordTarget.getAttribute('data-password-status'));
  }

  validateName() {
    return validInputValue(this.nameTarget.value);
  }
}
