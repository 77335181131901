import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['name', 'watcher'];

  characterWatcher() {
    let characters = this.nameTarget.value;
    this.watcherTarget.innerText = characters;
  }
}
