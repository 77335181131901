import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="ai-aspect-ratio-slider"
export default class extends Controller {
  static values = {
    mapping: Object
  };

  static targets = ['ratio', 'input'];

  handleChange(event) {
    let value = event.currentTarget.value;
    this.inputTarget.value = value;

    let text = this.mappingValue[value];
    this.ratioTarget.innerText = text.replace('x', ':');
    const placeholders = document.getElementsByClassName('ai-image-placeholder');
    const updated_ratio = text.replace(':', 'x');
    Array.from(placeholders).forEach((placeholder) => {
      placeholder.classList.value.match(/ratio-\d{1,2}x\d{1,2}/).forEach((class_name) => {
        placeholder.classList.remove(class_name);
      });
      placeholder.classList.add('ratio-' + updated_ratio);
    });
  }
}
