addEventListener('direct-upload:initialize', event => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    'beforebegin',
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `
  );
  target.previousElementSibling.querySelector('.direct-upload__filename').textContent = file.name;
});

addEventListener('direct-upload:start', event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove('direct-upload--pending');
  disableFormButtons();
});

addEventListener('direct-upload:progress', event => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(`direct-upload-progress-${id}`);
  progressElement.style.width = `${progress}%`;
});

addEventListener('direct-upload:error', event => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add('direct-upload--error');
  element.setAttribute('title', error);
  enableFormButtons();
});

addEventListener('direct-upload:end', event => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add('direct-upload--complete');
  enableFormButtons();
});

function disableFormButtons() {
  const cancelButton = document.getElementById('attachment-cancel-button');
  const submitButton = document.getElementById('attachment-submit-button');
  if (cancelButton) cancelButton.setAttribute('disabled', '');
  if (submitButton) submitButton.setAttribute('disabled', '');
}

function enableFormButtons() {
  const cancelButton = document.getElementById('attachment-cancel-button');
  const submitButton = document.getElementById('attachment-submit-button');
  if (cancelButton) cancelButton.removeAttribute('disabled', '');
  if (submitButton) submitButton.removeAttribute('disabled', '');
}
