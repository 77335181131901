import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger'];
  static values = {
    className: String
  };

  // You can add a custom class per el that triggers action by doing:
  // action: "click->class-adder#addClass",
  // "class-adder-css-param": "my-custom-class"
  addClass(event) {
    this.element.classList.add(event?.params?.css ?? this.classNameValue);
  }
}
