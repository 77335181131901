import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="ai-blocked-query-modal"
export default class extends Controller {
  static values = {
    redirectPath: String
  };

  connect() {
    if (this.redirectPathValue) {
      const pathName = window.location.pathname;

      if (pathName.includes('/dreamscale')) {
        window.location.href = '/ai/image/dreamscale/introduction?prompt_blocked=true';
      } else {
        window.location.href = this.redirectPathValue;
      }
    } else {
      this.modal = new Modal(this.element);
      this.modal.show();
    }
  }

  close() {
    this.modal.hide();
  }

  proceed() {
    this.modal.hide();

    // User wants to bypass copyright notice, so set that hidden form input to true so this modal won't be triggered when we click generate again
    let ignoreCopyrightEl = document.querySelector('[name="ignore_copyright"]');
    ignoreCopyrightEl.value = true;
    document.querySelector('#ai-generate-button-control-btn').click();
  }
}
