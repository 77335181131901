import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

// Connects to data-controller="autoscroll"
export default class extends Controller {
  connect() {
    useIntersection(this, this.options);
  }

  appear() {
    this.element.click();
  }
}
