import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="auto-cycle-images"
export default class extends Controller {
  static targets = ['image'];

  currentImageIndex = 0;
  timeoutRef = null;

  connect() {
    this.scheduleNextShowImage();
  }

  disconnect() {
    this.unscheduleNextShowImage();
  }

  scheduleNextShowImage() {
    // Using setTimeout instead of setInterval to avoid bug where if browser tab is awoken again after tabbing away, setInterval will try to quickly catch up all cycles it missed, which we don't want
    this.timeoutRef = setTimeout(() => {
      this.showNextImage();
      this.scheduleNextShowImage();
    }, 5000);
  }

  unscheduleNextShowImage() {
    clearTimeout(this.timeoutRef);
    this.timeoutRef = null;
  }

  showNextImage(){
    // Cur Image - it vanishes out at the top of the z index
    let curImageEl = this.imageTargets[this.currentImageIndex];
    let curImgContainer = getImgContainer(curImageEl);
    curImgContainer.classList.remove('next');
    curImgContainer.classList.add('vanishOut','current');

    // Next image - it's just behind the current one in the z-index
    this.currentImageIndex = (this.currentImageIndex + 1) % this.imageTargets.length;
    let nextImageEl = this.imageTargets[this.currentImageIndex];
    let nextImgContainer = getImgContainer(nextImageEl);
    nextImgContainer.classList.remove('vanishOut', 'current');
    nextImgContainer.classList.add('next');
    this.loadImageForHoverImageEl(nextImageEl);

    // other images - they are at the bottom of the z-index. But the next one up should already start loading into memory
    let preloadImageIndex = (this.currentImageIndex + 1) % this.imageTargets.length;
    let preloadImageEl = this.imageTargets[preloadImageIndex];
    let preloadImgContainer = getImgContainer(preloadImageEl);
    preloadImgContainer.classList.remove('vanishOut','current','next');
    this.loadImageForHoverImageEl(preloadImageEl);
  }

  loadImageForHoverImageEl(imgEl) {
    // When the srcset is blank, we know we haven't loaded the image yet (they do immediately have the src set (to a blank inline image at first))
    if (!imgEl.attributes.srcset?.value) {
      // setting this sometimes seems to force a load, so only do this once per image
      imgEl.src = imgEl.attributes['data-src'].value;
      imgEl.srcset = imgEl.attributes['data-srcset'].value;
    }
  }
}

function getImgContainer(el){
  return el.closest('.auto-cycle-image-container');
}
