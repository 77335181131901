import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="ai-right-panel-options-toggler"

export default class extends Controller {
  static targets = [
    'promptBuilderForm',
    'modelForm',
    'promptBuilderButton',
    'modelOptionsButton',
    'infoButton'
  ];

  modelFormShown = false;
  promptBuilderFormShown = false;

  connect() {
    this.hideModelForm();
  }

  showModelForm(event) {
    event.preventDefault();
    this.modelFormShown = true;
    if (this.hasModelFormTarget) this.modelFormTarget.classList.remove('d-none');
    if (this.hasModelOptionsButtonTarget) this.modelOptionsButtonTarget.classList.add('active');
    this.hidePromptBuilderForm();
    this.setInfoButtonTargetValue();
  }

  showPromptBuilderForm(event) {
    event.preventDefault();
    this.promptBuilderFormShown = true;
    if (this.hasPromptBuilderFormTarget) this.promptBuilderFormTarget.classList.remove('d-none');
    if (this.hasPromptBuilderButtonTarget) this.promptBuilderButtonTarget.classList.add('active');
    this.hideModelForm();
    this.setInfoButtonTargetValue();
  }

  hidePromptBuilderForm() {
    this.promptBuilderFormShown = false;
    if (this.hasPromptBuilderFormTarget) this.promptBuilderFormTarget.classList.add('d-none');
    if (this.hasPromptBuilderButtonTarget)
      this.promptBuilderButtonTarget.classList.remove('active');
  }

  hideModelForm() {
    this.modelFormShown = false;
    if (this.hasModelFormTarget) this.modelFormTarget.classList.add('d-none');
    if (this.hasModelOptionsButtonTarget) this.modelOptionsButtonTarget.classList.remove('active');
  }

  setInfoButtonTargetValue() {
    if (this.hasInfoButtonTarget) {
      let pathName = window.location.pathname;
      if (this.modelFormShown) {
        this.infoButtonTarget.dataset.showHelpDocumentIdentifierValue = 'txt_to_img_model_options';
        if (pathName.includes('/image/purple_pill')) {
          this.infoButtonTarget.dataset.showHelpDocumentIdentifierValue = 'purple_pill_model_options';
        }
      }
      if (this.promptBuilderFormShown) {
        this.infoButtonTarget.dataset.showHelpDocumentIdentifierValue = ''; // prompt_builder
        if (pathName.includes('/image/purple_pill')) {
          this.infoButtonTarget.dataset.showHelpDocumentIdentifierValue = 'purple_pill_dosage';
        }
      }
    }
  }
}
