<script>
  import pageState from '~/services/pageState.svelte';
  import { railsPost } from '~/services/railsQuery.svelte';

  // This is how you get props - we don't really need to pass in name since we have it from pageStore.current_user
  let { email } = $props();
  let formState = $state({ name: '' });
  let serverResponse = $state({});

  $effect(() => {
    // Automatically runs immediately and when pageState's current_user is updated, so when we learn that current_user.name has been changed, we update the formState from that
    formState.name = pageState.current_user.name;
  });

  function submit() {
    // You you technically bypass using the special library, but using it allows it to update the pageState for you if you return any _page_state in any JSON response
    serverResponse = railsPost('/develop/svelte_playground/update_my_user', {
      body: formState
    });
  }

  $effect(() => {
    if (serverResponse.isSuccess) {
      // $inspect is just console.log that logs $state more readably
      $inspect('Update ajax call succeeded!', serverResponse.data);
    }
  });
</script>

<div class="border">
  <p>Your email: {email}</p>
  <input type="text" bind:value={formState.name} />
  <button onclick={submit}>Update</button>

  <span class="ml-2">
    {#if serverResponse.isLoading}
      Sending...
    {:else if serverResponse.isSuccess}
      Updated name to: <b>{serverResponse.data.name}</b>, and Rails says your Lucky Number is:
      <i>{serverResponse.data.lucky_number}</i>
    {/if}
  </span>
</div>

<style lang="scss">
  .border {
    padding: 1rem;
  }
</style>
