import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="assistant-persona"
export default class extends Controller {
  static targets = ['selectPersonaMode', 'toggleAsDefault', 'selectPersona', 'usePersona'];
  connect() {}

  onSelectedPersonaMode() {
    let personaId = '';

    if (this.selectPersonaModeTarget.value) {
      const selectedPersonaMode = this.selectPersonaModeTarget.value;
      personaId = this.selectPersonaModeTarget?.dataset?.personaId || '';
      post('/ai/assistants/personas/update_mode', {
        body: { selected_persona_mode: selectedPersonaMode, persona_id: personaId },
        responseKind: 'turbo-stream'
      });
    }
  }

  resetBias(event) {
    event.preventDefault();

    document
      .querySelectorAll('[name="assistant_persona[bias_type]"]')
      .forEach((radio) => (radio.checked = false));
  }

  resetFormality(event) {
    event.preventDefault();

    document
      .querySelectorAll('[name="assistant_persona[formality_type]"]')
      .forEach((radio) => (radio.checked = false));
  }

  resetResponseLength(event) {
    event.preventDefault();

    document
      .querySelectorAll('[name="assistant_persona[response_length_type]"]')
      .forEach((radio) => (radio.checked = false));
  }

  toggleAsDefault(event) {
    event.preventDefault();
    let personaId = '';

    if (this.hasToggleAsDefaultTarget) {
      personaId = this.toggleAsDefaultTarget?.dataset?.personaId || '';
    }

    post('/admin/ai/toggle_persona_as_default', {
      body: {
        persona_id: personaId
      },
      responseKind: 'turbo-stream'
    });
  }

  selectPersona() {
    let personaId = '';

    if (this.hasSelectPersonaTarget) {
      personaId = this.selectPersonaTarget?.dataset?.personaId || '';

      post('/ai/assistants/personas/update_selected_persona', {
        body: { persona_id: personaId },
        responseKind: 'turbo-stream'
      });
    }
  }

  usePersona() {
    let personaId = '';
    let actionType = '';

    if (this.hasUsePersonaTarget) {
      personaId = this.usePersonaTarget?.dataset?.personaId || '';
      actionType = this.usePersonaTarget?.dataset?.actionType || '';

      post('/ai/assistants/personas/update_in_use_personas', {
        body: { persona_id: personaId, action_type: actionType },
        responseKind: 'turbo-stream'
      });
    }
  }
}
