import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static values = {
    path: String
  };

  connect() {
    if(!window.history || !this.pathValue) return;

    history.replaceState({},'', this.pathValue);
  }
}
