import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="offset-parallax"
export default class extends Controller {
  // Parallax effect parameters
  rateTranslateY = 0.3; // Rate of translation on Y axis
  rateOpacity = 0.001; // Rate of opacity change

  // Scroll Event Handling variables
  lastScrollY = 0; // Last scroll position
  ticking = false; // Flag to prevent multiple update requests

  connect() {
    // Bind the scroll event handler
    this.scrollHandler = this.throttledOnScroll.bind(this);
    // Add scroll event listener
    window.addEventListener('scroll', this.scrollHandler, { passive: true });
    // Call the throttledOnScroll method to update the element on connect
    this.throttledOnScroll(); // Initial call to update on connect
  }

  disconnect() {
    // Remove the scroll event listener
    window.removeEventListener('scroll', this.scrollHandler);
  }

  throttledOnScroll() {
    // Check if update is not already requested
    if (!this.ticking) {
      // Request animation frame to update the element
      window.requestAnimationFrame(this.update.bind(this));
      // Set the flag to prevent multiple update requests
      this.ticking = true;
    }
  }

  update() {
    // Reset the flag to allow next update request
    this.ticking = false;
    // Get the scroll position
    const offsetY = window.scrollY * this.rateTranslateY;
    const opacity = 1 - window.scrollY * this.rateOpacity;
    // Apply the transform and opacity to the element
    this.element.style.transform = `translateY(${offsetY}px)`;
    this.element.style.opacity = opacity;
  }
}
