import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="ai-image-count-subscription-pop-up"
export default class extends Controller {
  static targets = ['select'];

  static values = {
    triggerId: String,
    show: Boolean
  };

  modal = null;

  connect() {
    if (this.hasTriggerIdValue) {
      let element = document.getElementById(this.triggerIdValue);
      this.modal = new Modal(element);
      if (this.hasShowValue && this.showValue) {
        this.modal.toggle();
      }
    }
  }

  onChange() {
    if (this.selectTarget.value && Number(this.selectTarget.value) > 1) {
      this.selectTarget.value = 1;
      this.modal.toggle();
    }
  }
}
