import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="redirect-on-connect"
export default class extends Controller {
  static values = {
    path: String,
    error: String
  };

  connect() {
    let url = new URL(window.location);
    if (this.hasPathValue) {
      let redirect = `${url.origin}${this.pathValue}`;
      if (this.hasErrorValue) {
        redirect += `?e=${this.errorValue}`;
      }
      window.location.replace(redirect);
    }
  }
}
