import { Controller } from '@hotwired/stimulus';

// Tell the browser to find any required fields
let requiredFieldSelectors = '[data-required="true"]';
export default class extends Controller {
  static values = {
    validateAll: { type: Boolean, default: false },
    skipValidation: { type: Boolean, default: false }
  };

  connect() {
    if (this.skipValidationValue) {
      return;
    }

    if (this.requiredFields.length > 0) {
      this.disableSubmitButton();
    }

    this.requiredFields.forEach((field) => {
      field.addEventListener('input', this.validateForm.bind(this));
    });
  }

  validateForm() {
    let validForm = false;

    validForm = this.validateField();

    if (validForm) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  validateField() {
    let is_valid = false;
    this.requiredFields.forEach((field) => {
      if(field.name === 'order_addendum[additional_cost_in_cents]') {
        is_valid = parseInt(field.value.replace(/[^0-9.]+/g,'')) > 0;
      }
      if(field.type === 'hidden') {
        is_valid ||= (field.value > 0 && field.id === 'additional_delivery_in_days') || (field.value > 0 && field.id === 'additional_revisions');
      }
    });
    this.requiredFields.forEach((field) => {
      if(field.type === 'textarea') {
        is_valid &&= (field.value.length > 0 && field.name === 'order_addendum[description]');
      }
    });
    return is_valid;
  }

  disableSubmitButton() {
    if (!this.submitButton) {
      return;
    }

    this.submitButton.disabled = true;
  }

  enableSubmitButton() {
    if (!this.submitButton) {
      return;
    }

    this.submitButton.disabled = false;
  }

  get requiredFields() {
    return this.element.querySelectorAll(requiredFieldSelectors);
  }

  get submitButton() {
    return document.getElementById('attachment-submit-button');
  }
}
