import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="order-addendum"
export default class extends Controller {
  static targets = [
    'originalPriceLabel',
    'newPriceLabel',
    'totalPriceLabel',
    'originalDeliveryDaysLabel',
    'additionalDeliveryDaysLabel',
    'totalDeliveryDaysLabel',
    'originalRevisionsLabel',
    'additionalRevisionsLabel',
    'totalRevisionsLabel'
  ];

  connect() {}

  setNewPrice(event) {
    let value = Number(event.target.value.replace(/\$/, ''));
    this.newPriceLabelTarget.innerText = value;
    this.totalPriceLabelTarget.innerText = value + Number(this.originalPriceLabelTarget.innerText);
  }

  setAdditionalDeliveryDays(event) {
    let value = Number(event.target.dataset.value);
    this.additionalDeliveryDaysLabelTarget.innerText = value;
    this.totalDeliveryDaysLabelTarget.innerText =
      value + Number(this.originalDeliveryDaysLabelTarget.innerText);
  }

  setAdditionalRevisions(event) {
    let value = Number(event.target.dataset.value);
    this.additionalRevisionsLabelTarget.innerText = value;
    this.totalRevisionsLabelTarget.innerText =
      value + Number(this.originalRevisionsLabelTarget.innerText);
  }
}
