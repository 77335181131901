
export class PageState{
  state = $state({});
  constructor(){
    // let state = this.#state;

    // Allow state properties to be get/set directly on the class instance. So you can do `pageState.sample` for `pageState.#state.sample`
    return new Proxy(this, {
      get(target, prop) {
        if(target[prop]){
          return target[prop];
        }
        return target.state[prop];
      },
      set() {
        // PageState should be coming in in very specific ways, so we shouldn't need to update it over the place; will only restrict top-level state setting
        throw new Error('Please avoid setting state directly on pageState. Use .addState() instead if you have to, or consider making another State holder.');
        // target.state[prop] = value;
        // return true;
      }
    });
  }
  addState(data){
    Object.assign(this.state, data);
  }
}

let pageState = new PageState();
export default pageState;

