import { Controller } from '@hotwired/stimulus';
import { Worker as html2pdf } from 'html2pdf.js';
import { post } from '@rails/request.js';

// Connects to data-controller="doc-export"
export default class extends Controller {
  static targets = ['button'];
  static values = { filename: String, extension: String, htmlTargetId: String };

  async export() {
    if (this.extensionValue === 'pdf') {
      await this.pdfExport();
    }
  }

  wrapText(str) {
    return str.replace(/(?![^\n]{1,128}$)([^\n]{1,128})\s/g, '$1\n');
  }

  unwrapText(str) {
    return str.replace(/\n/g, ' ');
  }

  async pdfExport() {
    // Disable the button and change the text to 'Creating PDF Document'
    this.buttonTarget.disabled = true;
    this.buttonTarget.querySelector('span').innerText = 'Creating PDF Document';
    this.buttonTarget.classList.add('shimmer');

    // if we are on safari - remove the typography link to avoid CORS issues
    if (
      document.documentElement.classList.contains('device-ios') ||
      document.documentElement.classList.contains('browser-safari')
    ) {
      // eslint-disable-next-line quotes
      document.querySelector("link[href*='https://cloud.typography.com']")?.remove();
    }

    const element = document.getElementById(this.htmlTargetIdValue);
    // Find image elements in the content to export
    // Now that we are using the front-end - because of CORS restrictions PDF export will not work for images that are not base64 encoded
    // So if we have images use the custom endpoint on our server to convert the image urls to blobs before exporting
    const images = element.querySelectorAll('img');
    if (images.length) {
      let allImageConversions = Array.from(images).map(async (img) => {
        let { blob, error } = await this.convertImageSrcToBase64(img);
        if (!error) {
          img.src = 'data:image/png;base64,' + blob;
        }
      });
      await Promise.all(allImageConversions);
    }

    const scrollableTableElements = element.querySelectorAll('.table-wrap');
    if (scrollableTableElements) {
      scrollableTableElements.forEach((scrollableTableElement) =>
        scrollableTableElement.querySelectorAll('td').forEach((td) => {
          if (td.firstChild && td.firstChild.nodeName === '#text') {
            // wrap the text inside td's so the content fits on the pdf page
            td.innerText = this.wrapText(td.innerText);
          }
        })
      );
    }

    let jsPdfOptions = {
      unit: 'in',
      format: 'a4',
      orientation: 'landscape'
    };

    // This was inspired / copied from https://github.com/eKoopmans/html2pdf.js/issues/37#issuecomment-983608743
    // To be able to render the content in a single pdf page, so we avoid issues with random page breaks
    // If the content has tables - usually for IZZY etc.. and the table css allows overflow and scrolling horizontally
    // we need to adjust the pdf settings so the content fits on the page
    if (scrollableTableElements.length || element.clientHeight > 800) {
      // height can be the max of the element itself properties
      let height = Math.max(element.scrollHeight, element.offsetHeight, element.clientHeight);
      let orientation = height > 1400 ? 'portrait' : 'landscape';
      let widthCM = 1400 / 35.35;
      let heightCM = height / 35.35;
      jsPdfOptions.orientation = orientation;
      jsPdfOptions.format = height > 1400 ? [heightCM, widthCM] : [widthCM, heightCM];
      jsPdfOptions.unit = 'cm';
    }

    setTimeout(() => {
      html2pdf()
        .from(element)
        .set({
          margin: scrollableTableElements.length || element.clientHeight > 800 ? 1.3 : 0.5,
          filename: this.filenameValue,
          jsPDF: jsPdfOptions
        })
        .save()
        .then(() => {
          this.buttonTarget.disabled = false;
          this.buttonTarget.querySelector('span').innerText = 'PDF Document';
          this.buttonTarget.classList.remove('shimmer');
          if (scrollableTableElements) {
            scrollableTableElements.forEach((scrollableTableElement) =>
              scrollableTableElement.querySelectorAll('td').forEach((td) => {
                if (td.firstChild && td.firstChild.nodeName === '#text') {
                  td.innerText = this.unwrapText(td.innerText);
                }
              })
            );
          }
        });
    }, 500);
  }

  async convertImageSrcToBase64(img) {
    if (img.src.includes('data:image')) {
      return { blob: null, error: 'Already a base64 image' };
    }

    try {
      let response = await post('/image-blob-from-url', { body: { image_blob: { url: img.src } } });
      let data = await response.json;
      return data;
    } catch (err) {
      console.error(err);
      return { blob: null, error: err };
    }
  }
}
