import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['rejectReasonTextField', 'actionType'];
  static values = {
    acceptUrl: String,
    rejectUrl: String,
    accept: Number,
    reject: Number
  };

  connect() {
    this.hideRejectReasonTextField();
  }

  showRejectReasonTextField() {
    this.rejectReasonTextFieldTarget.parentElement.classList.remove('d-none');
  }

  hideRejectReasonTextField() {
    this.rejectReasonTextFieldTarget.parentElement.classList.add('d-none');
  }

  toggleRejectReasonField(event) {
    Array.from(document.querySelectorAll('input[name="admin-review-delivery"]')).forEach(
      (element) => {
        element.checked = false;
      }
    );
    event.target.checked = true;

    if (event.target.value === 'reject_delivery') {
      this.showRejectReasonTextField();
      this.resetFormSubmitUrl(this.rejectUrlValue);
      this.resetActionType(this.rejectValue);
    } else {
      this.hideRejectReasonTextField();
      this.resetFormSubmitUrl(this.acceptUrlValue);
      this.resetActionType(this.acceptValue);
    }
  }

  resetFormSubmitUrl(url) {
    let form = document.getElementById('actions-form');
    form.action = url;
  }

  resetActionType(value) {
    this.actionTypeTarget.value = value;
  }
}
