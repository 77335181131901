import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="ai-advanced-image-select"
export default class extends Controller {
  static targets = [
    'selectModel',
    'selectSize',
    'selectAspectRatio',
    'imageBlurHolder',
    'selectDosageType',
    'imageDosageAlert'
  ];

  onSelectedModel() {
    if (this.selectModelTarget.value) {
      const selected_model = this.selectModelTarget.value;
      post('/ai/image/advanced_settings_for_model', {
        query: { selected_model },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectedSize() {
    if (this.selectSizeTarget.value && this.selectModelTarget.value) {
      const selected_size = this.selectSizeTarget.value;
      const selected_model = this.selectModelTarget.value;
      post('/ai/image/advanced_settings_for_model', {
        query: { selected_size, selected_model },
        responseKind: 'turbo-stream'
      });
    }
  }

  onInitImageStrengthChange(event) {
    let value = parseInt(event.target.value);
    if (value < 0 || value > 100) return;
    this.imageBlurHolderTarget.style.setProperty('--image-strength-blur', value);
  }

  getExistingInitImageSignedId() {
    return document.querySelector('#dropzone_image')?.dataset?.signedId;
  }

  onSelectAspectRatio() {
    let aspect_ratio = this.selectAspectRatioTarget.value;
    let model = this.selectModelTarget.value;
    let signedId = this.getExistingInitImageSignedId();
    post('/ai/image/update_preview_and_values', {
      query: { aspect_ratio, model },
      responseKind: 'turbo-stream'
    });
    if (signedId) {
      post('/ai/image/update_init_image_preview', {
        query: { signed_id: this.getExistingInitImageSignedId(), aspect_ratio },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectPortraitAspectRatio() {
    let ar = this.selectAspectRatioTarget.value;
    let signedId = this.getExistingInitImageSignedId();
    if (signedId) {
      post('/ai/video/update_portrait_image_preview', {
        query: { signed_id: signedId, aspect_ratio: ar },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectClipSourceAspectRatio() {
    let ar = this.selectAspectRatioTarget.value;
    let signedId = this.getExistingInitImageSignedId();
    let pathName = window.location.pathname;
    if (signedId) {
      post('/ai/image/update_source_image_preview', {
        query: { signed_id: signedId, aspect_ratio: ar, url_path: pathName },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectedDosageType() {
    let pathName = window.location.pathname;
    let category = '';
    if (pathName.includes('/model_comparison')) category = 'model_comparison';
    if (pathName.includes('/prompt_party')) category = 'prompt_party';
    if (this.selectDosageTypeTarget.value) {
      const selectedDosageType = this.selectDosageTypeTarget.value;
      post('/ai/image/update_dosage_type', {
        query: { selected_dosage_type: selectedDosageType, category },
        responseKind: 'turbo-stream'
      });
    }
  }

  onBeginnerDosageCountChange(event) {
    if (this.hasImageDosageAlertTarget) {
      let value = parseInt(event.target.value);
      if (value < 0 || value > 22) return;

      if (value > 4) {
        this.imageDosageAlertTarget.classList.remove('d-none');
      } else {
        this.imageDosageAlertTarget.classList.add('d-none');
      }
    }
  }
}
