import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    timeout: 2000,
    disableTimeout: false,
    calculatePadding: false,
    autoHide: false
  };

  connect() {
    if (this.hasShowClass() && !this.disableTimeoutValue) {
      this.hideAfterTimeout();
    }
    if(this.autoHideValue){
      this.autohide();
    }
    if (this.calculatePaddingValue) {
      this.updateHeight();
    }
  }

  hide() {
    this.element.classList.remove('show');
    this.element.classList.add('hide');
  }

  hideAfterTimeout() {
    setTimeout(() => {
      document.querySelector('.slide-in-notification').classList.remove('show');
      document.querySelector('.slide-in-notification').classList.add('hide');
    }, this.timeoutValue);
  }

  hasShowClass() {
    return document.querySelector('.slide-in-notification.show') !== null;
  }

  autohide(){
    setTimeout(() => {
      let status_div = document.querySelector('#status_banner.notification-banner-form-ai');
      if(status_div && status_div.querySelector('.slide-in-notification.show')){
        this.hide();
        document.documentElement.style.setProperty('--zindex', '0');
        document.documentElement.style.setProperty('--padding', '0px');
      }
    }, this.timeoutValue);
  }
  
  updateHeight (){
    let status_div = document.querySelector('#status_banner.notification-banner-form-ai');
    let status_div_height;
    if(status_div){
      status_div_height = status_div.offsetHeight;
      document.documentElement.style.setProperty('--zindex', '99');
      document.documentElement.style.setProperty('--padding', `${status_div_height}px`);
    }
  }
}
