import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap';

// The point of this file is to auto open the Offcanvas so that Rails controllers can cause open/close of Offcanvases
export default class extends Controller {
  connect() {
    this.bsOffcanvas = new Offcanvas(this.element);
    this.bsOffcanvas.show();
    this.element.addEventListener('turbo:click', (event) => {
      if (event.target.id === 'connection_disconnect_btn') {
        this.bsOffcanvas.hide();
      }
    });
  }

  disconnect() {
    // We have to clean up, otherwise the user can no longer scroll the page
    this.bsOffcanvas.hide();
  }
}
