import { Controller } from '@hotwired/stimulus';

// For disabling/enabling the wizard next button from JS
export default class extends Controller {
  static values = {
    startDisabled: Boolean
  };

  connect() {
    if(this.startDisabledValue){
      this.disableNextButton();
    }
  }

  nextButton(){
    return document.querySelector('button[data-disable-with="next"]');
  }

  disableNextButton(){
    let btn = this.nextButton();
    if(btn){
      btn.classList.add('disabled');
    }
  }

  enableNextButton(){
    let btn = this.nextButton();
    if(btn){
      btn.classList.remove('disabled');
    }
  }
}
