import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="tiktok-oauth-modal"
export default class extends Controller {
  static values = {
    lastLogin: Number,
    userAuthWithTiktokNonBusiness: Boolean
  };
  connect() {
    this.modelEl = document.getElementById('tiktok-oauth-modal');
    this.modal = this.modelEl ? new Modal(this.modelEl) : null;

    if (this.modal && this.shouldAutoShow()) {
      this.showModal();
      this.setHideUntilNextLogin();
    }
  }

  shouldAutoShow() {
    if (!this.lastLoginValue) {
      return false;
    }

    // Don't show modal if user has already oauth with TikTok Business
    if (!this.userAuthWithTiktokNonBusinessValue) {
      return false;
    }

    if (!window.localStorage) {
      // if local storage isn't available, show it
      return true;
    }

    let storageValue = localStorage.getItem('hide-tiktok-oauth-modal');
    if (!storageValue) {
      return true;
    }

    let [key, value] = storageValue.split(':');
    if (key === 'until-next-login') {
      return parseInt(value) < this.lastLoginValue;
    } else if (key === 'always') {
      return false;
    }
  }

  showModal() {
    this.modal.show();
  }

  setHideUntilNextLogin() {
    localStorage.setItem(
      'hide-tiktok-oauth-modal',
      'until-next-login:' + (this.lastLoginValue + 604800)
    );
  }
}
