import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  didCategoryChange(event) {
    this.toggleWarning(event, event.target.closest('#listing_category_container'));
  }

  didSubCategoryChange(event) {
    this.toggleWarning(event, event.target.closest('#listing_subcategory_container'));
  }

  didConnectionChange(event) {
    this.toggleWarning(event, event.target.closest('#listing_connection_container'));
  }

  toggleWarning(event, container) {
    let original_selection = container.dataset.original;
    let warning_container = document.querySelector('.code-confirm-keep-prefills');

    if (!warning_container || !original_selection) {
      return;
    }

    if (event.target.type == 'radio' || event.target.type == 'select-one') {
      if (original_selection !== event.target.value) {
        warning_container.classList.remove('d-none');
      } else {
        warning_container.classList.add('d-none');
      }
    }
  }
}
