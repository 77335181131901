import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();

import * as bootstrap from 'bootstrap';

import '@hotwired/turbo';
import './controllers';
import './active_storage_direct_upload';
import './channels';
import './service_worker_interface.js';
import 'trix';
import '@rails/actiontext';

window.addEventListener('DOMContentLoaded', () => {
  // TODO: Remove this if we no longer call bootstrap directly anywhere
  window.bootstrap = bootstrap;
});

// Apply CSS class defined by data-animate-out to apply animation as el is being removed/replaced by TurboStream
// https://medium.com/pragmatic-programmers/animating-turbo-streams-with-animate-css-58fdc93b7814
document.addEventListener('turbo:before-stream-render', (event) => {
  let { action } = event.target;

  if (['remove', 'replace'].includes(action)) {
    let targetEl = document.getElementById(event.target.target);
    if (!targetEl) {
      return console.error(
        'Could not find target element for TurboStream action',
        event.target.target
      );
    }
    // Support animation on targetEl itself, or anywhere underneath
    let animateEl = targetEl.dataset.animateOut
      ? targetEl
      : targetEl.querySelector('[data-animate-out]');

    if (animateEl) {
      // Don't let TurboStream remove/replace the targetEl just yet
      event.preventDefault();

      // Animate it first
      animateEl.classList.add('animated');
      animateEl.classList.add(animateEl.dataset.animateOut);

      // Remove/replace targetEl when the animation is done
      animateEl.addEventListener('animationend', () => {
        if (action === 'remove') {
          targetEl.remove();
        } else if (action === 'replace') {
          // https://turbo.hotwired.dev/handbook/streams#custom-actions
          event.detail.render(event.detail.newStream);
        }
      });
    }
  }
});

// So redirecting after a turbo submit fires another HTML request from webview that will get detected by IOS (doesn't detect TURBO RESPONSE REDIRECTS)
// So turbo stream requests returning a redirect work
document.addEventListener('turbo:submit-end', (event) => {
  if (event.detail.fetchResponse?.redirected && event.detail.fetchResponse?.response?.url) {
    window.location.replace(event.detail.fetchResponse.response.url);
  }
});
