// app/javascript/controllers/hide_description_on_scroll_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['avatar', 'title'];

  connect() {
    this.scrollBody = document.querySelector('main');
    this.scrollHandler = this._scrollHandler.bind(this);

    this.scrollBody.addEventListener('scroll', this.scrollHandler);
  }

  disconnect() {
    this.scrollBody.removeEventListener('scroll', this.scrollHandler);
  }

  _scrollHandler() {
    const scrollPosition = this.scrollBody.scrollTop || this.scrollBody.pageYOffset;
    const titleThreshold = 10;
    const avatarThreshold = 20;

    if (scrollPosition > titleThreshold) {
      this.titleTarget.classList.add('opacity-0');
    } else {
      this.titleTarget.classList.remove('opacity-0');
    }

    if (scrollPosition > avatarThreshold) {
      this.avatarTarget.classList.add('org-avatar-shrink');
    } else {
      this.avatarTarget.classList.remove('org-avatar-shrink');
    }
  }
}
