import { Controller } from '@hotwired/stimulus';
import { validEmail, validNewPassword, validInputValue } from '../helpers/input_validations';
import { debounce, once } from 'lodash';
// Connects to data-controller="get-started"
export default class extends Controller {
  static targets = [
    'button',
    'emailInput',
    'newPasswordInput',
    'passwordInput',
    'nameInput',
    'codeInput',
    'checksContainer',
    'form'
  ];

  onceSubmitForm = null;
  called = false;

  connect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true;
      this.validate();
    }

    if (this.hasFormTarget) {
      this.onceSubmitForm = once(this.submitForm.bind(this));
      this.formTarget.addEventListener('submit', (e) => {
        e.preventDefault();
        if (this.called) return;
        this.called = true;
        this.onceSubmitForm();
      });
    }
  }

  submitForm() {
    this.formTarget.submit();
  }

  validate() {
    let validations = debounce(() => {
      if (this.validations()) {
        this.buttonTarget.disabled = false;
      } else {
        this.buttonTarget.disabled = true;
      }
    }, 100);

    validations();
  }

  validations() {
    let [emailValid, checksValid, codeValid, newPasswordValid, nameValid, passwordValid] =
      Array(6).fill(true);

    if (this.hasEmailInputTarget) {
      emailValid = validEmail(this.emailInputTarget.value);
    }

    if (this.hasPasswordInputTarget) {
      passwordValid = validInputValue(this.passwordInputTarget.value);
    }

    if (this.hasNewPasswordInputTarget) {
      newPasswordValid = validNewPassword(
        this.newPasswordInputTarget.getAttribute('data-password-status')
      );
    }

    if (this.hasCodeInputTarget) {
      codeValid = validInputValue(this.codeInputTarget.value);
    }

    if (this.hasChecksContainerTarget) {
      checksValid = Array.from(
        this.checksContainerTarget.querySelectorAll('input[type=radio]')
      ).reduce((prev, curr) => prev || curr.checked, false);
    }

    if (this.hasNameInputTarget) {
      nameValid = validInputValue(this.nameInputTarget.value);
    }
    return emailValid && newPasswordValid && passwordValid && codeValid && checksValid && nameValid;
  }
}
