import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['flyup', 'form', 'files', 'imagePreview', 'bodyField', 'sendButton'];
  static values = {
    success: Boolean
  };

  connect() {
    if (this.hasBodyFieldTarget) {
      this.bodyFieldTarget.addEventListener('keyup', (e) => this.enableDisableOnChange(e));
      this.bodyFieldTarget.focus();
    }
  }

  flyupTargetConnected() {
    let chatLog = document.getElementById('chat');
    chatLog.classList.add('disabled');
  }

  flyupTargetDisconnected() {
    let chatLog = document.getElementById('chat');
    chatLog.classList.remove('disabled');
  }

  enableDisableOnChange(e) {
    let value = e.target.value;
    if (value == '') {
      this.sendButtonTarget.classList.add('disabled');
    } else {
      this.sendButtonTarget.classList.remove('disabled');
    }
  }

  performAction(event) {
    event.preventDefault();
    let url = event.currentTarget.dataset.url;
    this.loadTemplate(url);
  }

  loadTemplate(url) {
    get(url, {
      responseKind: 'turbo-stream'
    });
  }

  imagePreview() {
    let files = this.filesTarget.files;
    let output = this.imagePreviewTarget;

    for (let file of files) {
      let reader = new FileReader();

      reader.onload = function () {
        let div = document.createElement('div');
        let image = document.createElement('img');
        let span = document.createElement('span');
        div.classList.add('image-preview');
        div.classList.add('d-inline');
        div.classList.add('ms-2');
        div.setAttribute('file-index', Array.from(files).indexOf(file));
        span.textContent = 'x';
        span.setAttribute('data-action', 'click->message-actions#removeImage');
        div.appendChild(span);
        div.appendChild(image);
        output.appendChild(div);
        image.style.height = '100px';
        image.style.width = '100px';
        image.src = reader.result;
        image.classList.add('img-thumbnail');
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(event) {
    let divToBeRemoved = event.target.parentElement;
    let removeIndex = parseInt(divToBeRemoved.getAttribute('file-index'));
    const dt = new DataTransfer();
    const input = this.filesTarget;
    const { files } = input;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (removeIndex !== i) dt.items.add(file); // here you exclude the file. thus removing it.
    }

    input.files = dt.files; // Assign the updates list
    divToBeRemoved.remove();
  }
}
