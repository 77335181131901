import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import { formatBytes } from '../helpers';
import { last, split } from 'lodash';

const FILE_NAME = 'recording.webm';

export default class extends Controller {
  static targets = [
    'dropdownCam',
    'dropdownMic',
    'uploadInput',
    'preview',
    'fileName',
    'fileSize',
    'afterGrant',
    'beforeGrant',
    'fileType',
    'hardDeleteButton',
    'softDeleteButton',
    'video'
  ];
  static values = {
    directUploadPath: String,
    existingFileName: String // if a file was already uploaded before this loaded
  };

  // for future usage
  // browserAgentChrome = true;

  connect() {
    // for future usage
    // this.browserAgentChrome = window.navigator.userAgent.indexOf('Chrome') !== -1;
    if (!this.existingFileNameValue) {
      this.disableSubmitButtons();
    }
    this.waitVideoJSReady();
  }

  disconnect() {
    clearTimeout(this.waitVideoJSReadyPointer);
    if (this.player) {
      // Destroy record plugin so that input devices are released
      this.player.record().destroy();
    }
  }

  // VideoJS is sometimes not loaded by the time this code runs, so this keeps retrying until it is
  waitVideoJSReady() {
    if (window.videojs) {
      this.setupVideoJS();
    } else {
      this.waitVideoJSReadyPointer = setTimeout(() => {
        this.waitVideoJSReady();
      }, 50);
    }
  }

  setupVideoJS() {
    let { videojs } = window;
    let options = {
      // video.js options
      controls: true,
      bigPlayButton: false,
      loop: false,
      fluid: false,
      width: 320,
      height: 240,
      plugins: {
        // videojs-record plugin options
        record: {
          image: false,
          audio: true,
          video: true,
          maxLength: 180, // in seconds
          // can change this back to be auto once we know default formats this library uses will be supported on all browsers
          videoMimeType: 'video/webm', // force mimeType supported by Safari/Chrome/Firefox
          audioMimeType: 'audio/wav', // force mimeType supported by Safari/Chrome/Firefox
          // maxFileSize: 500, // in bytes
          // displayMilliseconds: true,
          debug: true
        }
      }
    };

    // // see https://www.webrtc-experiment.com/RecordRTC/simple-demos/isTypeSupported.html
    // leaving this here for future reference
    // if (!this.browserAgentChrome) {
    //   options.plugins.record.videoMimeType = 'video/webm';
    //   options.plugins.record.audioMimeType = 'audio/wav';
    // }

    // first param is the DOM ID
    this.player = videojs(this.videoTarget, options, () => {
      // print version information at startup
      const msg =
        'Using video.js ' +
        videojs.VERSION +
        ' with videojs-record ' +
        videojs.getPluginVersion('record');
      videojs.log(msg);
    });

    // enumerate devices once
    this.player.one('deviceReady', () => {
      this.player.record().enumerateDevices();
      this.beforeGrantTarget.classList.add('d-none');
      this.afterGrantTarget.classList.remove('d-none');
    });

    this.player.on('enumerateReady', () => {
      let devices = this.player.record().devices;
      devices.forEach((device) => {
        // let description = device.kind + ' - label: ' +
        //   (device.label || 'unknown') +
        //   ' (id: ' + device.deviceId + ')';
        // console.log(description);

        let optionEl = document.createElement('option');
        optionEl.value = device.deviceId;
        optionEl.text = device.label || 'unknown ';

        if (device.kind === 'audioinput') {
          this.dropdownMicTarget.appendChild(optionEl);
        } else if (device.kind === 'videoinput') {
          this.dropdownCamTarget.appendChild(optionEl);
        }
      });

      this.dropdownCamTarget.removeAttribute('disabled');
      this.dropdownMicTarget.removeAttribute('disabled');
    });

    this.player.on('finishRecord', () => {
      // Blob to File
      let file = new File([this.player.recordedData], FILE_NAME);
      this.onRecordingFinished(file);
    });
  }

  get url() {
    return this.directUploadPathValue;
  }

  changeCam(el) {
    let deviceId = el.target.value;
    this.player.record().setVideoInput(deviceId);
  }

  changeMic(el) {
    let deviceId = el.target.value;
    this.player.record().setAudioInput(deviceId);
  }

  onRecordingFinished(file) {
    new DirectUploadControllerVideoRecorder(file, this.url, this);
  }

  submitButtons() {
    return document.querySelectorAll('button[type="submit"].disabled-while-creating.saving');
  }

  disableSubmitButtons() {
    let btns = this.submitButtons();
    if (btns.length) {
      btns.forEach((btn) => btn.classList.add('disabled'));
    }
  }

  enableSubmitButtons() {
    let btns = this.submitButtons();
    if (btns.length) {
      btns.forEach((btn) => btn.classList.remove('disabled'));
    }
  }

  softDeletePreview() {
    this.disableSubmitButtons();
    this.uploadInputTarget.value = '';
    this.previewTarget.classList.add('d-none');
  }
}

// Based on DirectUploadController from ActiveStorage
class DirectUploadControllerVideoRecorder {
  constructor(file, url, stimulusController) {
    this.file = file;
    this.url = url;
    this.stimulusController = stimulusController;

    this.directUpload = new DirectUpload(file, this.url, this);
    this.start();
  }

  start() {
    this.stimulusController.disableSubmitButtons();
    this.stimulusController.fileNameTarget.innerText = FILE_NAME;
    this.stimulusController.fileTypeTarget.src = `/mimetypes/${last(split(FILE_NAME, '.'))}.png`;
    this.stimulusController.previewTarget.classList.remove('d-none');

    // If the hard delete button is still on the page, that means there is still a file persisted; so we want to allow the user to delete that file
    // (There is a scenario in which they had uploaded a recording, then are planning to locally overwrite it by having recorded another one, but that won't persist until they click Next, but when they delete what they think is the new file, it should actually delete the old file, so if they then refresh the page, there will be nothing persisted)
    if (!this.stimulusController.hasHardDeleteButtonTarget) {
      this.stimulusController.softDeleteButtonTarget.classList.remove('d-none');
    }

    this.directUpload.create((error, attributes) => {
      if (error) {
        // Handle the error
        throw new Error(error);
      } else {
        // Add an appropriately-named hidden input to the form
        // with a value of blob.signed_id
        this.stimulusController.uploadInputTarget.value = attributes.signed_id;
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/upload
    request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    const progress = Math.floor((event.loaded / event.total) * 100);
    let text = `Uploading ${progress}%`;
    console.log('Uploading', progress, event);
    if (progress === 100) {
      text = formatBytes(this.file.size);
      this.stimulusController.enableSubmitButtons();
    }
    this.stimulusController.fileSizeTarget.innerText = text;
  }
}
