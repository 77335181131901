import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="ai-mixers"
export default class extends Controller {
  static values = {
    min: Number,
    max: Number
  };

  static targets = [
    'inUseMixerId',
    'mixerGenerateButton',
    'mode',
    'name',
    'objectPrompt',
    'thumbnailSignedId',
    'selectedMixerId',
    'removedMixerId',
    'prompt',
    'selectModel',
    'confirmDeleteMixer',
    'cancelMixerAction',
    'deleteMixer',
    'confirmRenameMixer',
    'mixerRenameInput',
    'renameMixer',
    'mixerRecipientInput',
    'shareMixer',
    'confirmDeleteSharedMixer',
    'deleteSharedMixer',
    'cancelSharedMixerAction',
    'removeSharedMixer',
    'confirmEditMixerName',
    'editMixerName',
    'mixerEditNameInput',
    'mixerNextButton',
    'objectName'
  ];

  connect() {
    if (this.hasMixerGenerateButtonTarget && this.hasNameTarget && !this.validateMixerCreation()) {
      this.mixerGenerateButtonTarget.setAttribute('disabled', true);
    }

    if (this.hasMixerNextButtonTarget && this.hasObjectNameTarget && !this.validateObjectName()) {
      this.mixerNextButtonTarget.setAttribute('disabled', true);
    }
  }

  validateForm() {
    if (this.hasMixerGenerateButtonTarget) {
      if (this.validateMixerCreation()) {
        this.mixerGenerateButtonTarget.removeAttribute('disabled');
      } else {
        this.mixerGenerateButtonTarget.setAttribute('disabled', true);
      }
    }
  }

  validateObjectClassifierForm() {
    if (this.hasMixerNextButtonTarget) {
      if (this.validateObjectName()) {
        this.mixerNextButtonTarget.removeAttribute('disabled');
      } else {
        this.mixerNextButtonTarget.setAttribute('disabled', true);
      }
    }
  }

  onMixerWeightChange(event) {
    if (event.target.id == this.inUseMixerIdTarget.id) {
      let weight = parseInt(event.target.value);
      if (weight < 0 || weight > 100) return;
      const mixerId = this.inUseMixerIdTarget.value;
      post('/ai/image/update_in_use_mixer', {
        query: { weight, user_mixer_id: mixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  onThumbnailSelected(event) {
    const selectedThumbnailSignedId = event.currentTarget.dataset.signedId;
    const mixerId = document.getElementById('mixer_id')?.value || '';

    post('/ai/image/mixer_thumbnail_selected', {
      query: { thumbnail_signed_id: selectedThumbnailSignedId, mixer_id: mixerId },
      responseKind: 'turbo-stream'
    });
  }

  validateMixerCreation() {
    const value = this.nameTarget.value.trim();
    const attachedMixerImagesCount =
      document.querySelectorAll('.attached_mixer_image')?.length || 0;

    return (
      value &&
      value.length >= 1 &&
      attachedMixerImagesCount >= this.minValue &&
      attachedMixerImagesCount <= this.maxValue
    );
  }

  validateObjectName() {
    const value = this.objectNameTarget.value.trim();

    return value && value.length >= 1;
  }

  selectMixer() {
    let mixerId = '';
    let mixerName = '';
    let sharedMixerId = '';
    let promptValue = document.querySelector('[name="image[prompt]"]')?.value || '';

    if (this.hasSelectedMixerIdTarget) {
      mixerId = this.selectedMixerIdTarget?.dataset?.mixerId;
      mixerName = this.selectedMixerIdTarget?.dataset?.mixerName;

      if (this.selectedMixerIdTarget?.dataset?.sharedMixerId)
        sharedMixerId = this.selectedMixerIdTarget?.dataset?.sharedMixerId;
    }

    document.querySelector('[name="image[prompt]"]').value = promptValue.concat(' ', mixerName);

    post('/ai/image/update_in_use_mixer', {
      query: { mixer_id: mixerId, shared_mixer_id: sharedMixerId },
      responseKind: 'turbo-stream'
    });
  }

  removeMixer() {
    let inUseMixerId = '';
    let mixerName = '';
    let promptValue = document.querySelector('[name="image[prompt]"]')?.value || '';

    if (this.hasRemovedMixerIdTarget) {
      inUseMixerId = this.removedMixerIdTarget?.dataset?.inUseMixerId;
      mixerName = this.removedMixerIdTarget?.dataset?.mixerName;
    }

    document.querySelector('[name="image[prompt]"]').value = promptValue.replace(
      `${mixerName}`,
      ''
    );

    post('/ai/image/update_in_use_mixer', {
      query: { in_use_mixer_id: inUseMixerId },
      responseKind: 'turbo-stream'
    });
  }

  removeMixerKeyword() {
    if (this.hasSelectModelTarget && this.selectModelTarget.value !== 'Felli') {
      let promptValue = document.querySelector('[name="image[prompt]"]')?.value || '';
      let sanitizedPromptValue = document.querySelector('[name="image[prompt]"]').value || '';
      Array.from(document.querySelectorAll('[name="ai_mixer[in_use_keyword]"]')).forEach(
        (input) => {
          if (promptValue.includes(input.value)) {
            sanitizedPromptValue = sanitizedPromptValue.replace(`${input.value}`, '');
          }
        }
      );
      document.querySelector('[name="image[prompt]"]').value = sanitizedPromptValue.trim();
    }
  }

  confirmDeleteMixer(event) {
    event.preventDefault();
    if (this.hasConfirmDeleteMixerTarget) {
      const mixerId = this.confirmDeleteMixerTarget?.dataset?.mixerId;

      post('/ai/image/confirm_delete_mixer', {
        query: { mixer_id: mixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  cancelMixerAction(event) {
    event.preventDefault();
    if (this.hasCancelMixerActionTarget) {
      const mixerId = this.cancelMixerActionTarget?.dataset?.mixerId;

      post('/ai/image/cancel_mixer_action', {
        query: { mixer_id: mixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  deleteMixer(event) {
    event.preventDefault();
    if (this.hasDeleteMixerTarget) {
      const mixerId = this.deleteMixerTarget?.dataset?.mixerId;

      post('/ai/image/delete_mixer', {
        query: { mixer_id: mixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  confirmRenameMixer(event) {
    event.preventDefault();
    if (this.hasConfirmRenameMixerTarget) {
      const mixerId = this.confirmRenameMixerTarget?.dataset?.mixerId;

      post('/ai/image/confirm_rename_mixer', {
        query: { mixer_id: mixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  renameMixer(event) {
    event.preventDefault();
    if (this.hasRenameMixerTarget && this.hasMixerRenameInputTarget) {
      const mixerId = this.renameMixerTarget?.dataset?.mixerId;
      const mixerKeyword = this.mixerRenameInputTarget.value;

      post('/ai/image/rename_mixer', {
        query: { mixer_id: mixerId, alias: mixerKeyword },
        responseKind: 'turbo-stream'
      });
    }
  }

  shareMixer(event) {
    event.preventDefault();
    if (this.hasShareMixerTarget && this.hasMixerRecipientInputTarget) {
      const mixerId = this.shareMixerTarget?.dataset?.mixerId;
      const recipientEmail = this.mixerRecipientInputTarget.value;

      if (recipientEmail.length < 1) {
        this.shareMixerTarget.setAttribute('disabled', true);
      } else {
        this.shareMixerTarget.removeAttribute('disabled');
      }

      post('/ai/image/share_mixer', {
        query: { mixer_id: mixerId, recipient_email: recipientEmail },
        responseKind: 'turbo-stream'
      });
    }
  }

  cancelSharedMixerAction(event) {
    event.preventDefault();
    if (this.hasCancelSharedMixerActionTarget) {
      const sharedMixerId = this.cancelSharedMixerActionTarget?.dataset?.sharedMixerId;

      post('/ai/image/cancel_shared_mixer_action', {
        query: { shared_mixer_id: sharedMixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  confirmDeleteSharedMixer(event) {
    event.preventDefault();
    if (this.hasConfirmDeleteSharedMixerTarget) {
      const sharedMixerId = this.confirmDeleteSharedMixerTarget?.dataset?.sharedMixerId;

      post('/ai/image/confirm_delete_shared_mixer', {
        query: { shared_mixer_id: sharedMixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  deleteSharedMixer(event) {
    event.preventDefault();
    if (this.hasDeleteSharedMixerTarget) {
      const sharedMixerId = this.deleteSharedMixerTarget?.dataset?.sharedMixerId;

      post('/ai/image/delete_shared_mixer', {
        query: { shared_mixer_id: sharedMixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  removeSharedMixer(event) {
    event.preventDefault();
    if (this.hasRemoveSharedMixerTarget) {
      const sharedMixerId = this.removeSharedMixerTarget?.dataset?.sharedMixerId;

      post('/ai/image/remove_shared_mixer', {
        query: { shared_mixer_id: sharedMixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  confirmEditMixerName(event) {
    event.preventDefault();
    if (this.hasConfirmEditMixerNameTarget) {
      const mixerId = this.confirmEditMixerNameTarget?.dataset?.mixerId;

      post('/ai/image/confirm_edit_mixer_name', {
        query: { mixer_id: mixerId },
        responseKind: 'turbo-stream'
      });
    }
  }

  editMixerName(event) {
    event.preventDefault();
    if (this.hasEditMixerNameTarget && this.hasMixerEditNameInputTarget) {
      const mixerId = this.editMixerNameTarget?.dataset?.mixerId;
      const mixerName = this.mixerEditNameInputTarget.value;

      post('/ai/image/update_mixer_name', {
        query: { mixer_id: mixerId, name: mixerName },
        responseKind: 'turbo-stream'
      });
    }
  }

  mixerNextButton(event) {
    event.preventDefault();
    if (this.hasMixerNextButtonTarget && this.hasObjectNameTarget) {
      const mixerId = this.mixerNextButtonTarget?.dataset?.mixerId || '';
      const mixerObjectPrompt = this.objectNameTarget.value;

      if (mixerObjectPrompt.length < 1) {
        this.mixerNextButtonTarget.setAttribute('disabled', true);
      } else {
        this.mixerNextButtonTarget.removeAttribute('disabled');
      }

      post('/ai/image/update_mixer_object_prompt', {
        query: { mixer_id: mixerId, mixer_object_prompt: mixerObjectPrompt },
        responseKind: 'turbo-stream'
      });
    }
  }
}
