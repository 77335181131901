import { Controller } from '@hotwired/stimulus';
import introJs from 'intro.js';
import { post } from '@rails/request.js';
import { debounce } from 'lodash';

// Connects to data-controller="ai-hint"
export default class extends Controller {
  static values = {
    hints: Object
  };

  static targets = ['selectModel', 'prompt'];

  modelSuggestionCookieName = 'ai-model-suggestion';
  modelSuggestionId = 'MODEL_SUGGESTION';

  modelSuggestionShown = false;

  initialize() {
    this.onPromptInputChangeDebounced = debounce(() => {
      let model = this.selectModelTarget.value;
      let prompt = this.promptTarget.value;
      if (!this.modelSuggestionShown && model !== 'Fresko' && prompt.includes('logo')) {
        this.showHintForGivenId(this.modelSuggestionId);
        this.modelSuggestionShown = true;
      }
    }, 500);
  }

  onPromptInputChange() {
    if (this.hasSelectModelTarget && this.hasPromptTarget) {
      this.onPromptInputChangeDebounced();
    }
  }

  getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  showHintForGivenId(hint_id) {
    const steps = this.hintsValue[hint_id].main_steps;
    // check if tutorial config has responsive steps
    if (this.responsiveElements(hint_id).length) {
      this.responsiveElements(hint_id).forEach((elem) => {
        // If we have responsive elements and they are visible
        // inject the step at its correct position for the responsive element
        if (elem.dataset.step && elem.offsetHeight > 0) {
          steps.splice(
            Number(elem.dataset.step) - 1,
            0,
            this.responsiveStep(elem.dataset.intro, hint_id)
          );
        }
      });
    }

    steps.forEach(this.addElement);
    let controller = this;
    introJs()
      .setOptions({
        steps: steps,
        showBullets: false,
        showButtons: false,
        dontShowAgain: true,
        dontShowAgainLabel: 'Dont show again',
        dontShowAgainCookie: this.modelSuggestionCookieName,
        doneLabel: 'OK'
      })
      .onexit(() => {
        this.updateUserTutorialIds();
        document.getElementById('offcanvasRightPanel')?.classList?.remove('show');
      })
      .onafterchange(function () {
        let acceptButton = document.getElementById('ai_accept_suggestion');
        acceptButton.addEventListener('click', ()=>{
          controller.changeModel();
          this.exit();
        });
      })
      .onbeforechange(function (targetElement) {
        let element_from_right_panel = null;
        if (targetElement) {
          element_from_right_panel =
            targetElement.offsetParent.classList.value.includes('offcanvas-right');
        }
        if (
          element_from_right_panel &&
          !document.getElementById('offcanvasRightPanel').classList.contains('show')
        ) {
          document.getElementById('offcanvasRightPanel').classList.add('show');

          return new Promise((resolve) => {
            setInterval(resolve, 500);
          });
        } else {
          document.getElementById('offcanvasRightPanel')?.classList?.remove('show');
        }
      })
      .start();
  }

  addElement(item) {
    const element_id = item.element;
    item.element = document.querySelectorAll('[data-intro="' + element_id + '"]')[0];
  }

  responsiveElements(hint_id) {
    return this.hintsValue[hint_id].responsive_steps.map(({ element }) =>
      document.querySelector(`[data-intro="${element}"]`)
    );
  }

  responsiveStep(element_selector, hint_id) {
    return this.hintsValue[hint_id].responsive_steps.find(
      ({ element }) => element === element_selector
    );
  }

  updateUserTutorialIds() {
    if (this.getCookie(this.modelSuggestionCookieName) === 'true') {
      post('/users/update_intro_tutorial_ids', {
        query: { tutorial_id: this.modelSuggestionId }
      });
    }
  }

  changeModel(){
    const category = window.location.pathname.split('/').pop();
    this.updateUserTutorialIds();
    post('/ai/image/advanced_settings_for_model', {
      query: { selected_model: 'Fresko' },
      responseKind: 'turbo-stream'
    });
    post('/ai/image/selected_ai_model', {
      query: { model: 'Fresko', category },
      responseKind: 'turbo-stream'
    });
  }
}
