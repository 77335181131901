import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="dreamscale"
export default class extends Controller {
  connect() {}

  onCreativitySelected(event) {
    const selectedCreativityOption = event.currentTarget.value;

    post('/ai/image/creativity_selected', {
      query: { creativity_option: selectedCreativityOption },
      responseKind: 'turbo-stream'
    });
  }
}
