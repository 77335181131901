import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  timeoutPointer = null;

  text = null;

  connect() {
    this.text = this.element.innerHTML || '';
    this.element.innerHTML = '';
    this.typeChar();
  }
  disconnect() {
    clearTimeout(this.timeoutPointer);
  }
  typeChar() {
    let length = this.element.innerHTML.length;
    if (length >= this.text.length) {
      return;
    }

    this.element.innerHTML = this.text.substring(0, length + 1);

    this.timeoutPointer = setTimeout(() => {
      this.typeChar();
    }, 50);
  }
}
