import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'nav',
    'navTemplate',
    'navParent',
    'withTabsButtonTemplate',
    'noTabsButtonTemplate',
    'add_association',
    'add_nested_association',
    'nested_association',
    'association',
    'add_button'
  ];

  static values = {
    associationIndex: Number,
    nestedAssociationIndex: Number,
    usesTurboDelete: Boolean
  };

  static classes = ['disabled'];

  add_association(event) {
    event.preventDefault();
    this.associationIndexValue++;
    let association = this.associationTarget.innerHTML.replace(
      /ASSOCIATION_RECORD/g,
      this.associationIndexValue
    );

    if (this.hasNavTarget) {
      this.tabsAddUpdate();
    }

    this.add_associationTarget.insertAdjacentHTML('beforeend', association);

    if (this.hasNavTarget) {
      this.navTabsAddUpdate();
    }
  }

  remove_association_tab(event) {
    this.associationIndexValue--;
    this.tabsRemoveUpdate(event);
  }

  remove_association(event) {
    event.preventDefault();
    this.associationIndexValue--;
    let association = event.target.closest('.nested-fields');
    let destroyField = association.querySelector('input[name*="_destroy"]');
    if (destroyField) {
      destroyField.value = 1;
    }
    if (this.usesTurboDeleteValue) {
      association.remove();
    }
  }

  remove_association_with_turbo_delete(event) {
    this.associationIndexValue--;
    let association = event.currentTarget.closest('.nested-fields');
    let destroyField = association.querySelector('input[name*="_destroy"]');
    if (destroyField) {
      destroyField.value = 1;
    }
    if (this.usesTurboDeleteValue) {
      association.remove();
    }
  }

  add_nested_association(event) {
    event.preventDefault();
    this.nestedAssociationIndexValue++;
    let nested_association = this.nested_associationTarget.innerHTML
      .replace(/NESTED_ASSOCIATION/g, this.nestedAssociationIndexValue)
      .replace(/NESTED_ALPHA/g, String.fromCharCode(64 + this.nestedAssociationIndexValue));
    this.add_nested_associationTarget.insertAdjacentHTML('beforebegin', nested_association);
  }

  remove_nested_association(event) {
    event.preventDefault();
    this.nestedAssociationIndexValue--;
    let nested_association = event.target.closest('.option-form');
    nested_association.remove();
  }

  // -- DAN TEST ---------------------------------------
  // We should review the two different ways of doing
  // this and create a common solution.

  add_nested_object(event) {
    this.associationIndexValue++;
    let regexp = new RegExp(event.currentTarget.dataset.id, 'g');
    let html = event.currentTarget.dataset.fields.replace(regexp, this.associationIndexValue);
    if (this.hasNavTarget) {
      this.tabsAddUpdate();
      this.add_associationTarget.insertAdjacentHTML('beforeend', html);
      this.navTabsAddUpdate();
    } else {
      document.querySelector('.nested-object-fields').insertAdjacentHTML('beforeend', html);
    }

    this.toggle_add_button();
    this.toggle_delete_button();

    return event.preventDefault();
  }

  remove_nested_object(event) {
    this.associationIndexValue--;
    if (this.hasNavTarget) {
      this.tabsRemoveUpdate(event);
    } else {
      let association = event.currentTarget.closest('.association-group');
      association.querySelector('input[name*="_destroy"]').value = 1;
      association.classList.add('d-none');
    }

    this.toggle_add_button();
    this.toggle_delete_button();

    return event.preventDefault();
  }

  remove_array_object(event) {
    let association = event.target.closest('.association-group');
    association.remove();

    return event.preventDefault();
  }

  toggle_add_button() {
    let max_objects = this.add_buttonTarget.dataset.maxObjects;

    if (this.associationIndexValue + 1 >= max_objects) {
      this.add_buttonTarget.classList.add('d-none');
    } else {
      this.add_buttonTarget.classList.remove('d-none');
    }
  }

  toggle_delete_button() {
    if (this.associationIndexValue === 0) {
      document.querySelectorAll('.delete_fields').forEach((element) => {
        if (!element.classList.contains('optional')) {
          element.classList.add('d-none');
        }
      });
    } else {
      document.querySelectorAll('.delete_fields').forEach((element) => {
        element.classList.remove('d-none');
      });
    }
  }

  navTabsAddUpdate() {
    let navItem = this.navTemplateTarget.innerHTML
      .replace(/TAB_INDEX/g, this.associationIndexValue)
      .replace(/TAB_TEXT/g, this.associationIndexValue + 1);
    [...this.navTarget.children].forEach((child) => {
      let activeTag = child.querySelector('a.active');
      if (activeTag) {
        activeTag.classList.remove('active');
      }
    });
    this.navTarget.insertAdjacentHTML('beforeend', navItem);
    if (this.associationIndexValue > 0) {
      this.navParentTarget.classList.remove('d-none');
      this.add_buttonTarget.remove();
      this.navParentTarget.insertAdjacentHTML(
        'afterend',
        this.withTabsButtonTemplateTarget.innerHTML
      );
    }
  }

  tabsAddUpdate() {
    [...this.add_associationTarget.children].forEach((child) => {
      child.classList.remove('active');
      child.classList.remove('show');
    });
  }

  tabsRemoveUpdate(event) {
    let tabContent = event.currentTarget.closest('.tab-pane');
    tabContent.querySelector('input[name*="_destroy"]').value = 1;
    let index = event.currentTarget.dataset.index;
    let activateIndex =
      index <= this.associationIndexValue
        ? this.associationIndexValue + 1
        : this.associationIndexValue;
    let navItemToRemove = this.navTarget.querySelector(`#tab-nav-item${index}`).parentElement;
    let navItemToActivate = this.navTarget.querySelector(`#tab-nav-item${activateIndex}`);
    navItemToRemove.remove();
    if (navItemToActivate) {
      navItemToActivate.classList.add('active');
    }

    let tabToActivate = this.add_associationTarget.querySelector(`#nav-target-${activateIndex}`);
    if (tabToActivate) {
      tabToActivate.classList.add('show');
      tabToActivate.classList.add('active');
    }

    if (this.usesTurboDeleteValue) {
      tabContent.remove();
    } else {
      tabContent.classList.add('d-none');
      tabContent.removeAttribute('id');
    }
    let navItems = this.navTarget.querySelectorAll('a.nav-link');
    navItems.forEach((item, index) => (item.innerText = index + 1));
    if (this.associationIndexValue < 1) {
      this.navParentTarget.classList.add('d-none');
      this.add_buttonTarget.remove();
      this.navParentTarget.insertAdjacentHTML(
        'afterend',
        this.noTabsButtonTemplateTarget.innerHTML
      );
    }
  }
}
