import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['actionsDropdown', 'selectedResourceIds', 'form', 'submitButton'];

  connect() {
    if (this.hasActionsDropdownTarget) {
      this.hideActionsDropdown();
    }
  }

  updateSelectedResourcesField(event) {
    let currentTarget = event.currentTarget;
    let clickedResourceId = currentTarget.dataset.resourceId;
    let selectedResourceIds = this.parsedToJson(this.selectedResourceIdsTarget.value);

    if (currentTarget.checked) {
      this.showActionsDropdown();
      selectedResourceIds.push(clickedResourceId);
      this.selectedResourceIdsTarget.value = this.stringifyIds(selectedResourceIds);
    } else {
      selectedResourceIds = selectedResourceIds.filter((id) => id !== clickedResourceId);
      this.selectedResourceIdsTarget.value = this.stringifyIds(selectedResourceIds);
      if (selectedResourceIds.length === 0) {
        this.hideActionsDropdown();
      }
    }
  }

  performAction(event) {
    event.preventDefault();

    const method = event.currentTarget.dataset.method;
    const turboEnabled = event.currentTarget.dataset.turbo;

    let url = event.currentTarget.dataset.path;

    this.formTarget.method = method;
    this.formTarget.action = url;
    this.formTarget.dataset.turbo = turboEnabled;

    this.submitButtonTarget.click();
  }

  selectAll(event) {
    let selectedResourceIds = [];

    if (event.currentTarget.checked) {
      selectedResourceIds = this.selectAllResourceIds();
      if (selectedResourceIds.length === 0) {
        this.hideActionsDropdown();
      } else {
        this.showActionsDropdown();
      }
    } else {
      this.hideActionsDropdown();
      this.unselectAllCheckboxes();
    }
    this.selectedResourceIdsTarget.value = this.stringifyIds(selectedResourceIds);
  }

  selectAllResourceIds() {
    let resourceIds = [];
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
      resourceIds.push(checkbox.dataset.resourceId);
    });

    return resourceIds;
  }

  unselectAllCheckboxes() {
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  hideActionsDropdown() {
    this.actionsDropdownTarget.hidden = true;
  }

  showActionsDropdown() {
    this.actionsDropdownTarget.hidden = false;
  }

  parsedToJson(value) {
    if (value === '') {
      return [];
    }

    return JSON.parse(value);
  }

  stringifyIds(value) {
    return JSON.stringify(value);
  }

  get checkboxes() {
    return document.querySelectorAll('input[type=checkbox][data-resource-id]');
  }
}
