import { Controller } from '@hotwired/stimulus';
import { validEmail, validNewPassword, validInputValue } from '../helpers/input_validations';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['email', 'code', 'password', 'button'];
  connect() {
    this.buttonTarget.setAttribute('disabled', true);
    this.validateForm();
  }

  validateForm() {
    let valid = debounce(() => this.validate(), 300);
    valid();
  }

  validate() {
    if (this.validateEmail() && this.validateCode() && this.validatePassword()) {
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.disabled = true;
    }
  }

  validateEmail() {
    return validEmail(this.emailTarget.value);
  }

  validatePassword() {
    return validNewPassword(this.passwordTarget.getAttribute('data-password-status'));
  }

  validateCode() {
    return validInputValue(this.codeTarget.value);
  }
}
