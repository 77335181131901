import { Controller } from '@hotwired/stimulus';
import { findElement } from '../helpers';

export default class extends Controller {
  static values = {
    // We can't use a "target" for this, since this el might live above this controller
    selector: String,
    path: String,
    formId: String
  };

  clickEl() {
    if (this.hasFormIdValue && this.hasPathValue) {
      let form = findElement(this.formIdValue);
      form.setAttribute('action', this.pathValue);
    }

    let el = findElement(this.selectorValue);
    if (el) {
      el.click();
    }
  }
}
