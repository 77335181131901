import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="locked-ai-model-membership-modal"
export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element);
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }
}
