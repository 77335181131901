import { Controller } from '@hotwired/stimulus';
import { clamp } from 'lodash';

const LOCALE = 'en-US';

/**
 * Parse a localized number to a float. based on: https://stackoverflow.com/questions/29255843/is-there-a-way-to-reverse-the-formatting-by-intl-numberformat-in-javascript
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
function parseLocaleNumber(stringNumber, locale) {
  locale = locale ?? LOCALE;
  let thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  let decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return (
    parseFloat(
      stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
        // remove special characters that we inject to denote that a number can be <= or greater/equal than +
        .replace(new RegExp('\\+', 'g'), '')
        .replace(new RegExp('=', 'g'), '')
        .replace(new RegExp('<', 'g'), '')
        .replace(new RegExp('>', 'g'), '')
    ) || 0
  );
}

function formatLocaleNumber(number) {
  return new Intl.NumberFormat(LOCALE).format(number);
}

export default class extends Controller {
  static targets = [
    'fromSlider',
    'toSlider', // the slider knobs
    'fromInput',
    'toInput', // the inputs the user types into
    'fromHidden',
    'toHidden' // the inputs with the actual value for Rails form submission
  ];
  static values = {
    min: Number,
    max: Number,
    stepInterval: Number,
    specialCharacterType: String
  };

  connect() {
    // This gets triggered by the custom form reset logic, for exaple. If something external wants to manipulate the hidden inputs, this syncs up all the inputs again
    this.fromHiddenTarget.addEventListener('custom-change', () => {
      this.restoreHiddenValues();
    });

    // applies formatting based on initial hidden input values
    this.restoreHiddenValues();
  }

  restoreHiddenValues() {
    let [from, to] = this.getParsed(this.fromHiddenTarget, this.toHiddenTarget, true, true, false);
    // If values are blank, set them as the defaults
    if (this.fromHiddenTarget.value === '') {
      from = this.minValue;
    }
    if (this.toHiddenTarget.value === '') {
      to = this.maxValue;
    }

    this.setValues(to, from);
  }

  controlFromInput() {
    let [from, to] = this.getParsed(this.fromInputTarget, this.toInputTarget);
    this.setValues(to, from);
  }

  controlToInput() {
    let [from, to] = this.getParsed(this.fromInputTarget, this.toInputTarget);
    this.setToggleAccessible(this.toInputTarget);
    this.setValues(to, from);
  }

  controlFromSlider() {
    let [from, to] = this.getParsed(this.fromSliderTarget, this.toSliderTarget, true, true, true);
    this.setValues(to, from);
  }
  controlToSlider() {
    let [from, to] = this.getParsed(this.fromSliderTarget, this.toSliderTarget, true, false, true);
    this.setToggleAccessible(this.toSliderTarget);
    this.setValues(to, from);
  }

  getParsed(currentFrom, currentTo, makeValid, moveTo, snap) {
    let from = parseLocaleNumber(currentFrom.value);
    let to = parseLocaleNumber(currentTo.value);

    // always clamp to max
    from = clamp(from, 0, this.maxValue);
    to = clamp(to, 0, this.maxValue);

    if (makeValid) {
      // Clamp to min/max
      from = clamp(from, this.minValue, this.maxValue);
      to = clamp(to, this.minValue, this.maxValue);

      if (from > to) {
        if (moveTo) {
          to = from;
        } else {
          from = to;
        }
      }
    }

    if (snap) {
      // Snaps to interval (ex: get value to nearest 1000)
      from = Math.floor(from / this.stepIntervalValue) * this.stepIntervalValue;
      to = Math.floor(to / this.stepIntervalValue) * this.stepIntervalValue;
    }

    return [from, to];
  }

  setValues(to, from) {
    // Always reformat user input strings regardless of if it's valid
    this.fromInputTarget.value = formatLocaleNumber(from);
    this.toInputTarget.value = formatLocaleNumber(to);

    // Inject special characters into user input to denote we treat this inputs differently than other numbers
    let atMin = from === this.minValue;
    let atMax = to === this.maxValue;
    let atMinSpecialCharacter = '';
    let atMaxSpecialCharacter = '';
    if (this.specialCharacterTypeValue === 'greater_than_and_less_than') {
      atMinSpecialCharacter = '>=';
      atMaxSpecialCharacter = '<=';
    } else if (this.specialCharacterTypeValue == 'greater_than_and_plus') {
      atMinSpecialCharacter = '>=';
      atMaxSpecialCharacter = '+';
    } else {
      atMinSpecialCharacter = '<=';
      atMaxSpecialCharacter = '+';
    }

    if (atMin) {
      this.fromInputTarget.value = `${atMinSpecialCharacter}${this.fromInputTarget.value}`;
    }
    if (atMax) {
      this.toInputTarget.value =
        this.specialCharacterTypeValue === 'greater_than_and_less_than'
          ? `${atMaxSpecialCharacter}${this.toInputTarget.value}`
          : `${this.toInputTarget.value}${atMaxSpecialCharacter}`;
    }

    // reset the visual indicator to show that the values are valid
    this.fromInputTarget.classList.remove('is-invalid');
    this.toInputTarget.classList.remove('is-invalid');

    // prevent setting invalid range
    if (from > to) {
      // Would be nice to set something visual to make it clear that the values are invalid here
      this.toInputTarget.classList.add('is-invalid');
      this.fromInputTarget.classList.add('is-invalid');
      return;
    }
    if (from < this.minValue) {
      this.fromInputTarget.classList.add('is-invalid');
      return;
    }
    if (to > this.maxValue) {
      this.toInputTarget.classList.add('is-invalid');
      return;
    }

    // This has to be set as empty when From is at min/etc, because it means there is no min. So <=1000 means 500 should also show in the results
    this.fromHiddenTarget.value = atMin ? '' : from;
    this.toHiddenTarget.value = atMax ? '' : to;

    this.fromSliderTarget.value = from;
    this.toSliderTarget.value = to;
    this.refreshFillSlider();
  }

  refreshFillSlider() {
    this.fillSlider(
      this.fromInputTarget,
      this.toInputTarget,
      '#ced4da',
      '#55a6c4',
      this.toSliderTarget
    );
  }

  fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
    const rangeDistance = this.maxValue - this.minValue;
    // using slider value here since we want the lowest/highest number value instead of null here (so 500000 when that's the max, instead of '')
    const fromPosition = this.fromSliderTarget.value - this.minValue;
    const toPosition = this.toSliderTarget.value - this.minValue;
    controlSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
      ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
      ${rangeColor} ${(toPosition / rangeDistance) * 100}%,
      ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
      ${sliderColor} 100%)`;
  }

  setToggleAccessible(currentTarget) {
    if (Number(currentTarget.value) <= 0) {
      this.toSliderTarget.style.zIndex = 2;
    } else {
      this.toSliderTarget.style.zIndex = 0;
    }
  }
}
