<script>
  import { railsGet } from '~/services/railsQuery.svelte';

  // You could technically bypass using the special library, but using it allows it to update the pageState for you under the hood
  let slowEndpointQuery = railsGet('/develop/svelte_playground/slow_endpoint_example');

  // Derived is just like a computed / memoized value that updates when the dependencies change automatically (no need to declare what they are separately)
  let completedAtDate = $derived(new Date(slowEndpointQuery.data?.completed_at));

  $effect(() => {
    if (slowEndpointQuery.isSuccess) {
      // Effect + checking for isSuccess is better than awaiting a promise, because this won't run if the component is destroyed before the query finishes, but awaiting a fetch/etc would have that issue
      console.log('SlowEndpointExample: network call just succeeded!', completedAtDate);
    }
  });
</script>

<div class="my-5 border">
  Slow Endpoint Example: [{slowEndpointQuery.status}]
  {#if slowEndpointQuery.data?.completed_at}
    <span class="text-xs text-gray-500"
      >(Last server response from <i>{completedAtDate}</i> was
      <b>{slowEndpointQuery.data.answer_to_life}</b>)</span
    >
  {/if}

  <div class="m-1">
    <div>
      Last User:
      {#if slowEndpointQuery.data?.last_user}
        <b>{slowEndpointQuery.data.last_user.name}</b>, who was <i>viewing_as</i>
        <b>{slowEndpointQuery.data.last_user.viewing_as}</b>, and has created
        <b>{slowEndpointQuery.data.last_user.ai_image_histories_count}</b>
        AI images. Their plan: <b>{slowEndpointQuery.data.last_user.plan.name}</b>
      {:else}
        ...
      {/if}
    </div>

    <div class="mt-1">
      {#if slowEndpointQuery.isLoading}
        <span>Loading...</span>
      {:else if slowEndpointQuery.isError}
        <span>Error: {slowEndpointQuery.error.message}</span>
      {:else}
        <span>Loaded! Answer to life is: <b>{slowEndpointQuery.data.answer_to_life}</b></span>
        <div>
          <button onclick={() => slowEndpointQuery.refetch()}>Refresh</button>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .border {
    padding: 1rem;
  }
</style>
