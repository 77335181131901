import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="auto-cycle-texts"
export default class extends Controller {
  static targets = ['text'];

  static values = {
    otherTexts: Array
  };

  currentIndex = 0;
  timeoutRef = null;

  connect() {
    this.scheduleNextText();
  }

  disconnect() {
    this.unscheduleNextText();
  }

  scheduleNextText() {
    this.timeoutRef = setTimeout(() => {
      this.showNextText();
      this.scheduleNextText();
    }, 5000);
  }

  unscheduleNextText() {
    clearTimeout(this.timeoutRef);
    this.timeoutRef = null;
  }

  showNextText() {
    let curEl = this.textTargets[this.currentIndex];
    // curEl.classList.remove('vanishIn');
    curEl.classList.remove('fadeIn');
    curEl.classList.add('vanishOut');

    this.currentIndex = (this.currentIndex + 1) % this.textTargets.length;
    let nextEl = this.textTargets[this.currentIndex];
    // nextEl.classList.add('vanishIn');
    nextEl.classList.add('fadeIn');
    nextEl.classList.remove('vanishOut');
    nextEl.classList.remove('d-none');
  }
}
