import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

// Connects to data-controller="download-file"
export default class extends Controller {
  connect() {}

  subscribeToDownloadChannel() {
    let element = this.element;
    let historyId = element.dataset.historyId;

    consumer.download = consumer.subscriptions.create(
      {
        channel: 'DownloadFileChannel',
        history_id: historyId
      },
      {
        connected() {},

        disconnected() {},

        received(data) {
          let fileContent = data['file'];
          let href = '';
          if (fileContent['content']) {
            let blob = new Blob([data['file']['content']]);
            href = window.URL.createObjectURL(blob);
          } else {
            href = fileContent['src'];
          }

          let download_link = document.createElement('a');
          download_link.href = href;
          download_link.download = fileContent['name'];
          download_link.click();

          consumer.download.unsubscribe();
          delete consumer.download;
        }
      }
    );
  }
}
