import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';
import { UploadManager } from '../helpers/upload_manager';
import { emit } from '../helpers/eventbus';
import { getAcceptedFilesFromFeature } from '../helpers/accepted_file_types';
import { showToast } from '../helpers/toast_functions';

// Connects to data-controller="turbo-uploader"
export default class extends Controller {
  static targets = [
    'dropAreaVisual',
    'filesHolder',
    'file',
    'inputUpload',
    'inputParamName',
    'uploadButton',
    'minFilesEnforcer',
    'showWhileUploading',
    'hideWhileUploading',
    'showWhenZeroFiles', // Els to only show when there are 0 files
    'showWhenSomeFiles', // Els to only show when there are > 0 files
    'totalPercentage'
  ];
  fileElementByKey = new Map();

  static values = {
    config: Object // Enables the uploader / turbo stream websocket channel when set, allows template to be different
  };

  getContext() {
    return this.configValue?.context;
  }

  getContextType() {
    return this.getContext()?.type;
  }

  getHiddenInputFileName() {
    return this.configValue?.hidden_input_file_name;
  }

  get acceptedFilesFeature() {
    return this.configValue?.accepted_files_feature || this.getContextType();
  }

  get maxFiles() {
    return this.configValue?.max_files;
  }

  get minFiles() {
    return this.configValue?.min_files || 0;
  }

  get uploadsCompleteCallback() {
    return this.configValue?.uploads_complete_callback;
  }
  get uploadCompleteCallback() {
    return this.configValue?.upload_complete_callback;
  }

  get currentFilesCount() {
    // FileTargets are everything with a Blob key; PreBlobUploads are the ones which aren't in the template yet / don't have a blob key yet
    return this.fileTargets.length + this.uploadManager.countPreBlobUploads;
  }
  refreshUploadButtonsEnabled() {
    if (this.maxFiles > 0) {
      for (let uploadButton of this.uploadButtonTargets) {
        uploadButton.disabled = this.currentFilesCount >= this.maxFiles;
      }
    }
  }

  refreshIsUploadingUI() {
    let anyUploading = this.uploadManager.countUploading > 0;

    for (let el of this.showWhileUploadingTargets) {
      el.classList[anyUploading ? 'remove' : 'add']('d-none');
    }
    for (let el of this.hideWhileUploadingTargets) {
      el.classList[anyUploading ? 'add' : 'remove']('d-none');
    }
  }

  refreshShowZeroFilesUI() {
    let anyFiles = this.currentFilesCount > 0;

    for (let el of this.showWhenZeroFilesTargets) {
      el.classList[anyFiles ? 'add' : 'remove']('d-none');
    }
    for (let el of this.showWhenSomeFilesTargets) {
      el.classList[anyFiles ? 'remove' : 'add']('d-none');
    }

    // CSS classes to add only when zero files (and remove otherwise)
    let cssOnlyWhenZeroFilesTargets = this.element.querySelectorAll(
      '[data-turbo-uploader-css-only-when-zero-files]'
    );
    for (let el of cssOnlyWhenZeroFilesTargets) {
      let classes = el.dataset.turboUploaderCssOnlyWhenZeroFiles.split(' ');
      classes.forEach((className) => el.classList[anyFiles ? 'remove' : 'add'](className));
    }
  }

  refreshMinFilesValidation() {
    // Tells the FormValidation whether this is valid
    if (this.hasMinFilesEnforcerTarget) {
      if (this.currentFilesCount >= this.minFiles) {
        delete this.minFilesEnforcerTarget.dataset.required;
      } else {
        this.minFilesEnforcerTarget.dataset.required = 'true';
      }
    }
  }

  initialize() {
    this.uploadManager = new UploadManager({
      uploadProgressCallback: this.onUploadProgress.bind(this)
    });
  }

  connect() {
    this.hideDropAreaVisualDebounced = debounce(this.hideDropAreaVisual.bind(this), 100);

    // Set the accepted files for the input element / file picker
    if (this.hasInputUploadTarget) {
      this.inputUploadTarget.accept = this.acceptedFiles;
    }

    this.refreshUploadButtonsEnabled();
    this.refreshShowZeroFilesUI();
    this.refreshMinFilesValidation();
  }

  disconnect() {
    // this.unsubscribeAll();
  }

  inputUploadTargetConnected() {
    this.refreshAcceptedFileTypes();
  }

  inputParamNameTargetConnected() {
    if (this.hasInputParamNameTarget && this.getHiddenInputFileName()) {
      this.inputParamNameTarget.name = this.getHiddenInputFileName();
    }
  }

  refreshAcceptedFileTypes() {
    if (!this.hasInputUploadTarget) return;

    // Set the accepted files for the input element / file picker
    this.inputUploadTarget.accept = this.acceptedFiles;
  }

  get acceptedFiles() {
    return getAcceptedFilesFromFeature(this.acceptedFilesFeature);
  }

  fileTargetConnected(fileEl) {
    let { key, temporaryId } = fileEl.dataset;
    this.fileElementByKey.set(key, fileEl);
    // DirectUpload doesn't tell us the blob key until after the upload is complete, but the TurboStream can tell us as the upload is beginning, so we can then react to upload progress events
    // Without this, the progress events would not include the key, as the Upload would not know it yet
    if (temporaryId) {
      this.uploadManager.onLearnedKeyForTemporaryId(temporaryId, key);

      // Could use this to already show the image as it's uploading, but it causes the flicker as the image has to be reloading after upload complete, so maybe nicer to just show filetype placeholder during upload, and get it nicely formated with ImgIx after
      // let upload = this.uploadManager.onLearnedKeyForTemporaryId(temporaryId, key);
      // let imgEl = fileEl.querySelector('img[data-is-image-upload]');
      // if(imgEl){
      //   imgEl.src = await readAsDataURL(upload.file);
      // }
    }

    this.refreshUploadButtonsEnabled();
    this.refreshShowZeroFilesUI();
    this.refreshMinFilesValidation();
    emit('turboUploader:filesCountChanged');
  }

  fileTargetDisconnected() {
    this.refreshUploadButtonsEnabled();
    this.refreshShowZeroFilesUI();
    this.refreshMinFilesValidation();
    emit('turboUploader:filesCountChanged');
  }

  onUploadProgress(event) {
    let { key, progress } = event;
    if (!key) return;
    let fileEl = this.fileElementByKey.get(key);
    // If we don't have this El, we must be the wrong TurboUploader stimulus controller for this file, so ignore this
    if (!fileEl) return;

    let progressEl = fileEl.querySelector('[data-progress]');
    if (progressEl) {
      progressEl.innerText = `${progress}%`;
    }

    let { totalPercentageUploaded } = this.uploadManager;
    for (let el of this.totalPercentageTargets) {
      el.innerText = `${totalPercentageUploaded}%`;
    }
  }

  showDropAreaVisual() {
    this.hideDropAreaVisualDebounced.cancel();
    this.dropAreaVisualTarget.classList.remove('invisible');
  }

  hideDropAreaVisual() {
    this.dropAreaVisualTarget.classList.add('invisible');
  }

  pickFiles() {
    // pop up file picker
    this.inputUploadTarget.click();
  }

  pickFilesIfBelowMax() {
    if (this.currentFilesCount >= this.maxFiles) return;
    this.pickFiles();
  }

  onFilesPicked() {
    [...this.inputUploadTarget.files].forEach((file) => {
      this.startUpload(file);
    });
  }

  onFilesDropped(event) {
    event.preventDefault();
    this.hideDropAreaVisual();

    // Use DataTransfer interface to access the file(s)
    [...event.dataTransfer.files].forEach((file) => {
      this.startUpload(file);
    });
  }

  onFilesDragEnter() {
    this.showDropAreaVisual();
  }
  onFilesDragOver(event) {
    event.preventDefault();
    this.showDropAreaVisual();
  }
  onFilesDragLeave() {
    this.hideDropAreaVisualDebounced();
  }

  get directUploadUrl() {
    return this.element.getAttribute('data-direct-upload-url');
  }

  startUpload(file) {
    // Enforce accepted files for drag and drop
    if (this.acceptedFiles && !this.acceptedFiles.includes(file.type)) {
      showToast('danger', `You can't upload files of this type: ${file.name}`);
      return;
    }

    if (this.currentFilesCount >= this.maxFiles) {
      showToast('danger', `You have reached the max amount of attachable files: ${this.maxFiles}`);
      return;
    }

    let upload = this.uploadManager.startUpload(file, {
      directUploadUrl: this.directUploadUrl,
      uploadCompleteCallback: this.uploadCompleteCallback,
      uploadsCompleteCallback: this.uploadsCompleteCallback,
      context: this.getContext()
    });

    this.refreshIsUploadingUI();
    this.refreshShowZeroFilesUI();

    let div = document.createElement('div');
    // div.className = 'd-none';
    div.id = upload.temporaryId;
    this.filesHolderTarget.appendChild(div);
  }
}
