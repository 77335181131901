import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggler', 'yearlyOption', 'monthlyOption', 'monthlyPlan', 'yearlyPlan'];

  currentOption = '';
  selectedClass = '';
  unSelectedClass = '';

  connect() {
    if (this.yearlyOptionTarget.getAttribute('class').includes('active')) {
      this.currentOption = 'yearly';
      this.selectedClass = this.yearlyOptionTarget.getAttribute('class');
      this.unSelectedClass = this.monthlyOptionTarget.getAttribute('class');
      this.hideMonthlyPlans();
    } else {
      this.currentOption = 'monthly';
      this.selectedClass = this.monthlyOptionTarget.getAttribute('class');
      this.unSelectedClass = this.yearlyOptionTarget.getAttribute('class');
      this.hideYearlyPlans();
    }
  }

  toggle() {
    if (this.currentOption === 'yearly') {
      this.yearlyOptionTarget.setAttribute('class', this.unSelectedClass);
      this.monthlyOptionTarget.setAttribute('class', this.selectedClass);
      this.showMonthlyPlans();
      this.hideYearlyPlans();
      this.currentOption = 'monthly';
    } else {
      this.yearlyOptionTarget.setAttribute('class', this.selectedClass);
      this.monthlyOptionTarget.setAttribute('class', this.unSelectedClass);
      this.showYearlyPlans();
      this.hideMonthlyPlans();
      this.currentOption = 'yearly';
    }
  }

  hideMonthlyPlans() {
    this.monthlyPlanTargets.forEach((targ) => targ.setAttribute('hidden', true));
    // this.monthlyPlansTarget.setAttribute('hidden', true);
  }

  showMonthlyPlans() {
    this.monthlyPlanTargets.forEach((targ) => targ.removeAttribute('hidden'));
    // this.monthlyPlansTarget.removeAttribute('hidden');
  }

  hideYearlyPlans() {
    this.yearlyPlanTargets.forEach((targ) => targ.setAttribute('hidden', true));
    // this.yearlyPlansTarget.setAttribute('hidden', true);
  }

  showYearlyPlans() {
    this.yearlyPlanTargets.forEach((targ) => targ.removeAttribute('hidden'));
  }
}
