import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="flow-dashboard"
export default class extends Controller {
  static targets = ['platform', 'overview', 'country', 'charts'];
  connect() {
    this.overviewSnippet = this.overviewTarget;
    this.overviewRemoved = false;
  }

  get noPlatformSelected() {
    return this.platformTarget.value === 'all' || this.platformTarget.value == null;
  }

  doTurboRequest(url) {
    get(url, {
      responseKind: 'turbo-stream'
    });
  }

  platformSelected() {
    console.log('Platform selected: ', this.platformTarget.value);

    if (this.overviewRemoved && this.noPlatformSelected) {
      this.chartsTarget.parentElement.insertBefore(this.overviewSnippet, this.chartsTarget);
      this.overviewRemoved = false;
      this.doTurboRequest('/flow/dashboard?platform=all');
      return;
    }

    if (!this.overviewRemoved && !this.noPlatformSelected) {
      this.overviewSnippet.remove();
      this.overviewRemoved = true;
    }

    this.doTurboRequest(`/flow/dashboard?platform=${this.platformTarget.value}`);
  }
}
