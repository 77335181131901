import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import {post} from '@rails/request.js';

// Custom MediaRecorder that support saving as wav files, which Azure requires
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

let initializedMediaRecorder = false;
async function initializeMediaRecorder() {
  if(initializedMediaRecorder) return;
  // https://github.com/chrisguttandin/extendable-media-recorder?tab=readme-ov-file#using-a-custom-encoder
  await register(await connect());
  initializedMediaRecorder = true;
}

// Connects to data-controller="ai-audio-recorder"
export default class extends Controller {
  static targets = [ 'playback', 'record', 'stop' ];

  static values = {
    directUploadPath: String,
    voiceId: String,
    // existingFileName: String // if a file was already uploaded before this loaded
  };

  recorder = null;
  chunks = null;
  state = ''; // '' / 'recording' / 'saving' / 'uploading' / 'finalizing' / 'uploaded'
  audioEl = null;

  connect() {
    initializeMediaRecorder();
    // console.log('Audio Recorder connected', this.voiceIdValue);
  }

  async record(){
    if(!initializedMediaRecorder) return;
    if (!this.isIdle()){
      console.log('Cannot record while not idle');
      return;
    }
    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.recorder = new MediaRecorder(stream, {mimeType: 'audio/wav'}); // audioBitsPerSecond: 16000
    this.recorder.ondataavailable = (event) => {
      this.chunks.push(event.data);
    };
    this.recorder.onstop = () => {
      const blob = new Blob(this.chunks, { type: 'audio/wav' });
      this.file = new File([blob], 'recording.wav', {
        type: blob.type,
      });
      // const url = URL.createObjectURL(blob);
      // const audio = new Audio(url);
      // audio.controls = true;
      // this.playbackTarget.innerHTML = '';
      // this.playbackTarget.appendChild(audio);
      // this.audioEl = audio;
      this.recorder = null;
      this.startDirectUpload();
    };

    this.chunks = [];
    this.recorder.start();
    this.state = 'recording';
    this.refreshUI();
    // console.log('Recording started');
  }
  stop(){
    if (this.recorder && this.isRecording()){
      this.state = 'saving';
      this.recorder.stop();
      // console.log('Recording stopped');
    }
  }
  isIdle(){
    return !this.state || this.state === 'uploaded';
  }
  isRecording(){
    return this.state === 'recording';
  }
  isUploading(){
    return this.state === 'uploading';
  }
  // hasRecorded(){
  //   return this.state === 'recorded' && this.audioEl;
  // }
  refreshUI(){
    if (this.isRecording()) {
      this.recordTarget.classList.add('d-none');
      this.stopTarget.classList.remove('d-none');
      this.playbackTarget.innerHTML = '';
    }else if(this.isUploading()){
      this.recordTarget.classList.add('d-none');
      this.stopTarget.classList.add('d-none');
      this.playbackTarget.innerHTML = 'Uploading...';

    }else if(this.isIdle()){
      this.recordTarget.classList.remove('d-none');
      this.stopTarget.classList.add('d-none');
    }
  }
  startDirectUpload(){
    this.directUpload = new DirectUpload(this.file, this.directUploadPathValue, {
      directUploadWillStoreFileWithXHR: this.directUploadWillStoreFileWithXHR.bind(this),
    });
    this.directUpload.create((error, attributes) => {
      if (error) {
        throw new Error(error);
      } else {
        // attributes.signed_id;
        this.onUploadComplete(attributes);
      }
    });
    console.log('DirectUpload', this.directUpload);
    this.state = 'uploading';
    this.refreshUI();
  }

  directUploadWillStoreFileWithXHR(request) {
    // console.log('directUploadWillStoreFileWithXHR', request);
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/upload
    request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    const progress = Math.floor((event.loaded / event.total) * 100);
    let text = `Uploading ${progress}%`;
    // console.log('Uploading', progress, event);
    this.playbackTarget.innerText = text;
  }

  async onUploadComplete(attributes){
    this.state = 'finalizing';

    // console.log('onUploadComplete', this.voiceIdValue, attributes);
    await post('/ai/audio/uploads/voice_training/attach_verification_audio', {
      body: {
        upload: {
          signed_id: attributes.signed_id,
          voice_id: this.voiceIdValue
        }
      },
      responseKind: 'turbo-stream'
    });
    this.state = 'uploaded';
    this.refreshUI();
  }
}
