import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger'];

  get isOpen() {
    return document.querySelector('.hs-shadow-container').className.includes('active');
  }

  toggleHubspotChat() {
    if (window.HubSpotConversations) {
      if (this.isOpen) {
        window.HubSpotConversations.widget.close();
      } else {
        window.HubSpotConversations.widget.open();
      }
    }
  }
}
