import { Controller } from '@hotwired/stimulus';
import * as ProgressBar from 'progressbar.js';

// Connects to data-controller="progress-bar"
// this controller needs to be decalred on a form element for the turbo events to be listened for
export default class extends Controller {
  static targets = ['percent', 'svg'];
  static values = {
    percentage: Number,
    shape: String,
    height: String,
    strokeWidth: Number,
    trailColor: String,
    trailWidth: Number
  };

  SHAPE_TO_CLASS = {
    line: ProgressBar.Line,
    circle: ProgressBar.Circle,
    semiCircle: ProgressBar.SemiCircle
  };

  progressBar = null;

  connect() {
    if (this.shapeValue !== 'custom') {
      this.progressBar = new this.SHAPE_TO_CLASS[this.shapeValue](this.element, {
        strokeWidth: this.strokeWidthValue,
        color: '#FFEA82',
        trailColor: this.trailColorValue,
        trailWidth: this.trailWidthValue,
        svgStyle: { width: '100%', height: this.heightValue },
        step: (state, bar) => {
          bar.path.setAttribute('stroke', this.getColor(100 - state.offset));
        }
      });
    } else {
      if (this.hasSvgTarget) {
        // TODO: Implement custom shape for animation and pass in gradient direction (vertical vs horizontal)
        let svgPath = this.svgTarget.querySelector('path');
        let originalFill = svgPath.getAttribute('fill');
        let id = `gradient-${this.percentageValue}`;
        let defs = this.svgTarget.querySelector('defs');
        let color = this.getColor(this.percentageValue);
        let linearGradient = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'linearGradient'
        );
        linearGradient.setAttribute('id', id);
        linearGradient.setAttribute('x1', '0%');
        linearGradient.setAttribute('y1', '0%');
        linearGradient.setAttribute('x2', '0%');
        linearGradient.setAttribute('y2', '100%');
        let stop0 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
        stop0.setAttribute('offset', '0%');
        stop0.setAttribute('stop-color', originalFill);
        let stop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
        stop1.setAttribute('offset', `${this.percentageValue}%`);
        stop1.setAttribute('stop-color', originalFill);
        let stop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
        stop2.setAttribute('offset', `${this.percentageValue}%`);
        stop2.setAttribute('stop-color', color);
        let stop3 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
        stop3.setAttribute('offset', '100%');
        stop3.setAttribute('stop-color', color);
        linearGradient.appendChild(stop1);
        linearGradient.appendChild(stop2);
        linearGradient.appendChild(stop3);
        defs.appendChild(linearGradient);
        svgPath.setAttribute('fill', `url(#${id})`);
      }
    }

    if (this.progressBar) {
      this.progressBar.animate(this.percentageValue / 100);
    }
  }

  getColor(percentage) {
    // Ensure percentage is within the valid range
    percentage = Math.max(0, Math.min(100, percentage));

    // Define RGB values for red, yellow, and green
    const redColor = { r: 189, g: 8, b: 28 };
    const yellowColor = { r: 252, g: 213, b: 113 };
    const greenColor = { r: 52, g: 130, b: 103 };

    let r, g, b;

    if (percentage <= 50) {
      // Interpolate between red and yellow
      r = Math.round(redColor.r + (yellowColor.r - redColor.r) * (percentage / 50));
      g = Math.round(redColor.g + (yellowColor.g - redColor.g) * (percentage / 50));
      b = Math.round(redColor.b + (yellowColor.b - redColor.b) * (percentage / 50));
    } else {
      // Interpolate between yellow and green
      r = Math.round(yellowColor.r + (greenColor.r - yellowColor.r) * ((percentage - 50) / 50));
      g = Math.round(yellowColor.g + (greenColor.g - yellowColor.g) * ((percentage - 50) / 50));
      b = Math.round(yellowColor.b + (greenColor.b - yellowColor.b) * ((percentage - 50) / 50));
    }

    // Return the color as a CSS-compatible string
    return `rgb(${r}, ${g}, ${b})`;
  }
}
