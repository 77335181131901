import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="ai-step-context"
export default class extends Controller {
  static targets = ['promptInput', 'contextHiddenInput'];

  initialize() {
    localStorage.removeItem('custom_prompts');
  }

  contextHiddenInputTargetConnected(element) {
    //  if we already have it in localStorage - reuse the persisted answers from previous steps
    // you can check the stepNumber > 1 from the promptInputTarget.dataset.stepNumber
    // let stepNumber = Number(this.promptInputTarget.dataset.stepNumber);
    // if (element.value && stepNumber >= 1) {
    if (element.value) {
      let json = JSON.parse(element.value);
      this.setLocalStorageItem(json);
    } else {
      this.setContextInputValue();
    }
  }

  setLocalStorageItem(json) {
    localStorage.setItem('createContext', JSON.stringify(json));
  }

  onPromptInputChange(event) {
    let value = event.currentTarget.value;
    let step = Number(event.currentTarget.dataset.stepNumber);

    let context = this.createContext();
    let index = context.findIndex(({ step_number }) => Number(step_number) === Number(step));
    let question = context.find(({ step_number }) => Number(step_number) === Number(step));

    if (question) {
      question.answer = value;
      context[index] = question;

      this.setLocalStorageItem(context);
      this.setContextInputValue();
    }
  }

  setContextInputValue() {
    this.contextHiddenInputTarget.value = JSON.stringify(this.createContext());
  }

  createContext() {
    return JSON.parse(localStorage.getItem('createContext'));
  }
}
