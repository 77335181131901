import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const observer = new MutationObserver((mutationList) => this.domChangeCallback(mutationList));
    observer.observe(document.querySelector('.code-dom-observer'), { childList: true, subtree: true });
  }

  domChangeCallback(mutationList) {
    for(const mutation of mutationList) {
      if (mutation.type === 'childList') {
        this.showBtn('.code-fetching-complete', '#fetching_next_btn');
        this.showBtn('.code-fetching-error', '#fetching_back_to_connections_btn');
      }
    }
  }

  showBtn(selectorResult, selectorToShow) {
    if (document.querySelector(selectorResult)) {
      document.querySelector(selectorToShow).style.display = 'block';
    }
  }
}
