import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element);
    let modalBackdrops = document.querySelectorAll('.modal-backdrop');
    if (modalBackdrops) {
      modalBackdrops.forEach((drop) => drop.remove());
    }
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  // Ignore these for now, we still have an issue with a "flash" between modals.
  // To remove this, we could opt to just swap the body, not the entire modal

  // hideBeforeRender(event) {
  //   console.log('hideBeforeRender');
  //   if (this.isOpen()) {
  //     event.preventDefault();
  //     this.element.addEventListener('hidden.bs.modal', event.detail.resume);
  //     this.modal.hide();
  //   }
  // }

  // isOpen() {
  //   console.log('isOpen');
  //   return this.element.classList.contains('show');
  // }
}
