import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="pitch-decline-reason"
export default class extends Controller {
  static targets = ['declineMessage'];

  connect() {
    this.enableSubmitButton();
  }

  declineReasonChanged(event) {
    const declineMessage = event.target.dataset.declineMessage;
    if (declineMessage === 'custom') {
      this.declineMessageTarget.value = '';
    } else {
      this.declineMessageTarget.value = event.target.dataset.declineMessage;
    }

    // Trigger the auto-grow-textarea controller to resize the textarea
    const autoGrowTextarea = this.application.getControllerForElementAndIdentifier(
      this.declineMessageTarget.parentElement,
      'auto-grow-textarea'
    );

    autoGrowTextarea.resize();
    this.enableSubmitButton();
  }

  declineMessageChanged() {
    if (this.declineMessageTarget.value === '') {
      this.disableSubmitButton();
    } else {
      this.enableSubmitButton();
    }
  }

  enableSubmitButton() {
    if (this.declineMessageTarget.value === '') {
      this.disableSubmitButton();
      return;
    }
    this.submitButton.disabled = false;
  }

  disableSubmitButton() {
    this.submitButton.disabled = true;
  }

  get submitButton() {
    return document.getElementById('pitch-decline-submit');
  }
}
