import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';

// Connects to data-controller="change-requests-turbo"
export default class extends Controller {
  static values = {
    onChangeSubmitUrl: String
  };
  static targets = ['checkBox'];

  onChange() {
    let viewed = this.checkBoxTarget.querySelector('input').checked;

    put(this.onChangeSubmitUrlValue, {
      query: { viewed },
      responseKind: 'turbo-stream'
    });
  }
}
