import { Controller } from '@hotwired/stimulus';
import Highcharts from 'highcharts';

// Alternatively, this is how to load Highcharts Stock. The Maps and Gantt
// packages are similar.
// import Highcharts from 'highcharts/highstock';

// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
// Initialize exporting module.
Exporting(Highcharts);

export default class extends Controller {
  static values = {
    param: Object,
    useStackNameFormat: false
  };

  options = null;

  connect() {
    if (this.useStackNameFormatValue) {
      this.options = Object.assign(this.paramValue, {
        tooltip: {
          formatter: function () {
            let stackName = this.point.series.stackKey.split(',')[1];
            // important no arrow function so this scope remians for highchart objects
            return `${stackName}<br/>${this.series.name}: ${this.point.y}<br/>Total: ${this.point.stackTotal}`;
          }
        }
      });
    } else {
      this.options = this.paramValue;
    }
    Highcharts.chart(this.element, this.options);
  }
}
