import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="swiper-initializer"
export default class extends Controller {
  static values = { swiperOptions: Object };

  swiper = null;

  connect() {
    // eslint-disable-next-line no-undef
    this.swiper = new Swiper(this.element, this.swiperOptionsValue);
  }
}
