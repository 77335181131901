import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'section',
    'category',
    'dependentCategory',
    'name',
    'customOptions',
    'submitButton'
  ];

  save() {
    let payload = event.params.payload;
    if (this.nameTarget.value === payload.old_value) {
      return;
    }

    this.submitButtonTarget.click();
  }

  updateBody() {
    let emptyHash = {};
    let aiImagePrompt = (emptyHash['ai_image_prompt'] = {});
    aiImagePrompt['section'] = this.sectionTarget.value;
    aiImagePrompt['category'] = this.categoryTarget.value;
    aiImagePrompt['dependent_category'] = this.dependentCategoryTarget.value;
    aiImagePrompt['name'] = this.nameTarget.value;

    return JSON.stringify(aiImagePrompt);
  }
}
