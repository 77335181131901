import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="creator-invites"
export default class extends Controller {
  static values = {
    selectCcPath: String,
    selectCategoryPath: String
  };

  // TODO: add targets for other fields
  static targets = [
    'castingCallSelect',
    'brandName',
    'name',
    'email',
    'phoneNumber',
    'connections'
  ];

  get castingCallId() {
    if (this.hasCastingCallSelectTarget) {
      return this.castingCallSelectTarget.value;
    }
    return null;
  }

  get brandName() {
    if (this.hasBrandNameTarget) {
      return this.brandNameTarget.value;
    }
    return null;
  }

  get name() {
    if (this.hasNameTarget) {
      return this.nameTarget.value;
    }
    return null;
  }

  get email() {
    if (this.hasEmailTarget) {
      return this.emailTarget.value;
    }
    return null;
  }

  get phoneNumber() {
    if (this.hasPhoneNumberTarget) {
      return this.phoneNumberTarget.value;
    }
    return null;
  }

  get connections() {
    if (this.hasConnectionsTarget) {
      let picked = Array.from(this.connectionsTarget.querySelectorAll('input[type="checkbox"]'));
      return picked.filter((elm) => elm.checked).map((elm) => elm.value);
    }
    return [];
  }

  selectedCastingCall() {
    if (this.hasCastingCallSelectTarget && this.hasSelectCcPathValue) {
      post(this.selectCcPathValue, {
        body: {
          user_invite_creator: {
            opportunity_id: this.castingCallId,
            name: this.name,
            email: this.email,
            phone_number: this.phoneNumber,
            platforms: this.connections
          }
        },
        responseKind: 'turbo-stream'
      });
    }
  }

  selectedCategory(event) {
    let category = event.currentTarget.dataset.value;
    if (this.hasSelectCategoryPathValue) {
      post(this.selectCategoryPathValue, { body: { category }, responseKind: 'turbo-stream' });
    }
  }
}
