import { Controller } from '@hotwired/stimulus';
import { emit, subscribe, unsubscribe } from '../helpers/eventbus';

export default class extends Controller {
  static targets = ['player', 'playButton', 'stopButton'];

  connect() {
    this.stopSubscription = subscribe('stopAllAudioPlayers', this.stop.bind(this));
  }
  disconnect() {
    unsubscribe('stopAllAudioPlayers', this.stopSubscription);
  }

  play() {
    emit('stopAllAudioPlayers');
    if (this.hasPlayerTarget) {
      this.playerTarget.play();
      if (this.hasPlayButtonTarget && this.hasStopButtonTarget) {
        this.playButtonTarget?.classList?.add('d-none');
        this.stopButtonTarget?.classList?.remove('d-none');
        this.playerTarget.addEventListener('ended', () => {
          this.playButtonTarget?.classList?.remove('d-none');
          this.stopButtonTarget?.classList?.add('d-none');
        });
      }
    }
  }

  stop() {
    if (this.hasPlayerTarget) {
      this.playerTarget.pause();
      this.playerTarget.currentTime = 0;
      this.playButtonTarget?.classList?.remove('d-none');
      this.stopButtonTarget?.classList?.add('d-none');
    }
  }

  stopAllPlayingAudio() {
    document.querySelectorAll('audio').forEach((audio) => {
      audio.pause();
      audio.currentTime = 0;
      audio.onended = () => {
        this.playButtonTarget?.classList?.remove('d-none');
        this.stopButtonTarget?.classList?.add('d-none');
      };
    });
    document.querySelectorAll('#custom_player_play_button').forEach((button) => {
      button.classList?.remove('d-none');
    });
    document.querySelectorAll('#custom_player_pause_button').forEach((button) => {
      button.classList?.add('d-none');
    });

    document.querySelectorAll('[id^="waveform-"]').forEach((wf) => {
      wf?.querySelector('div')?.remove();
    });

    document.querySelectorAll('[id^="waveform-play-btn-"]').forEach((wfb) => {
      wfb?.classList?.remove('d-none');
    });

    document.querySelectorAll('[id^="waveform-stop-btn-"]').forEach((wfb) => {
      wfb?.classList?.add('d-none');
    });
  }
}
