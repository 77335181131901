import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="ai-image-variant"
export default class extends Controller {
  failedToLoad = false;

  connect() {
    this.element.onload = () => {
      this.onLoaded();
    };
    this.element.onerror = () => {
      this.onMissing();
    };

    let urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.get('creating')) {
      this.element.src = this.element.dataset['srcFallback'] || '';
      return;
    }

    this.element.srcset = this.element.dataset.srcset || '';
    this.element.src = this.element.dataset.src || '';
  }

  onLoaded() {
    // This comes from the Rails helper; we only want to remove it when an image is loaded, so the default behavior of showing the alt text when the first image failed to load is worked around
    this.element.classList.remove('d-none');
  }

  onMissing() {
    if (this.failedToLoad) {
      return;
    }
    this.element.srcset = '';
    this.element.src = this.element.dataset['srcFallback'] || '';

    this.failedToLoad = true;

    post('/ai/schedule_ai_image_variant_job', {
      query: { key: this.element.dataset.key }
    });
  }
}
