import { Controller } from '@hotwired/stimulus';

let stripe = null;

function initStripe(publishableKey) {
  if (!stripe) {
    stripe = Stripe(publishableKey);
  }
  return stripe;
}

// Connects to data-controller="stripe-payment-method"
export default class extends Controller {
  static values = {
    publishableKey: String,
    customerSessionClientSecret: String,
    setupIntentClientSecret: String
  };

  static targets = ['addPaymentMethodButton'];

  connect() {
    initStripe(this.publishableKeyValue);

    // https://docs.stripe.com/js/elements_object/create_without_intent
    // https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#save-payment-methods
    // https://docs.stripe.com/js/setup_intents/confirm_card_setup
    const options = {
      customerSessionClientSecret: this.customerSessionClientSecretValue,
      mode: 'setup',
      paymentMethodTypes: ['card'],
      // amount: 1099,
      currency: 'usd',
      paymentMethodCreation: 'manual',
      setupFutureUsage: 'off_session',
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      }
    };
    // console.log('Stripe Payment Method Controller connected', stripe, options);

    // Set up Stripe.js and Elements to use in checkout form
    const elements = stripe.elements(options);
    this.elements = elements;

    // Create and mount the Payment Element
    // const paymentElement = elements.create('payment', { layout: 'accordion' });
    const paymentElement = elements.create('card', { disableLink: true }); // layout: 'accordion'
    this.paymentElement = paymentElement;
    paymentElement.mount('#payment-element');
  }

  async addPaymentMethod() {
    this.addPaymentMethodButtonTarget.disabled = true;
    const submitResponse = await this.elements.submit();
    if (submitResponse.error) {
      console.log('Error submitting payment form', submitResponse.error);
      this.addPaymentMethodButtonTarget.disabled = false;
      return;
    }
    // console.log('Payment form submitted', submitResponse);

    // const { error, confirmationToken } = await stripe.createConfirmationToken({
    //   elements: this.elements,
    //   params: {
    //     // shipping: {
    //     //   name: 'Jenny Rosen',
    //     //   address: {
    //     //     line1: '1234 Main Street',
    //     //     city: 'San Francisco',
    //     //     state: 'CA',
    //     //     country: 'US',
    //     //     postal_code: '94111',
    //     //   },
    //     // },
    //     return_url: window.location.href
    //   }
    // });
    // console.log('Stripe createConfirmationToken', error, confirmationToken);

    // For "payment" element
    // let confirmCardSetupResponse = await stripe.confirmSetup({
    //   clientSecret: this.setupIntentClientSecretValue,
    //   elements: this.elements,
    //   confirmParams: {
    //     return_url: window.location.href
    //   }
    // });

    // For 'card' element
    let confirmCardSetupResponse = await stripe.confirmCardSetup(
      this.setupIntentClientSecretValue,
      {
        payment_method: {
          card: this.paymentElement // Needs to be element type 'card'
        }
      }
    );
    // console.log('Stripe confirmCardSetup', confirmCardSetupResponse);
    if (confirmCardSetupResponse && !confirmCardSetupResponse.error) {
      window.location.reload();
    }else{
      this.addPaymentMethodButtonTarget.disabled = false;
      console.log('Error submitting payment form confirmSetup', confirmCardSetupResponse.error);
    }
  }
}
