import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="single-range-slider"
export default class extends Controller {
  static targets = ['slider', 'input'];
  static values = {
    min: Number,
    max: Number,
    stepInterval: Number
  };

  controlSlider() {
    this.inputTarget.value = this.sliderTarget.value;
    this.inputTarget.classList.remove('is-invalid');
  }

  controlInput() {
    let inputValue = this.getParsed(this.inputTarget.value);

    // prevent setting invalid range
    if (inputValue < this.minValue || inputValue > this.maxValue) {
      // Would be nice to set something visual to make it clear that the values are invalid here
      this.inputTarget.classList.add('is-invalid');
      return;
    } else {
      this.inputTarget.classList.remove('is-invalid');
    }

    this.sliderTarget.value = inputValue;
  }

  getParsed(currentRange) {
    let rangeValue = parseInt(currentRange);
    if (isNaN(rangeValue)) {
      return 0;
    }

    return rangeValue;
  }
}
