import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="flow-status"
export default class extends Controller {
  static values = { channel: String, provider: String };
  connect() {
    
  }

  doTurboRequest(url) {
    get(url, {
      responseKind: 'turbo-stream'
    });
  }
  
  loadFields(event) {
    const values = JSON.parse(event.delegateTarget.dataset.flowStatus);
    const reqParams = new URLSearchParams({ channel: values['channel-value'], provider: values['provider-value'] });
    this.doTurboRequest( `/flow/status?${reqParams.toString()}` );
  }
}