// Import all the channels to be used by Action Cable
import { start } from '@anycable/turbo-stream';
import cable from '../cable';
import { default as AppearanceChannel } from './appearance_channel';
let isLoggedIn = document.getElementById('appearance_channel');

// Don't bother starting a Websocket connection unless user is logged in (it'd just get rejected anyway)
if (isLoggedIn) {
  AppearanceChannel();
  start(cable);
}
