import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'signup', 'login'];
  connect() {
    this.signup = this.signupTarget;
    this.login = this.loginTarget;
    if (!document.documentElement.hasAttribute('data-turbo-preview')) {
      document.getElementsByClassName('not-show-modal')[0].style.display = 'none';
      setTimeout(() => {
        this.triggerTarget.click();
      }, 200);
    }
  }

  toggleModal() {
    if (this.signup.style.display === 'none') {
      this.signup.style.display = 'block';
      this.login.style.display = 'none';
    } else {
      this.signup.style.display = 'none';
      this.login.style.display = 'block';
    }
  }
}
