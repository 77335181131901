import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spokenLanguage'];
  connect() {
    this.maxCheck();
  }

  maxCheck() {
    let checkedLength = 0;
    let unchecked = [];
    this.spokenLanguageTargets.forEach(element => {
      const { checked } = element;
      if (checked) {
        checkedLength += 1;
      } else {
        unchecked.push(element);
      }
    });
    if (checkedLength >= 5) {
      unchecked.forEach(element => {
        element.setAttribute('disabled', true);
      });
    } else {
      unchecked.forEach(element => {
        element.removeAttribute('disabled');
      });
    }
  }
}
