import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="change-request-fields"
export default class extends Controller {
  static targets = ['fields', 'trigger'];

  toggleFields() {
    this.fieldsTarget.classList.toggle('invisible');
  }
}
