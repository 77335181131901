import { get, post } from '@rails/request.js';

import pageState from '~/services/pageState.svelte';

// A way to use the Rails versions of ~fetch easily, with added support for putting pageState into the pageState service from JSON responses
// Loosely based on some Svelte-Query conventions (but with $state instead of classical stores), so we could add support for that in the future
export class FetchQuery{
  data = $state(null); // Fetched data
  error = $state(null); // Set if there was an error
  status = $state('idle');
  isIdle = $derived(this.status === 'idle');
  isLoading = $derived(this.status === 'loading');
  // isFetching = $derived(this.status === 'loading' || this.status === 'refretching');
  isError = $derived(this.status === 'error');
  isSuccess = $derived(this.status === 'success');
  options = $state({
    queryFn: null,
    enabled: true
  });
  constructor(options){
    Object.assign(this.options, options);
    if(!this.options.queryFn){
      throw new Error('queryFn option is required');
    }
    if(this.options.enabled){
      this.resolve();
    }
  }

  // Consumer Warning: awaiting this promise from a Component has no built in guard rails to not then trigger after the component was destroyed; Instead, use $effect(() => { if(myQuery.isSuccess)...
  async resolve(){
    this.status = 'loading';
    try{
      this.data = await this.options.queryFn();
      this.status = 'success';

    }catch (e){
      console.error(e);
      this.error = e;
      this.status = 'error';
    }
  }
  refetch(){
    this.resolve();
  }
}

// Universal way to update pageState from any Rails JSON response that goes through this code
// Just return {data: {...}, page_state: {current_user: {...}}} from the endpoint to update page-wide pageState from any response
function interceptDataResponse(data){
  let addPageState = data?.page_state;
  if(addPageState){
    pageState.addState(addPageState);
  }
  return data?.data;
}

export function railsGet(url, getOptions, queryOptions){
  let queryFn = async function(){
    let response = await get(url, getOptions);
    return interceptDataResponse(await response.json);
  };
  return new FetchQuery(Object.assign({queryFn},queryOptions));
}

export function railsPost(url, postOptions, queryOptions){
  let queryFn = async function(){
    let response = await post(url, postOptions);
    return interceptDataResponse(await response.json);
  };
  return new FetchQuery(Object.assign({queryFn},queryOptions));
}
