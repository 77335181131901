import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    hiddenInputTargeting: String
  };

  connect() {
    if (this.element) {
      if (this.element.dataset.buttonText) {
        this.setButtonInnerText(this.element.dataset.buttonText);
      } else if (this.element.dataset.buttonHtml) {
        this.setButtonInnerHTML(this.element.dataset.buttonHtml);
      }
    }
  }

  dropDownItemSelectedForHiddenInput(event) {
    let value = event.currentTarget.dataset.value;
    let buttonText = event.currentTarget.dataset.buttonText;
    let buttonHtml = event.currentTarget.dataset.buttonHtml;
    let isCustomPrompt = event.currentTarget.dataset?.savedCustomPrompt;
    if (this.hasHiddenInputTargetingValue) {
      let dropdownParent = event.currentTarget.closest('.dropdown-parent');
      let hiddenInput = dropdownParent.querySelector(`#${this.hiddenInputTargetingValue}`);
      console.log('value');
      hiddenInput.value = value;
    }
    if (buttonHtml) {
      this.setButtonInnerHTML(buttonHtml);
    } else if (buttonText) {
      this.setButtonInnerText(buttonText);
    }

    if (isCustomPrompt == 'true') localStorage.setItem('custom_prompts', true);
  }

  setButtonInnerText(value) {
    let button = this.element.querySelector('button[data-bs-toggle="dropdown"]');
    button.innerText = value;
  }

  setButtonInnerHTML(html) {
    let button = this.element.querySelector('button[data-bs-toggle="dropdown"]');
    button.innerHTML = html;
  }
}
