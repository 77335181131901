import { Controller } from '@hotwired/stimulus';
import easymde from 'easymde';
import { marked } from 'marked';

// Connects to data-controller="composer"
export default class extends Controller {
  static targets = ['previewSection', 'markdownEditor'];

  connect() {
    this._easyMDE = new easymde({
      element: this.markdownEditorTarget,
      autofocus: true,
      placeholder: 'Type here...',
      toolbarTips: true,
      hideIcons: ['preview', 'fullscreen', 'side-by-side']
    });
    this.previewSectionTarget.innerHTML = marked.parse(this._easyMDE.value());

    this._easyMDE.codemirror.on('change', () => {
      this.previewSectionTarget.innerHTML = marked.parse(this._easyMDE.value());
    });
  }

  disconnect() {
    this._easyMDE.cleanup();
  }
}
