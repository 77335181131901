const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validEmail(email) {
  return emailRegex.test(email);
}

export function validNewPassword(statusAttribute) {
  return Number(statusAttribute) === 1;
}

export function validPasswordLength(password) {
  return password && password.length >= 10;
}

export function validInputValue(value) {
  return value && value.length > 1;
}
