/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { findElement } from '../helpers';

// Connects to data-controller="datatable-columns"
// must include this in a view that uses datatable
//   <%= content_for :scripts do %>
//   <script src="https://code.jquery.com/jquery-3.6.0.slim.min.js" integrity="sha256-u7e5khyithlIdTpu22PHhENmPcRdFiHRjhAuHcs05RI=" crossorigin="anonymous"></script>
//   <%= javascript_include_tag "vendor/dashboard-bundle.js" %>
// <% end %>

export default class extends Controller {
  static targets = ['datatable'];
  static values = {
    tableId: String,
    customizable: Boolean
  };

  table = null;
  columns = null;

  connect() {
    if (this.hasDatatableTarget) {
      this.setTable();
      this.initColumns();
      this.table.colReorder.disable(); // disable user interaction with table column headers (dragging/dropping)
      this.datatableTarget.classList.remove('table-hidden');

      if (this.customizableValue) {
        this.setSortable();
        this.setSortableListOrder();
      }

      this.element.addEventListener('shown.bs.offcanvas', () => {
        let backdrop = this.element.querySelector('.offcanvas-backdrop');
        if (!backdrop.onclick) {
          backdrop.onclick = () => this.resetOnDismiss();
        }
      });
    }
  }

  disconnect() {
    // KEEPING THIS STUFF HERE IF WE DECIDE TO REENABLE TURBO CACHING FOR VIEWS WITH DATATABLES WILL NEED TO FIGURE OUT
    // HOW TO MANAGE table reseting - currently with caching enabled - an error from the datatables.js library occurs
    this.table.clear();
    this.table.destroy();
    HSCore.components.HSDatatables.destroy(this.tableIdValue);
    HSCore.components.HSSortable.destroy(this.tableIdValue);
    this.table = null;
  }

  initColumns() {
    this.columns = [];
    let colOrder = this.table.state().ColReorder;
    let columns = this.table.state().columns;
    colOrder.forEach((originalColumnIdx, currentPosition) => {
      this.columns.push({
        originalColumnIdx,
        currentPosition,
        visible: columns[originalColumnIdx].visible
      });
    });

    this.updateColumns();
  }

  setTable() {
    HSCore.components.HSDatatables.init(this.datatableTarget, {
      colReorder: true
    });
    this.table = HSCore.components.HSDatatables.getItem(this.tableIdValue);
  }

  setSortable() {
    HSCore.components.HSSortable.init(`.js-sortable#${this.tableIdValue}`);
  }

  setSortableListOrder() {
    if (!this.customizableValue) {
      return;
    }
    let sortableList = findElement(`.js-sortable#${this.tableIdValue}`);
    let colOrder = this.table.state().ColReorder;
    let columns = this.table.state().columns;
    let elements = Array(sortableList.children.length);
    let sortingIndexes = [...sortableList.children].map((elem) => Number(elem.dataset.columnIndex));
    colOrder
      .filter((idx) => sortingIndexes.includes(idx))
      .forEach((originalColumnIdx, currentPosition) => {
        let element = [...sortableList.children].find(
          (elem) => Number(elem.dataset.columnIndex) === originalColumnIdx
        );

        element.querySelector('input[type="checkbox"]').checked =
          columns[originalColumnIdx].visible;
        elements[currentPosition] = element;
      });

    sortableList.replaceChildren(...elements);
  }

  updateColumns() {
    let order = Array(this.columns.length);
    this.columns.forEach((colConfig) => {
      order[colConfig.currentPosition] = colConfig.originalColumnIdx;
    });

    // use api to reorder - because of bug on frame load
    // where the table settings is nullified for some unknown reason 🤷‍♂️
    let tableApi = $(this.datatableTarget).dataTable().api();
    //order
    tableApi.colReorder.order(order, true);
    // then hide
    this.columns.forEach((colConfig) => {
      this.table.column(colConfig.currentPosition, { order: 'applied' }).visible(colConfig.visible);
    });
  }

  droppedLi(event) {
    let droppedLi = event.currentTarget;
    let sortableList = findElement(`.js-sortable#${this.tableIdValue}`);
    let children = [...sortableList.children];
    let notSortableIndexes = children
      .filter((elm) => elm.querySelector('.not-sortable'))
      .map((elm) => Number(elm.dataset.listIndex));

    if (notSortableIndexes.includes(children.findIndex((elem) => elem == droppedLi))) {
      this.resetOnDismiss();
      return;
    }

    children.forEach((element, index) => {
      let columnIdx = element.getAttribute('data-column-index');
      let columnConf = this.columns.find(
        ({ originalColumnIdx }) => originalColumnIdx.toString() === columnIdx
      );
      // add missing column from list config difference (if actions was removed by backend the indexes of the children array need to be adjusted)
      columnConf.currentPosition = index + (this.columns.length - children.length);
    });
  }

  setVisibility(event) {
    let input = event.target;
    let li = input.closest('li');
    let conf = this.columns.find(
      (conf) => conf.originalColumnIdx === Number(li.getAttribute('data-column-index'))
    );
    conf.visible = input.checked;
  }

  resetOnDismiss() {
    this.initColumns();
    this.setSortableListOrder();
  }

  reset() {
    this.columns.forEach((conf) => {
      conf.currentPosition = conf.originalColumnIdx;
      conf.visible = true;
    });
    this.updateColumns();
    this.setSortableListOrder();
  }

  save() {
    this.updateColumns();
  }
}
