import { Controller } from '@hotwired/stimulus';

// TODO this controller should be renamed since it has very specific logic for user location
export default class extends Controller {
  static targets = [
    'adminAction',
    'refundOptions',
    'refundAmount',
    'partialRefundElement',
    'dismissMessage'
  ];
  refundType = '';
  refundAmount = 0;
  selectedChargeId = '';
  selectedChargeAmount = 0;

  connect() {
    this.hideRefundOptions();
    this.disableSubmitButton();
    this.hideDismissMessage();
  }

  setAdminAction(event) {
    Array.from(document.querySelectorAll('input[name="admin-cancel-order"]')).forEach((element) => {
      element.checked = false;
    });
    this.adminActionTarget.value = event.target.value;
    event.target.checked = true;

    if (this.adminActionTarget.value === 'dismiss_cancelation_request') {
      this.showDismissMessage();
      this.hideRefundOptions();
    } else if (this.adminActionTarget.value === 'refund_only') {
      this.showRefundOptions();
      this.hideDismissMessage();
    } else {
      this.hideRefundOptions();
      this.hideDismissMessage();
    }
    this.enableSubmitButton();
  }

  showRefundOptions() {
    this.refundOptionsTarget.hidden = false;
  }
  hideRefundOptions() {
    this.refundOptionsTarget.hidden = true;
    this.partialRefundElementTarget.hidden = true;
  }

  refundTypeChanged(event) {
    if (event.target.value === 'partial') {
      this.showPartialRefundOptions();
      this.refundType = 'partial';
    } else {
      this.hidePartialRefundOptions();
      this.refundType = 'full';
    }

    this.enableSubmitButton();
  }

  showPartialRefundOptions() {
    this.partialRefundElementTarget.hidden = false;
  }

  hidePartialRefundOptions() {
    this.partialRefundElementTarget.hidden = true;
  }

  disableSubmitButton() {
    document.getElementById('attachment-submit-button').disabled = true;
  }

  refundAmountChange() {
    this.selectedChargeId = document.getElementById('refund_charge_id').value;
    this.selectedChargeAmount = parseInt(
      document.getElementById(`charge_amount_${this.selectedChargeId}`).value
    );

    this.refundAmount = parseInt(
      this.refundAmountTarget.value.replace(/\$/g, '').replace(/,/g, '')
    );

    if (isNaN(this.refundAmount) || this.refundAmount > this.selectedChargeAmount) {
      this.disableSubmitButton();
    } else {
      this.enableSubmitButton();
    }
  }

  enableSubmitButton() {
    if (!this.adminActionTarget.value) {
      return;
    }
    if (
      this.refundType === 'partial' &&
      (!this.selectedChargeId ||
        this.refundAmount <= 0 ||
        this.refundAmount > this.selectedChargeAmount)
    ) {
      return this.disableSubmitButton();
    }

    document.getElementById('attachment-submit-button').disabled = false;
  }

  chargeSelected() {
    this.refundAmountTarget.disabled = false;
  }

  showDismissMessage() {
    if (this.hasDismissMessageTarget) {
      this.dismissMessageTarget.parentElement.classList.remove('d-none');
      // set this back on showing the textarea
      this.dismissMessageTarget.setAttribute('required', true);
    }
  }

  hideDismissMessage() {
    if (this.hasDismissMessageTarget) {
      this.dismissMessageTarget.parentElement.classList.add('d-none');
      // to be able to submit form must remove html required attribute
      this.dismissMessageTarget.removeAttribute('required');
    }
  }
}
