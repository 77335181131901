import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap';
import { debounce } from 'lodash';

// Connects to data-controller="turbo-frame-loader"
export default class extends Controller {
  static targets = [
    'frame',
    'offcanvas',
    'loaderTemplate',
    'offcanvasTitle',
    'offcanvasBodyPadding'
  ];
  static values = {
    hideCanvasOnSuccess: false,
    closeFormSubmitPath: String
  };

  bootstrapOffCanvas = null;

  connect() {
    if (this.hasOffcanvasTarget) {
      this.bootstrapOffCanvas = new Offcanvas(this.offcanvasTarget);
      this.offcanvasTarget.addEventListener('hidden.bs.offcanvas', this.resetLoader.bind(this));
      this.element.addEventListener('turbo:submit-end', (event) => {
        if (
          this.hideCanvasOnSuccessValue &&
          event.detail.fetchResponse.statusCode === 200 &&
          this.eventUrlValidForClose(event)
        ) {
          this.bootstrapOffCanvas.hide();
        }
      });
    }
  }

  resetLoader() {
    this.frameTarget.innerHTML = this.loaderTemplateTarget.innerHTML;
  }

  setFrameSrcAndId(event) {
    let { target } = event;

    if (target.dataset.stopParentAction === 'true') {
      this.bootstrapOffCanvas.hide();
      return;
    }

    let title = event.currentTarget.dataset.offcanvasTitle;
    let bodyPadding = event.currentTarget.dataset?.offcanvasBodyPadding;
    let removeBodyPadding = event.currentTarget.dataset?.removeBodyPadding;
    if (title && this.hasOffcanvasTitleTarget) {
      this.offcanvasTitleTarget.innerText = title;
    }
    if (bodyPadding && removeBodyPadding === 'false') {
      this.offcanvasBodyPaddingTarget.classList.add(bodyPadding);
    }
    if (bodyPadding && removeBodyPadding === 'true') {
      this.offcanvasBodyPaddingTarget.classList.remove(bodyPadding);
    }

    let pathValue = event.currentTarget.dataset.path;
    let turboFrameIdentifier = event.currentTarget.dataset.turboFrameIdentifier;
    if (this.hasFrameTarget && pathValue && turboFrameIdentifier) {
      this.setFrameSrcAndIdDebounced(pathValue, turboFrameIdentifier);
    }
  }

  setFrameSrcAndIdDebounced(src, id) {
    debounce(() => {
      this.frameTarget.id = id;
      this.frameTarget.src = src;
      this.frameTarget.reload();
    }, 500)();
  }

  eventUrlValidForClose(event) {
    if (this.hasCloseFormSubmitPathValue) {
      let url = new URL(event.detail.fetchResponse.response.url);
      return url.pathname === this.closeFormSubmitPathValue;
    }

    false;
  }
}
