import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import { debounce } from 'lodash';

// Connects to data-controller="agency"
export default class extends Controller {
  static targets = ['themeInput'];

  initialize() {
    this.onThemeInputChangeDebounced = debounce(() => {
      let themeInput = this.themeInputTarget.value;
      if (themeInput.length >= 6) {
        post('/admin/agencies/update_avatar_theme', {
          body: {
            theme_color: themeInput,
            agency_id: this.themeInputTarget?.dataset?.agencyId || ''
          },
          responseKind: 'turbo-stream'
        });
      }
    }, 500);
  }

  onThemeInputChange() {
    if (this.hasThemeInputTarget) {
      this.onThemeInputChangeDebounced();
    }
  }
}
