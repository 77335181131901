import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['currentPassword', 'newPassword', 'newPasswordConfirmation', 'button'];
  connect() {
    this.buttonTarget.setAttribute('disabled', true);
    this.validateForm();
  }

  validateForm() {
    let status = false;
    if (this.validateCurrentPassword() && this.validateNewPassword() && this.validateNewPasswordConfirmation()) {
      status = true;
      this.buttonTarget.removeAttribute('disabled');
    } else {
      this.buttonTarget.setAttribute('disabled', true);
    }
    return status;
  }

  validateCurrentPassword() {
    const value = this.currentPasswordTarget.value;
    return value && value.length >= 1;
  }

  validateNewPassword() {
    const attr = this.newPasswordTarget.getAttribute('data-password-status');
    return Number(attr) === 1;
  }

  validateNewPasswordConfirmation() {
    const newPasswordValue = this.newPasswordTarget.value;
    const confirmationValue = this.newPasswordConfirmationTarget.value;

    return newPasswordValue === confirmationValue;
  }
}
