import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="location-filter"
export default class extends Controller {
  static targets = ['countryGrid', 'cityGrid'];

  connect() {
    this.toggleSelectedFilters();
  }

  toggleSelectedFilters() {
    if (this.countryFilterActive) {
      this.showCountryGrid();
      this.hideCityGrid();
    } else if (this.cityFilterActive) {
      this.hideCountryGrid();
      this.showCityGrid();
    } else {
      this.hideCountryGrid();
      this.hideCityGrid();
    }
  }

  changed(event) {
    let selectedFilterType = event.target.value;
    if (selectedFilterType == 'country') {
      this.clearCityOptions();
      this.countryGridTarget.dataset.activeFilter = true;
      this.cityGridTarget.dataset.activeFilter = false;
    } else if (selectedFilterType == 'city') {
      this.clearSelectedCountryCodes();
      this.countryGridTarget.dataset.activeFilter = false;
      this.cityGridTarget.dataset.activeFilter = true;
    } else {
      this.countryGridTarget.dataset.activeFilter = false;
      this.cityGridTarget.dataset.activeFilter = false;
    }
    this.toggleSelectedFilters();
  }

  clearCityOptions() {
    let cityDropdown = this.cityGridTarget.querySelector('select');
    if (cityDropdown) {
      cityDropdown.tomselect.clear();
    }

    if (this.rangeSlider && this.rangeSliderStartValue) {
      this.rangeSlider.value = this.rangeSliderStartValue;

      this.resetRadiusInput('');
    }
  }

  clearSelectedCountryCodes() {
    this.countryGridTarget.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  clear() {
    this.clearCityOptions();
    this.clearSelectedCountryCodes();
    this.hideCountryGrid();
    this.hideCityGrid();
  }

  showCityGrid() {
    this.cityGridTarget.classList.remove('d-none');
    this.resetRadiusInput(this.rangeSliderStartValue);
  }

  hideCityGrid() {
    this.cityGridTarget.classList.add('d-none');
  }

  showCountryGrid() {
    this.countryGridTarget.classList.remove('d-none');
  }

  hideCountryGrid() {
    this.countryGridTarget.classList.add('d-none');
  }

  resetRadiusInput(value) {
    this.cityGridTarget
      .querySelectorAll('input[name="filters[location.radius]"]')
      .forEach((input) => {
        input.value = value;
      });
  }

  get countryFilterActive() {
    return this.countryGridTarget.dataset.activeFilter == 'true';
  }

  get cityFilterActive() {
    return this.cityGridTarget.dataset.activeFilter == 'true';
  }

  get rangeSlider() {
    return this.cityGridTarget.querySelector('#radiusSlider');
  }

  get rangeSliderStartValue() {
    let startValue = this.rangeSlider.dataset.startValue;
    if (!startValue) {
      startValue = this.rangeSlider.min;
    }

    return startValue;
  }
}
