<script>
  import pageState from '~/services/pageState.svelte';

  let { initialCount } = $props();
  let count = $state(initialCount || 0);

  // Example of how to assign pageState to a variable without losing reactivity
  // let last_create_image_history = $derived(pageState.last_create_image_history);
  let { server_time_now, last_create_image_history } = $derived(pageState);

  function addOne() {
    count++;
  }
</script>

<div class="button-holder border mb-5">
  <div class="">
    Hello <b>{pageState.current_user.name}</b>! You've clicked {count} times since page load...
  </div>
  <button onclick={addOne}>click me: {count}</button>

  {#if last_create_image_history}
    <p class="mt-2">
      Last AI Image History [{last_create_image_history.id}] had prompt "{last_create_image_history.ai_prompt}"
      and used model <b>{last_create_image_history.model}</b>
      (Checked at: {server_time_now})
    </p>
  {/if}
</div>

<style lang="scss">
  .button-holder {
    padding: 1rem;

    p {
      font-size: 0.8em;
      color: gray;
    }

    button {
      font-size: 1.1em;
      color: green;
    }
  }
</style>
