import { Controller } from '@hotwired/stimulus';
import { subscribe, unsubscribe } from '../helpers/eventbus';
// Connects to data-controller="mobile-view-port-handler"
export default class extends Controller {
  subscription = null;
  connect() {
    this.subscription = subscribe('mobile-view-changed', this.handle.bind(this));
  }

  disconnect() {
    unsubscribe('mobile-view-changed', this.subscription);
  }

  handle() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
