import { Controller } from '@hotwired/stimulus';
import simpleParallax from 'simple-parallax-js';

// Connects to data-controller="simple-parallax"
// Add parallax effect to an image via https://simpleparallax.com/
// Set options via data-simple-parallax-options-value
export default class extends Controller {
  static values = {
    options: Object,
    includeAllImgs: Boolean
  };

  static targets = ['el'];

  instances = [];

  connect() {
    // IMPORTANT: the img el must be inside this.element (not be the same el)
    // because simpleParallax will relocate the img el, which would also re-connect the stimulus controller if it's the same el, causing infinite recursion
    this.instances = [];
    if(this.includeAllImgsValue){
      this.instances.push(new simpleParallax(this.element.querySelector('img'), this.optionsValue));
    }

    this.elTargets.forEach((el) => {
      this.instances.push(new simpleParallax(el, this.optionsValue));
    });

  }

  disconnect() {
    this.instances.forEach((instance) => {
      instance.destroy();
    });
  }
}
