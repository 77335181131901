import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'output', 'hideable'];
  static classes = ['hide'];
  static values = {
    showIf: Array
  };

  connect() {
    this.change(null);
  }

  change(event) {
    if (event && event.currentTarget.dataset.value) {
      if (!this.showIfValue.includes(event.currentTarget.dataset.value)) {
        this.outputTarget.classList.add(this.hideClass);
      } else {
        this.outputTarget.classList.remove(this.hideClass);
      }
    } else if (this.hasFieldTarget) {
      let value = this.fieldTarget.value || this.fieldTarget.dataset.value;
      if (!this.showIfValue.includes(value)) {
        this.outputTarget.classList.add(this.hideClass);
      } else {
        this.outputTarget.classList.remove(this.hideClass);
      }
    }
  }

  toggleTargets() {
    this.hideableTargets.forEach((el) => {
      el.hidden = !el.hidden;
    });
  }
}
