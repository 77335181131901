import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="accordion-helper"

export default class extends Controller {
  static values = {
    autoExpandActiveCollapse: Boolean
  };
  connect() {
    if (this.autoExpandActiveCollapseValue) {
      this.autoExpand();
    }
  }

  // This helps the Accordion automatically expand when there is an element with the .active class inside (like an active nav link)
  autoExpand() {
    let activeEl = this.getActiveEl();
    if (!activeEl) return;
    // expand accordion by default in this case
    let accordionCollapse = activeEl.closest('.accordion-collapse');
    accordionCollapse.classList.add('show');
    // also flip the + icon to be a - in the accordion button
    let accordionButton = activeEl.closest('.accordion-item').querySelector('.accordion-button');
    accordionButton.classList.remove('collapsed');
  }

  getActiveEl() {
    return this.element.querySelector('.active');
  }
}
