import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';
// Connects to data-controller="range-cadence"
export default class extends Controller {
  static values = {
    submitPath: String
  };

  onChange(event) {
    if (this.hasSubmitPathValue) {
      let cadence = event.currentTarget.dataset.value;
      // does the cadence dropdown appear on a page that shows subscription data
      // if it does - are we also giving the option to toggle between stripe and apple sub data
      // then we expect a hidden input with the following name - if it's checked then we should persist it on updating the cadence
      let apple_subs = document.querySelector('[name="service_provider_apple"]');
      let body = {
        cadence,
        date: this.flatpickrDates
      };
      if (apple_subs) {
        body['service_provider_apple'] = apple_subs.checked;
      }
      put(this.submitPathValue, {
        body: body,
        responseKind: 'turbo-stream'
      });
    }
  }

  get flatpickrElem() {
    return document.querySelector('[data-flatpickr-target="main"]');
  }

  get flatpickrDates() {
    if (this.flatpickrElem && this.flatpickrElem._flatpickr) {
      let { selectedDates } = this.flatpickrElem._flatpickr;
      return {
        to: Math.round(+selectedDates[1] / 1000),
        since: Math.round(+selectedDates[0] / 1000)
      };
    }

    return {};
  }
}
