import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="animate-out-setter"

// The point of this is to change the data-animate-out attribute of a target element when clicking the el that this controller is on
// This allows changing what the out animation is going to be as you click the button that will soon cause the el to animate out
export default class extends Controller {

  static values = {
    targetId: String,
    targetAnimateOut: String,
  };
  connect() {
    // Should be fine to not unbind it because this dom el will just be gone when this controller unloads anyway
    this.element.addEventListener('click', this.onClick.bind(this));
  }

  onClick(){
    let targetEl = document.getElementById(this.targetIdValue);
    if(!targetEl) return console.error(`animate-out-setter target not found: ${this.targetIdValue}`);
    // set data attribute on targetEl data-animate-out
    targetEl.dataset.animateOut = this.targetAnimateOutValue;
  }
}
