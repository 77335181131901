import { Controller } from '@hotwired/stimulus';
import { findElement } from '../helpers';

// Connects to data-controller="ai-control-generate-button"
export default class extends Controller {
  static values = {
    disabled: Boolean,
    buttonId: String
  };

  connect() {
    if (this.hasButtonIdValue) {
      let button = findElement(document, this.buttonIdValue);
      if (!button) {
        return;
      }

      if (this.disabledValue) {
        button.setAttribute('disabled', true);
        button.classList.add('disabled-while-creating');
      } else {
        button.classList.remove('disabled-while-creating');
        button.removeAttribute('disabled', true);
        this.replaceHistory();
      }
    }
  }

  replaceHistory() {
    let url = new URL(window.location.href);
    url.searchParams.delete('creating');
    window.history.replaceState(null, '', url);
  }
}
