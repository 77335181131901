import { Controller } from '@hotwired/stimulus';

// Toggle between files/links on opportunity edit popup
export default class extends Controller {
  static targets = ['filesDiv', 'linksDiv', 'filesCheckbox', 'linksCheckbox'];

  connect() {}

  showFiles() {
    this.filesCheckboxTarget.checked = true;
    this.linksCheckboxTarget.checked = false;
    this.filesDivTarget.classList.remove('d-none');
    this.linksDivTarget.classList.add('d-none');
  }

  showLinks() {
    this.filesCheckboxTarget.checked = false;
    this.linksCheckboxTarget.checked = true;
    this.filesDivTarget.classList.add('d-none');
    this.linksDivTarget.classList.remove('d-none');
  }
}
