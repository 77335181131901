import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';
// Connects to data-controller="user-type"
export default class extends Controller {
  static values = {
    submitPath: String
  };

  onChange(event) {
    if (this.hasSubmitPathValue) {
      let usertype = event.currentTarget.dataset.value;

      let body = {
        usertype,
        date: this.flatpickrDates
      };
      put(this.submitPathValue, {
        body: body,
        responseKind: 'turbo-stream'
      });
    }
  }

  get flatpickrElem() {
    return document.querySelector('[data-flatpickr-target="main"]');
  }

  get flatpickrDates() {
    if (this.flatpickrElem && this.flatpickrElem._flatpickr) {
      let { selectedDates } = this.flatpickrElem._flatpickr;
      return {
        to: Math.round(+selectedDates[1] / 1000),
        since: Math.round(+selectedDates[0] / 1000)
      };
    }

    return {};
  }
}
