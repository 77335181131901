import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];
  static values = {
    id: Number
  };

  connect() {
    this.visitingId();

    if (!this.conversationId) return;

    if (this.idValue == this.conversationId) {
      this.removeUnreadMessageCounter();
      this.itemTarget.classList.add('active');
    } else {
      this.itemTarget.classList.remove('active');
    }
  }

  visitingId() {
    let id = Number(window.location.pathname.split('/').pop());

    if (Number.isNaN(id)) {
      this.conversationId = 0;
    }

    this.conversationId = id;
  }

  removeUnreadMessageCounter() {
    let badgeIcon = this.itemTarget.getElementsByClassName('badge')[0];
    if (badgeIcon) {
      badgeIcon.remove();
    }
  }
}
