import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hoverImage', 'videoThumbnail', 'videoHolderEl', 'videoEl', 'posterOverlay', 'playButton'];

  isHovering = false;
  isClicked = false;
  hoveringTimeoutRef = null;
  currentHoverImageIndex = 0;

  connect() {
    if (this.isVideoMode()) {
      // We need to recreate the videoEl html for it to actually consider its current <source> els (otherwise it just loads the ones that were on the page originally)
      let videoHtml = this.videoHolderElTarget.innerHTML;
      this.videoHolderElTarget.innerHTML = videoHtml;

      // Show video el once the video data has loaded
      this.videoElTarget.addEventListener('loadeddata', () => {
        this.videoHolderElTarget.classList.remove('d-none');
      });
    }
  }

  disconnect() {
    this.unscheduleNextShowHoverImage();
  }

  isVideoMode() {
    return this.hasVideoHolderElTarget;
  }
  
  onMouseClick() {
    
    this.isClicked = true;

    this.onVideoClick();
  }

  onMouseOver() {
    if (this.isHovering) {
      return;
    }

    this.isHovering = true;

    if (this.isVideoMode()) {
      this.onVideoClick();
    } else {
      this.showHoverImage(false);
      this.scheduleNextShowHoverImage();
    }
  }

  onMouseLeave() {
    if (!this.isHovering) {
      return;
    }

    this.isHovering = false;

    if (this.isVideoMode()) {
      this.onVideoHoverOff();
    } else {
      this.unscheduleNextShowHoverImage();
      this.showHoverImage(false);
    }
  }

  onVideoClick() {
    this.videoElTarget.style.opacity = 1;
    this.posterOverlayTarget.remove();
    console.log(this.playButtonTarget);
    this.playButtonTarget.remove();
    this.videoElTarget.currentTime = 0;
    this.videoElTarget.play();
  }

  scheduleNextShowHoverImage() {
    // Using setTimeout instead of setInterval to avoid bug where if browser tab is awoken again after tabbing away, setInterval will try to quickly catch up all cycles it missed, which we don't want
    this.hoveringTimeoutRef = setTimeout(() => {
      this.showHoverImage(true);
      this.scheduleNextShowHoverImage();
    }, 5000);
  }

  unscheduleNextShowHoverImage() {
    clearTimeout(this.hoveringTimeoutRef);
    this.hoveringTimeoutRef = null;
  }

  showHoverImage(showNext) {
    if (this.hoverImageTargets.length < 2) {
      return;
    }
    if (showNext) {
      this.currentHoverImageIndex =
        (this.currentHoverImageIndex + 1) % this.hoverImageTargets.length;
    } else {
      this.currentHoverImageIndex = 0;
    }

    let nextIndex = (this.currentHoverImageIndex + 1) % this.hoverImageTargets.length;
    // JS doesn't support modding negative numbers properly without this hack
    let prevIndex =
      (this.currentHoverImageIndex + this.hoverImageTargets.length - 1) %
      this.hoverImageTargets.length;

    // Loop through all the images and set their css classes to what they should be
    for (let i = 0; i < this.hoverImageTargets.length; i++) {
      let hoverImageEl = this.hoverImageTargets[i];
      // When not hovering, we want to show 0 as 'cover'
      if (i === this.currentHoverImageIndex && !this.isHovering) {
        hoverImageEl.classList.add('cover');
        hoverImageEl.classList.remove('current');
        this.loadImageForHoverImageEl(hoverImageEl);
      } else if (i === this.currentHoverImageIndex) {
        // When hovering, we want the current image as 'current'
        hoverImageEl.classList.add('current');
        hoverImageEl.classList.remove('cover');
        this.loadImageForHoverImageEl(hoverImageEl);
      } else {
        hoverImageEl.classList.remove('current');
        hoverImageEl.classList.remove('cover');
      }

      if (i === nextIndex && this.isHovering) {
        hoverImageEl.classList.add('next');
        this.loadImageForHoverImageEl(hoverImageEl);
      } else {
        hoverImageEl.classList.remove('next');
      }

      if (i === prevIndex && this.isHovering && showNext) {
        hoverImageEl.classList.add('previous');
      } else {
        hoverImageEl.classList.remove('previous');
      }
    }
  }

  loadImageForHoverImageEl(el) {
    let imgEl = el.querySelector('img');
    // When the srcset is blank, we know we haven't loaded the image yet (they do immediately have the src set (to a blank inline image at first))
    if (!imgEl.attributes.srcset?.value) {
      // setting this sometimes seems to force a load, so only do this once per image
      imgEl.src = imgEl.attributes['data-src'].value;
      imgEl.srcset = imgEl.attributes['data-srcset'].value;
    }
  }
}
