import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="viewport-class-toggler"
export default class extends Controller {
  static values = {
    css: String,
    threshold: Number // 0.0-1.0; This percentage of the el must be visible before it's considered 'intersecting'
  };

  connect() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: this.thresholdValue
    };
    this.intersectionObserver = new IntersectionObserver((entries) => {
      // console.log('entries', entries);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.element.classList.add(this.cssValue);
        }else{
          this.element.classList.remove(this.cssValue);
        }
      });
    }, options);

    let el = this.element;
    // Fix a Safari-only bug where as the image is showing/hiding due to animation/etc, it infinitely toggles visibility on/off, resulting in staying invisible
    if(el.classList.contains('hide-without-animation')){
      el = this.element.parentElement;
    }

    this.intersectionObserver.observe(el);
  }
}
