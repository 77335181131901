import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    focus: { type: Boolean, default: false }
  };

  static targets = ['bodyField'];

  connect() {
    if (this.hasBodyFieldTarget && this.focusValue) {
      this.bodyFieldTarget.focus();
    }
  }
}
