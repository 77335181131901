import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'planInterval',
    'submitButton',
    'monthlyPrice',
    'yearlyPrice',
    'defaultOptionRanking',
    'userRanking',
    'alert'
  ];

  currentOption = '';
  userRanking = null;
  selectedRanking = null;

  connect() {
    // if(!this.hasPlanIntervalTarget) return;

    this.currentOption = 'yearly';
    this.planIntervalTarget.setAttribute('value', 'year');
    this.showYearlyPrices();
    this.userRanking = parseInt(this.userRankingTarget.getAttribute('value'));
    this.selectedRanking = parseInt(this.defaultOptionRankingTarget.getAttribute('value'));
    this.updateAlert();
  }

  get checkedOption() {
    let choices = this.element.querySelectorAll('.form-check-select-membership');
    let selected = null;

    for (const elem of choices) {
      let input = elem.querySelector('input[name="plan_id"]');
      if (input.checked) {
        selected = elem;
      }
    }
    return selected;
  }

  toggle() {
    if (this.currentOption === 'yearly') {
      this.currentOption = 'monthly';
      this.planIntervalTarget.setAttribute('value', 'month');
      this.showMonthlyPrices();
    } else {
      this.currentOption = 'yearly';
      this.planIntervalTarget.setAttribute('value', 'year');
      this.showYearlyPrices();
    }

    let yearlyPrice = this.checkedOption.dataset.creatorMembershipYearlyPriceParam;
    let monthlyPrice = this.checkedOption.dataset.creatorMembershipMonthlyPriceParam;
    let name = this.checkedOption.dataset.creatorMembershipNameParam;
    console.log(name, yearlyPrice, monthlyPrice);
    this.updatePriceOnButton(name, yearlyPrice, monthlyPrice);
  }

  select({ params: { name, ranking, yearlyPrice, monthlyPrice } }) {
    this.updatePriceOnButton(name, yearlyPrice, monthlyPrice);
    if (ranking) {
      this.selectedRanking = ranking;
      this.updateAlert();
    }
  }

  updatePriceOnButton(name, yearlyPrice, monthlyPrice) {
    if (name) {
      this.submitButtonTarget.innerHTML = `Upgrade to ${name}`;
    }
    if (yearlyPrice && this.currentOption === 'yearly') {
      this.submitButtonTarget.innerHTML =
        this.submitButtonTarget.innerHTML + this.yearlyPriceCopy(yearlyPrice);
    }
    if (monthlyPrice && this.currentOption === 'monthly') {
      this.submitButtonTarget.innerHTML =
        this.submitButtonTarget.innerHTML + this.monthlyPriceCopy(monthlyPrice);
    }
  }
  showYearlyPrices() {
    this.monthlyPriceTargets.forEach((targ) => {
      targ.setAttribute('hidden', true);
    });
    this.yearlyPriceTargets.forEach((targ) => {
      targ.removeAttribute('hidden');
    });
  }

  showMonthlyPrices() {
    this.yearlyPriceTargets.forEach((targ) => {
      targ.setAttribute('hidden', true);
    });
    this.monthlyPriceTargets.forEach((targ) => {
      targ.removeAttribute('hidden');
    });
  }

  updateAlert() {
    if (this.userRanking && this.selectedRanking && this.userRanking > this.selectedRanking) {
      this.alertTarget.removeAttribute('hidden');
    } else {
      this.alertTarget.setAttribute('hidden', true);
    }
  }

  monthlyPriceCopy(price) {
    return ` (${price}/mo)`;
  }

  yearlyPriceCopy(price) {
    return ` (${price}/yr)`;
  }
}
