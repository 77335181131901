import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'container',
    'select',
    'cash',
    'experience',
    'giftcard',
    'product',
    'service',
    'cashDropdownAction',
    'experienceDropdownAction',
    'giftcardDropdownAction',
    'productDropdownAction',
    'serviceDropdownAction',
    'compensationsContainer',
    'negotiableCompensationText',
    'fixedCompensationText'
  ];

  static values = {
    compensationIndex: Number
  };

  connect() {
    if (this.element) {
      let button = this.element.querySelector('.dropdown-toggle');
      button.onClick = (e) => e.preventDefault();
    }
  }

  removeCompensationType(event) {
    let type = event.currentTarget.dataset.compensationType;
    let template = null;
    switch (type) {
      case 'cash':
        template = this.cashDropdownActionTarget;
        break;
      case 'experience':
        template = this.experienceDropdownActionTarget;
        break;
      case 'giftcard':
        template = this.giftcardDropdownActionTarget;
        break;
      case 'product':
        template = this.productDropdownActionTarget;
        break;
      case 'service':
        template = this.serviceDropdownActionTarget;
        break;
      default:
        break;
    }

    let index = template.dataset.dropdownIndex;
    let indicies = [...this.selectTarget.children].map((_, idx) => idx);
    let closest = 0;
    if (indicies.length) {
      closest = indicies.reduce((prev, curr) => {
        return Math.abs(curr - Number(index)) < Math.abs(prev - Number(index)) ? curr : prev;
      });
    }

    if (closest === 0) {
      this.selectTarget.insertAdjacentHTML('afterbegin', template.innerHTML);
    } else {
      let child = this.selectTarget.children[closest];
      if (closest >= Number(index)) {
        child.insertAdjacentHTML('beforebegin', template.innerHTML);
      } else if (closest <= Number(index)) {
        child.insertAdjacentHTML('afterend', template.innerHTML);
      }
    }

    let block = event.target.closest('.compensation-block');
    block.remove();
    this.compensationIndexValue--;
  }

  addCompensationType(event) {
    let type = event.currentTarget.dataset.compensationType;

    let template = null;
    switch (type) {
      case 'cash':
        template = this.cashTarget;
        break;
      case 'experience':
        template = this.experienceTarget;
        break;
      case 'giftcard':
        template = this.giftcardTarget;
        break;
      case 'product':
        template = this.productTarget;
        break;
      case 'service':
        template = this.serviceTarget;
        break;
      default:
        break;
    }
    let content = template.innerHTML.replace(/COMPENSATION_INDEX/g, this.compensationIndexValue);
    this.containerTarget.insertAdjacentHTML('beforeend', content);
    this.compensationIndexValue++;

    // remove target after getting value
    event.currentTarget.remove();
  }

  removeCompensations() {
    this.fixedCompensationTextTarget.classList.add('d-none');
    this.negotiableCompensationTextTarget.classList.remove('d-none');
    this.compensationsContainerTarget.classList.add('d-none');
    Array.from(this.containerTarget.getElementsByTagName('li')).forEach((liElement) => {
      liElement.querySelector('a').click();
    });
  }

  addCompensations() {
    this.negotiableCompensationTextTarget.classList.add('d-none');
    this.fixedCompensationTextTarget.classList.remove('d-none');
    this.compensationsContainerTarget.classList.remove('d-none');
  }
}
