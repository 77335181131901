import { Controller } from '@hotwired/stimulus';
import { sleep } from '../helpers';

const CSS_CLASSES =
  'toast align-items-center text-white shadow border-0 rounded-1 mb-1 fade hide'.split(' ');

export default class extends Controller {
  static targets = ['toast'];
  static values = {
    duration: Number
  };

  connect() {
    for (let css of CSS_CLASSES) {
      this.toastTarget?.classList?.add(css);
    }
    this.fadeOut();
  }

  async fadeOut() {
    // show for three seconds - 1.5s might be too slow to read any message
    let duration = this.durationValue || 3000;

    // This emulates what BootstrapJS seems to do. We intend to do something more custom later
    this.toastTarget?.classList?.remove('hide');
    this.toastTarget?.classList?.add('show');
    this.toastTarget?.classList?.add('showing');

    await sleep(150);
    // The el might very well be gone after sleeping, so we're accessing it safely
    this.toastTarget?.classList?.remove('showing');

    await sleep(duration);
    this.toastTarget?.classList?.add('showing');

    await sleep(150);
    this.toastTarget?.classList?.add('hide');
    this.toastTarget?.classList?.remove('show');

    this.toastTarget?.remove();
  }
}
