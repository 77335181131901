import { Controller } from '@hotwired/stimulus';
import { toInteger } from 'lodash';
import { subscribeAll } from '../helpers/eventbus';

// Tell the browser to find any required fields
let requiredFieldSelectors = '[data-required="true"]';
let notRequiredFieldSelectors = '[data-required="false"]';
export default class extends Controller {
  static values = {
    validateAll: { type: Boolean, default: false },
    skipValidation: { type: Boolean, default: false }
  };

  static targets = [
    'submitDisabler',
  ];

  connect() {
    this.unsubscribeAll = subscribeAll(this, {
      'turboUploader:filesCountChanged': this.onFilesCountChanged
    });

    if (this.skipValidationValue) {
      return;
    }

    if (this.requiredFields.length > 0) {
      this.disableSubmitButton();
    }

    this.requiredFields.forEach((field) => {
      field.addEventListener('input', this.validateForm.bind(this));
    });

    this.notRequiredFields.forEach((field) => {
      field.addEventListener('input', this.validateForm.bind(this));
    });
  }

  disconnect() {
    this.unsubscribeAll();
  }

  onFilesCountChanged() {
    // We don't call validateForm directly because we want to make sure there is no param being passed to it
    this.validateForm();
  }

  submitDisablerTargetConnected(){
    this.validateForm();
  }
  submitDisablerTargetDisconnected(){
    this.validateForm();
  }

  validateForm(event) {
    let validForm = false;
    let validationReponse = [];

    if(this.submitDisablerTargets.length > 0){
      validForm = false;

    }else if (this.validateAllValue || !event) {
      this.requiredFields.forEach((field) => {
        validationReponse.push(this.validateField(field));
      });

      this.notRequiredFields.forEach(() => validationReponse.push(true));

      validForm = validationReponse.every((value) => value == true);
    } else {
      validForm = this.validateField(event.target);
    }

    if (validForm) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  validateField(field) {
    if (field.dataset.validationType) {
      if(field.dataset.validationType == 'always_invalid'){
        return false;
      }
      return !!field.value;
    } else if(field.type == 'hidden') {
      if (field.name == 'organization[avatar]') {
        return !!field.value; // if not blank
      } else {
        return toInteger(field.value) > 0; // this is not working well for strings :( ;
      }
    } else if (field.type == 'checkbox') {
      if (field.value == 'reject_delivery' && this.rejectReasonIsBlank()) return false;

      return field.checked;
    } else if (field.type == 'radio' && field.dataset.otherFieldTarget) {
      return false;
    } else if (field.type == 'tel' && !(field.value.length >= 10)) return false;

    return !!field.value;
  }

  disableSubmitButton() {
    if (!this.submitButton) {
      return;
    }

    if (this.isSubmitButtonAnchorTag) {
      this.submitButton.classList.add('disabled');
    } else this.submitButton.disabled = true;
  }

  enableSubmitButton() {
    if (!this.submitButton) {
      return;
    }

    if (this.isSubmitButtonAnchorTag) {
      this.submitButton.classList.remove('disabled');
    } else this.submitButton.disabled = false;
  }

  rejectReasonIsBlank() {
    let rejectReasonField = document.querySelector('textarea[name="rejection_reason"]');
    return rejectReasonField.value == '';
  }

  get requiredFields() {
    return this.element.querySelectorAll(requiredFieldSelectors);
  }

  get notRequiredFields() {
    return this.element.querySelectorAll(notRequiredFieldSelectors);
  }

  get submitButton() {
    return (
      document.getElementById('attachment-submit-button') ||
      document.getElementById('delete-account') ||
      document.getElementById('update-organization-button') ||
      document.getElementById('create-agency') ||
      document.getElementById('addCreator') ||
      document.getElementById('create-prompt-rewrite') ||
      document.getElementById('create-ai-assistant') ||
      document.getElementById('create-assistant-persona')
    );
  }

  get isSubmitButtonAnchorTag() {
    return this.submitButton.tagName == 'A';
  }
}
