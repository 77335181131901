import { Controller } from '@hotwired/stimulus';
import { isMobileDevice, isSafari } from '../helpers';

let nextId = 0;

export default class extends Controller {
  static targets = ['element'];

  id = null;
  glightboxElClass = null;
  slideNodeByIndex = {};

  connect() {
    // give each instance of this controller a unique Id
    this.id = ++nextId;

    this.glightboxElClass = `glightbox-gallery-${this.id}`;

    // Give each of our elements our unique class name so our Glightbox instance runs on only them
    this.elementTargets.forEach(el => {
      el.classList.add(this.glightboxElClass);
    });

    this.glightbox = window.GLightbox({
      selector: `.${this.glightboxElClass}`,
      touchNavigation: true
    });

    // Inject html for video before slides load in the lightbox
    this.glightbox.on('slide_before_load', data => {
      // data is an object that contain the following
      const { slideIndex, slideNode } = data;
      slideNode.classList.add(this.glightboxElClass);

      // glightbox sometimes doesn't pass in slideNode in subsequent events, so we store it now
      this.slideNodeByIndex[slideIndex] = slideNode;

      let { isVideo, videoMp4_1200kUrl, videoMp4_2400kUrl, videoWebm_1200kUrl, videoWebm_2400kUrl, videoFallbackUrl, videoPlaceholderUrl, videoIsTranscoded } = this.getIndexData(
        slideIndex
      );
      if (isVideo) {
        let mediaEl = slideNode.querySelector('.gslide-media');

        // controlsList="nofullscreen" doesn't work well in at least Chrome/Firefox
        let videoHtml = `
          <video muted playsinline preload="none"
            controls disablepictureinpicture
            controlsList="nodownload noremoteplayback noplaybackrate nofullscreen"
            poster="${videoPlaceholderUrl}">`;

        if(videoIsTranscoded){
          if(!isSafari()){
            let url = isMobileDevice() ? videoWebm_1200kUrl : videoWebm_2400kUrl;
            videoHtml +=`<source src="${url}" type="video/webm">`;
          }
          let url = isMobileDevice() ? videoMp4_1200kUrl : videoMp4_2400kUrl;
          videoHtml +=`<source src="${url}">`;

        }else{
          videoHtml +=` <source src="${videoFallbackUrl}"> `;
        }

        videoHtml += '<p>Your browser doesn\'t support this video.</p></video>';

        mediaEl.innerHTML = videoHtml;
      }
    });

    this.glightbox.on('slide_before_change', ({ prev: previous }) => {
      // the API docs are wrong: the slideIndex here is an el; it's actually called index (like above event)
      let previousIndexData = this.getIndexData(previous.index);
      if (previousIndexData.isVideo) {
        previousIndexData.videoEl.pause();
      }
    });

    this.glightbox.on('slide_changed', ({ current }) => {
      let currentIndexData = this.getIndexData(current.index);
      if (currentIndexData.isVideo) {
        currentIndexData.videoEl.play();
      }
    });
  }

  getIndexData(index) {
    let pageElement = this.elementTargets[index];
    if (!pageElement) {
      return {};
    }

    let data = pageElement.dataset;
    // data-type is s Glightbox concept; making it custom means it will leave it alone
    // We need that so we can have a video with multiple sources into the html (it doesn't support that for videos)
    const slideNode = this.slideNodeByIndex[index];
    return Object.assign({}, data, {
      isVideo: data.type === 'custom-video',
      videoIsTranscoded: data.videoIsTranscoded === 'true',
      videoEl: slideNode?.querySelector('video'),
      slideNode
    });
  }

  open() {
    this.glightbox.open();
  }
}
