import { Controller } from '@hotwired/stimulus';
import { stripHtml } from 'string-strip-html';

// data-controller="export-copy-to-clipboard"
export default class extends Controller {
  static targets = ['copyButton', 'copySource'];
  static values = {
    cssUrl: String,
    successDuration: {
      type: Number,
      default: 2000
    }
  };

  connect() {
    this.sourceTarget = document.getElementById(this.element.dataset.targetId);
  }

  text() {
    let text = stripHtml(this.sourceTarget.innerText).result;
    this.copy(text.trim());
  }

  html() {
    let doc = document.implementation.createHTMLDocument('AI Generated');
    let styleSheet = document.createElement('link');
    styleSheet.setAttribute('rel', 'stylesheet');
    styleSheet.href = this.cssUrlValue;
    styleSheet.type = 'text/css';
    doc.head.append(styleSheet);
    doc.body.innerHTML = this.sourceTarget.innerHTML;
    this.copy(`<!DOCTYPE html><html>${doc.lastChild.innerHTML}</html>`);
  }

  markdown() {
    this.copy(this.copyButtonTarget.dataset.content);
  }

  copy(text) {
    navigator.clipboard.writeText(text).then(() => this.copied(this.copyButtonTarget.innerText));
  }

  copied(defaultValue) {
    if (!this.hasCopyButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.copyButtonTarget.children[1].innerText = this.copyButtonTarget.dataset.successContent;

    this.timeout = setTimeout(() => {
      this.copyButtonTarget.children[1].innerText = defaultValue;
    }, this.successDurationValue);
  }
}
