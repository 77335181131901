import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    storgeIdentifier: String
  };

  connect() {
    let previouslyVisitemHistoryItem = localStorage.getItem(this.storgeIdentifierValue);
    if (previouslyVisitemHistoryItem) {
      let element = document.getElementById(previouslyVisitemHistoryItem);
      if (element) {
        element.focus();
      }

      localStorage.removeItem(this.storgeIdentifierValue);
    }
  }

  storeElementToFoucsId(event) {
    localStorage.setItem(this.storgeIdentifierValue, event.currentTarget.id);
  }
}
