import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['limit'];

  connect() {
    this.showHideAlert();
  }

  showHideAlert() {
    if (this.hasLimitTarget) {
      let limit = 0;
      this.element.querySelectorAll('select').forEach((select) => {
        if (select.value) {
          limit++;
        }
      });
      if (limit > 5) {
        this.limitTarget.classList.remove('d-none');
      } else {
        this.limitTarget.classList.add('d-none');
      }
    }
  }
}
