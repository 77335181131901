import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import WaveSurfer from 'wavesurfer.js';
import { emit, subscribe, unsubscribe } from '../helpers/eventbus';

// Connects to data-controller="ai-audio"
export default class extends Controller {
  static targets = [
    'select',
    'speaker',
    'voiceModel',
    'scriptInput',
    'audioButton',
    'deleteAudio',
    'voiceOptions',
    'histories',
    'voiceOptionsButton',
    'historiesButton',
    'audioClip',
    'progress',
    'playPause',
    'play',
    'pause',
    'timestamp',
    'textToSpeechOption',
    'speechToSpeechOption',
    'textToSpeech',
    'speechToSpeech',
    'selectedVoice',
    'voiceGenerateButton',
    'name',
    'description',
    'deleteVoice',
    'voiceRecipientInput',
    'shareVoice',
    'confirmDeleteSharedVoice',
    'deleteSharedVoice',
    'cancelSharedVoiceAction',
    'addVoiceToList',
    'removeVoiceFromList',
    'moveToVerifyVoiceButton',
    'moveToDescribeVoiceButton',
    'selectLanguage',
    'resetVoiceSettings',
    'toggleVoiceAsDefault'
  ];

  static values = {
    speechOption: String,
    previewUrl: String,
    waveformContainer: String,
    historyId: Number,
    min: Number,
    max: Number
  };

  voiceOptionsShown = false;
  historiesShown = false;
  textToSpeechOptionShown = false;
  speechToSpeechOptionShown = false;

  connect() {
    const lastTabAccessed = localStorage.getItem('audio_flyout_tab');
    if (
      this.hasVoiceGenerateButtonTarget &&
      this.hasNameTarget &&
      this.hasDescriptionTarget &&
      !this.validateVoiceCloning()
    ) {
      this.voiceGenerateButtonTarget.setAttribute('disabled', true);
    }

    document.addEventListener('DOMContentLoaded', () => {
      if (this.hasScriptInputTarget && !this.scriptInputTarget.value) {
        this.scriptInputTarget.value = localStorage.getItem('last_audio_script');
        emit('ai-audio-script-set');
      }
      if (!lastTabAccessed || lastTabAccessed === 'voice_options') {
        this.showVoiceOptions();
        this.hideHistories();
      }
      if (lastTabAccessed === 'histories') {
        this.showHistories();
        this.hideVoiceOptions();
      }
      if (this.speechOptionValue == 'speech_to_speech') {
        this.hideTextToSpeechOption();
      } else {
        this.hideSpeechToSpeechOption();
      }
    });

    // This controller is used in many ways, one of which is to hold one instance of WaveSurfer
    // In this case, we'd like to auto-load that WaveSurfer (which implies loading that .wav file) when it's visible
    if (this.waveformContainerValue && this.previewUrlValue) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1 // This percentage of the el must be visible before it's considered 'intersecting'
      };
      this.intersectionObserver = new IntersectionObserver(() => {
        console.log(
          'waveformContainerValue VIVISBLE',
          this.waveformContainerValue,
          this.previewUrlValue
        );
        this.createWaveSurfer(this.waveformContainerValue, this.previewUrlValue);
      }, options);
      this.intersectionObserver.observe(this.element);
    }

    this.stopSubscription = subscribe('stopAllAudioPlayers', this.stopWS.bind(this));
  }
  disconnect() {
    unsubscribe('stopAllAudioPlayers', this.stopSubscription);
  }

  validateVoiceCloning() {
    const nameValue = this.nameTarget.value.trim();
    const descriptionValue = this.descriptionTarget.value.trim();
    const attachedVoiceFilesCount =
      this.voiceGenerateButtonTarget?.dataset?.attachedMediaCount || 0;

    return (
      nameValue &&
      descriptionValue &&
      nameValue.length >= 1 &&
      descriptionValue.length >= 1 &&
      attachedVoiceFilesCount > 0
    );
  }

  validateForm() {
    if (this.hasVoiceGenerateButtonTarget) {
      if (this.validateVoiceCloning()) {
        this.voiceGenerateButtonTarget.removeAttribute('disabled');
      } else {
        this.voiceGenerateButtonTarget.setAttribute('disabled', true);
      }
    }
  }

  // stopAllPlayingAudio(existingPlayerIdentifier = null) {
  //   document.querySelectorAll('audio').forEach((audio) => {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   });
  //
  //   document.querySelectorAll('#custom_player_play_button').forEach((button) => {
  //     console.log('#custom_player_play_button');
  //     button.classList?.remove('d-none');
  //   });
  //   document.querySelectorAll('#custom_player_pause_button').forEach((button) => {
  //     console.log('#custom_player_pause_button');
  //     button.classList?.add('d-none');
  //   });
  //
  //   document
  //     .querySelectorAll(`[id^="waveform-play-btn-"]:not([id*='-${existingPlayerIdentifier}'])`)
  //     .forEach((wfb) => {
  //       wfb?.classList?.remove('d-none');
  //     });
  //
  //   document
  //     .querySelectorAll(`[id^="waveform-stop-btn-"]:not([id*='-${existingPlayerIdentifier}'])`)
  //     .forEach((wfb) => {
  //       wfb?.classList?.add('d-none');
  //     });
  // }

  showVoiceOptions(event) {
    event?.preventDefault();
    this.voiceOptionsShown = true;
    localStorage.setItem('audio_flyout_tab', 'voice_options');
    if (this.hasVoiceOptionsTarget) this.voiceOptionsTarget.classList.remove('d-none');
    if (this.hasVoiceOptionsButtonTarget) this.voiceOptionsButtonTarget.classList.add('active');
    this.hideHistories();
  }

  showHistories(event) {
    event?.preventDefault();
    this.historiesShown = true;
    localStorage.setItem('audio_flyout_tab', 'histories');
    if (this.hasHistoriesTarget) this.historiesTarget.classList.remove('d-none');
    if (this.hasHistoriesButtonTarget) this.historiesButtonTarget.classList.add('active');
    this.hideVoiceOptions();
  }

  hideVoiceOptions() {
    this.voiceOptionsShown = false;
    if (this.hasVoiceOptionsTarget) this.voiceOptionsTarget.classList.add('d-none');
    if (this.hasVoiceOptionsButtonTarget) this.voiceOptionsButtonTarget.classList.remove('active');
  }

  hideHistories() {
    this.historiesShown = false;
    if (this.hasHistoriesTarget) this.historiesTarget.classList.add('d-none');
    if (this.hasHistoriesButtonTarget) this.historiesButtonTarget.classList.remove('active');
  }

  hideTextToSpeechOption() {
    this.textToSpeechOptionShown = false;
    if (this.hasTextToSpeechOptionTarget) this.textToSpeechOptionTarget.classList.add('d-none');
  }

  hideSpeechToSpeechOption() {
    this.speechToSpeechOptionShown = false;
    if (this.hasSpeechToSpeechOptionTarget) this.speechToSpeechOptionTarget.classList.add('d-none');
  }

  showTextToSpeechOption(event) {
    event.preventDefault();
    post('/ai/audio/update_speech_option', {
      body: { speech_option: 'text_to_speech' },
      responseKind: 'turbo-stream'
    });
    this.textToSpeechOptionShown = true;
    if (this.hasTextToSpeechOptionTarget) this.textToSpeechOptionTarget.classList.remove('d-none');
    this.hideSpeechToSpeechOption();
  }

  showSpeechToSpeechOption(event) {
    event.preventDefault();
    post('/ai/audio/update_speech_option', {
      body: { speech_option: 'speech_to_speech' },
      responseKind: 'turbo-stream'
    });
    this.speechToSpeechOptionShown = true;
    if (this.hasSpeechToSpeechOptionTarget)
      this.speechToSpeechOptionTarget.classList.remove('d-none');
    this.hideTextToSpeechOption();
  }

  updateSpeakerDetails() {
    if (this.speakerTarget.value) {
      const speaker = JSON.parse(this.speakerTarget.value);
      post('/ai/audio/selected_speaker', {
        body: { preview_url: speaker['preview_url'], voice_id: speaker['voice_id'] },
        responseKind: 'turbo-stream'
      });
    }
  }

  checkSelectedModel() {
    if (this.hasVoiceModelTarget && this.voiceModelTarget.value) {
      post('/ai/audio/selected_model', {
        body: { model_id: this.voiceModelTarget.value },
        responseKind: 'turbo-stream'
      });
    }
  }

  onScriptInputChange(event) {
    const value = event.currentTarget.value;
    localStorage.setItem('last_audio_script', value);
  }

  deleteAudio(event) {
    event.preventDefault();
    if (this.hasDeleteAudioTarget) {
      const audioId = this.deleteAudioTarget?.dataset?.audioId;

      post('/ai/audio/delete_audio_history', {
        body: { audio_id: audioId },
        responseKind: 'turbo-stream'
      });
    }
  }

  selectVoice() {
    let voiceData = '';
    let voiceId = '';

    if (this.hasSelectedVoiceTarget) {
      voiceData = this.selectedVoiceTarget?.dataset?.voiceData;
      voiceId = this.selectedVoiceTarget?.dataset?.voiceId || '';

      post('/ai/audio/update_selected_voice', {
        body: { voice: voiceData, voice_id: voiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  moveToVerifyVoice(event) {
    event.preventDefault();
    if (this.hasMoveToVerifyVoiceButtonTarget) {
      const voiceId = this.moveToVerifyVoiceButtonTarget?.dataset?.voiceId || '';

      post('/ai/audio/show_verify_voice_step', {
        body: { voice_id: voiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  moveToDescribeVoice(event) {
    event.preventDefault();
    if (this.hasMoveToDescribeVoiceButtonTarget) {
      const voiceId = this.moveToDescribeVoiceButtonTarget?.dataset?.voiceId || '';

      post('/ai/audio/show_describe_voice_step', {
        body: { voice_id: voiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  deleteVoice(event) {
    event.preventDefault();
    if (this.hasDeleteVoiceTarget) {
      const voiceId = this.deleteVoiceTarget?.dataset?.voiceId;

      post('/ai/audio/delete_voice', {
        body: { voice_id: voiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  shareVoice(event) {
    event.preventDefault();
    if (this.hasShareVoiceTarget && this.hasVoiceRecipientInputTarget) {
      const voiceId = this.shareVoiceTarget?.dataset?.voiceId;
      const recipientEmail = this.voiceRecipientInputTarget.value;

      if (recipientEmail.length < 1) {
        this.shareVoiceTarget.setAttribute('disabled', true);
      } else {
        this.shareVoiceTarget.removeAttribute('disabled');
      }

      post('/ai/audio/share_voice', {
        body: { voice_id: voiceId, recipient_email: recipientEmail },
        responseKind: 'turbo-stream'
      });
    }
  }

  cancelSharedVoiceAction(event) {
    event.preventDefault();
    if (this.hasCancelSharedVoiceActionTarget) {
      const sharedVoiceId = this.cancelSharedVoiceActionTarget?.dataset?.sharedVoiceId;

      post('/ai/audio/cancel_shared_voice_action', {
        body: { shared_voice_id: sharedVoiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  confirmDeleteSharedVoice(event) {
    event.preventDefault();
    if (this.hasConfirmDeleteSharedVoiceTarget) {
      const sharedVoiceId = this.confirmDeleteSharedVoiceTarget?.dataset?.sharedVoiceId;

      post('/ai/audio/confirm_delete_shared_voice', {
        body: { shared_voice_id: sharedVoiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  deleteSharedVoice(event) {
    event.preventDefault();
    if (this.hasDeleteSharedVoiceTarget) {
      const sharedVoiceId = this.deleteSharedVoiceTarget?.dataset?.sharedVoiceId;

      post('/ai/audio/delete_shared_voice', {
        body: { shared_voice_id: sharedVoiceId },
        responseKind: 'turbo-stream'
      });
    }
  }

  addVoiceToList() {
    let voiceId = '';
    let referenceId = '';
    let ownerId = '';
    let speakerName = '';
    let category = '';
    let isAdmin = '';

    if (this.hasAddVoiceToListTarget) {
      voiceId = this.addVoiceToListTarget?.dataset?.voiceId || '';
      referenceId = this.addVoiceToListTarget?.dataset?.referenceId || '';
      ownerId = this.addVoiceToListTarget?.dataset?.ownerId || '';
      speakerName = this.addVoiceToListTarget?.dataset?.speakerName || '';
      category = this.addVoiceToListTarget?.dataset?.category || '';
      isAdmin = this.addVoiceToListTarget?.dataset?.isAdmin || '';
    }

    if (isAdmin === 'true') {
      post('/admin/ai/add_shared_voice_to_list', {
        body: {
          reference_id: referenceId,
          speaker_name: speakerName,
          owner_id: ownerId,
          voice_id: voiceId,
          category
        },
        responseKind: 'turbo-stream'
      });
    } else {
      post('/ai/audio/update_in_use_voice', {
        body: {
          voice_id: voiceId,
          reference_id: referenceId,
          category,
          action_type: 'add_to_list'
        },
        responseKind: 'turbo-stream'
      });
    }
  }

  removeVoiceFromList() {
    let referenceId = '';
    let voiceId = '';
    let ownerId = '';
    let speakerName = '';
    let category = '';
    let isAdmin = '';

    if (this.hasRemoveVoiceFromListTarget) {
      voiceId = this.removeVoiceFromListTarget?.dataset?.voiceId || '';
      referenceId = this.removeVoiceFromListTarget?.dataset?.referenceId || '';
      ownerId = this.removeVoiceFromListTarget?.dataset?.ownerId || '';
      speakerName = this.removeVoiceFromListTarget?.dataset?.speakerName || '';
      category = this.removeVoiceFromListTarget?.dataset?.category || '';
      isAdmin = this.removeVoiceFromListTarget?.dataset?.isAdmin || '';
    }

    if (isAdmin === 'true') {
      post('/admin/ai/remove_shared_voice_from_list', {
        body: {
          reference_id: referenceId,
          speaker_name: speakerName,
          owner_id: ownerId,
          voice_id: voiceId,
          category
        },
        responseKind: 'turbo-stream'
      });
    } else {
      post('/ai/audio/update_in_use_voice', {
        body: {
          voice_id: voiceId,
          reference_id: referenceId,
          category,
          action_type: 'remove_from_list'
        },
        responseKind: 'turbo-stream'
      });
    }
  }

  createWaveSurfer(container, audioUrl) {
    if (this.hasCreatedWaveSurfer) return;
    this.hasCreatedWaveSurfer = true;

    const waveSurfer = WaveSurfer.create({
      container: `#${container}`,
      waveColor: '#ED2276',
      progressColor: '#CB115E',
      url: audioUrl,
      barWidth: 2,
      barGap: 1,
      height: 25
    });

    const existingPlayerIdentifier = container.split('-').pop();

    // waveSurfer.on('ready', () => {
    //   document
    //     .getElementById(`waveform-play-btn-${existingPlayerIdentifier}`)
    //     ?.classList?.add('d-none');
    //   document
    //     .getElementById(`waveform-stop-btn-${existingPlayerIdentifier}`)
    //     ?.classList?.remove('d-none');
    //   waveSurfer.play();
    // });

    waveSurfer.on('timeupdate', () => {
      if (this.hasTimestampTarget) {
        this.timestampTarget.textContent = `${this.toHHMMSS(
          waveSurfer.getCurrentTime()
        )} / ${this.toHHMMSS(waveSurfer.getDuration())}`;
      }
    });

    waveSurfer.on('interaction', () => {
      waveSurfer.playPause();
    });

    waveSurfer.on('finish', () => {
      if (this.hasTimestampTarget) {
        this.timestampTarget.textContent = `00:00:00 / ${this.toHHMMSS(waveSurfer.getDuration())}`;
      }
      document
        .getElementById(`waveform-play-btn-${existingPlayerIdentifier}`)
        ?.classList?.remove('d-none');
      document
        .getElementById(`waveform-stop-btn-${existingPlayerIdentifier}`)
        ?.classList?.add('d-none');
      // waveSurfer.destroy();
    });
  }

  playWS() {
    emit('stopAllAudioPlayers');

    const containerName = this.waveformContainerValue;
    const existingPlayerIdentifier = containerName.split('-').pop();

    let getWaveSurferAudioEl = this.getWaveSurferAudioEl();
    if (getWaveSurferAudioEl) {
      document
        .querySelector(`#waveform-play-btn-${existingPlayerIdentifier}`)
        ?.classList?.add('d-none');
      document
        .querySelector(`#waveform-stop-btn-${existingPlayerIdentifier}`)
        ?.classList?.remove('d-none');
      getWaveSurferAudioEl.play();
    }
  }

  getWaveSurferAudioEl() {
    const containerName = this.waveformContainerValue;

    return document
      .querySelector(`#${containerName}`)
      ?.querySelector('div')
      ?.shadowRoot?.querySelector('audio');
  }

  stopWS() {
    const containerName = this.waveformContainerValue;
    if (!containerName) return;
    const existingPlayerIdentifier = containerName.split('-').pop();
    document
      .getElementById(`waveform-play-btn-${existingPlayerIdentifier}`)
      ?.classList.remove('d-none');
    document
      .getElementById(`waveform-stop-btn-${existingPlayerIdentifier}`)
      ?.classList.add('d-none');

    document
      .querySelector(`#${containerName}`)
      ?.querySelector('div')
      ?.shadowRoot?.querySelector('audio')
      ?.pause();
  }

  toHHMMSS(numSecs) {
    let secNum = parseInt(numSecs, 10);
    let hours = Math.floor(secNum / 3600)
      .toString()
      .padStart(2, '0');
    let minutes = Math.floor((secNum - hours * 3600) / 60)
      .toString()
      .padStart(2, '0');
    let seconds = secNum - hours * 3600 - (minutes * 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  onSelectedLanguage() {
    const isAdmin = this.selectLanguageTarget?.dataset?.isAdmin || '';
    if (this.selectLanguageTarget.value) {
      const selected_language = this.selectLanguageTarget.value;
      post('/ai/audio/update_voice_accent', {
        body: { selected_language, is_admin: isAdmin },
        responseKind: 'turbo-stream'
      });
    }
  }

  resetVoiceSettings() {
    if (this.hasResetVoiceSettingsTarget) {
      post('/ai/audio/reset_voice_settings', {
        responseKind: 'turbo-stream'
      });
    }
  }

  toggleVoiceAsDefault() {
    let referenceId = '';
    let voiceId = '';
    let ownerId = '';
    let speakerName = '';
    let category = '';

    if (this.hasToggleVoiceAsDefaultTarget) {
      voiceId = this.toggleVoiceAsDefaultTarget?.dataset?.voiceId || '';
      referenceId = this.toggleVoiceAsDefaultTarget?.dataset?.referenceId || '';
      ownerId = this.toggleVoiceAsDefaultTarget?.dataset?.ownerId || '';
      speakerName = this.toggleVoiceAsDefaultTarget?.dataset?.speakerName || '';
      category = this.toggleVoiceAsDefaultTarget?.dataset?.category || '';
    }

    post('/admin/ai/toggle_voice_as_default', {
      body: {
        reference_id: referenceId,
        speaker_name: speakerName,
        owner_id: ownerId,
        voice_id: voiceId,
        category
      },
      responseKind: 'turbo-stream'
    });
  }
}
