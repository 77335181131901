import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['email', 'button'];
  connect() {
    if (this.targets.has('button') && this.targets.has('email')) {
      this.validateForm();
    }
  }

  validateForm() {
    let status = false;
    if (this.validateEmail()) {
      status = true;
      this.buttonTarget.removeAttribute('disabled');
    } else {
      this.buttonTarget.setAttribute('disabled', true);
    }
    return status;
  }

  validateEmail() {
    const value = this.emailTarget.value;

    let requirements = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return requirements.test(value);
  }
}
