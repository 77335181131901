import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="ai-auto-generate"
export default class extends Controller {
  static values = {
    autoGenerate: Boolean
  };
  connect() {
    if (this.autoGenerateValue) {
      document.querySelector('#ai-generate-button-control-btn').click();
    }
  }
}
