import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    className: String,
    breakpoint: Number,
    maxWidth: Number
  };

  onResizePointer = null;
  timeoutPointer = null;
  isMaxWidth = false;

  connect() {
    this.onResizePointer = () => this.onResize();

    window.addEventListener('resize', this.onResizePointer, { passive: true });
    this.onResize();

    this.timeoutPointer = setTimeout(() => {
      this.element.classList.remove('hide-transition');
    }, 1);
  }
  disconnect() {
    window.removeEventListener('resize', this.onResizePointer);
    clearTimeout(this.timeoutPointer);
  }
  onResize() {
    if (this.maxWidthValue && window.innerWidth > this.maxWidthValue) {
      this.element.classList.remove(this.classNameValue);
      this.element.classList.remove('hide-transition');
      this.isMaxWidth = true;
    } else if (this.maxWidthValue && window.innerWidth <= this.maxWidthValue && this.isMaxWidth) {
      this.element.classList.add(this.classNameValue);
      this.isMaxWidth = false;
    } else if (window.innerWidth <= this.breakpointValue) {
      this.element.classList.add(this.classNameValue);
      this.isMaxWidth = false;
    }
  }
}
