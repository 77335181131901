import { Controller } from '@hotwired/stimulus';
import { isMobileDevice, isSafari } from '../helpers';

function removeAll(els){
  els.forEach(function(el) {
    el.remove();
  });
}

// Remove els from the DOM based on some generic conditions, like whether the browser is Safari/mobile/desktop
export default class extends Controller {
  static targets = ['notMobile','notDesktop','notSafari','notSafariNotMobile','notSafariNotDesktop'];

  connect() {
    if(isSafari()){
      removeAll(this.notSafariTargets);
      removeAll(this.notSafariNotMobileTargets);
      removeAll(this.notSafariNotDesktopTargets);
    }

    if(isMobileDevice()){
      removeAll(this.notMobileTargets);
      removeAll(this.notSafariNotMobileTargets);
    }else{
      removeAll(this.notDesktopTargets);
      removeAll(this.notSafariNotDesktopTargets);
    }
  }

}
