import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="announcement-form-ai-modal"
// This modal should show once per user login (per device)
// If user checks they don't want to see it again, it shouldn't show up again on this device at all (unless they clear local storage)
export default class extends Controller {
  static values = {
    lastLogin: String,
    currentUserUsedAi: Boolean
  };
  connect() {
    this.modelEl = document.getElementById('announcement-form-ai');
    this.modal = this.modelEl ? new Modal(this.modelEl) : null;

    if (this.modal && this.shouldAutoShow()) {
      this.showModal();
      this.setHideUntilNextLogin();
    }
  }

  isOnAIPath() {
    return location.pathname.startsWith('/ai');
  }

  shouldAutoShow() {
    if (!this.lastLoginValue) {
      return false;
    }

    // Don't announce AI if user has already used it
    if (this.currentUserUsedAiValue) {
      return false;
    }

    if (!window.localStorage) {
      // if local storage isn't available, show it based on whether they're on the AI path
      return !this.isOnAIPath();
    }

    // Don't announce AI if user is on AI path (and also don't show it when user navigates away from AI unless they also log in again)
    if (this.isOnAIPath()) {
      this.setHideUntilNextLogin();
      return false;
    }

    let storageValue = localStorage.getItem('hide-announcement-form-ai');
    if (!storageValue) {
      return true;
    }

    let [key, value] = storageValue.split(':');
    if (key === 'until-next-login') {
      return value !== this.lastLoginValue;
    } else if (key === 'always') {
      return false;
    }
  }

  showModal() {
    this.modal.show();
  }

  setHideUntilNextLogin() {
    localStorage.setItem('hide-announcement-form-ai', 'until-next-login:' + this.lastLoginValue);
  }

  toggleDontShowAgain(event) {
    const checked = event.target.checked;
    if (checked) {
      localStorage.setItem('hide-announcement-form-ai', 'always');
    } else {
      this.setHideUntilNextLogin();
    }
  }
}
