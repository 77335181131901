import { Controller } from '@hotwired/stimulus';
import { mount, unmount } from 'svelte';
// import { render } from 'svelte/server';
import pageState from '~/services/pageState.svelte';

const COMPONENT_DEFINITIONS = import.meta.glob('~/components/**/*.svelte', { import: 'default', eager: true });

let elToComponentMap = new Map();

function addPageStateFromEl(){
  let el = document.querySelector('[data-js-page-state]');
  if(el?.dataset?.jsPageState){
    pageState.addState(JSON.parse(el.dataset.jsPageState));
    delete el.dataset.jsPageState;
  }
}

function mountSvelte(){
  addPageStateFromEl();

  const els = document.querySelectorAll('[data-controller="svelte-component"]');
  els.forEach((el) => {
    mountComponent(el);
  });
  // CSR = Client Side Render - now that we've mounted all the JS components, we can show the page content
  document.body.classList.remove('hide-until-csr-complete');
}

function mountComponent(el){
  if(elToComponentMap.has(el) || !el.dataset.component) return;

  let props = {};
  if(el.dataset.props){
    props = JSON.parse(el.dataset.props);
  }

  let componentName = el.dataset.component;
  let ComponentDef = COMPONENT_DEFINITIONS[`/components/${componentName}.svelte`];
  if(!ComponentDef){
    throw new Error(`Component not found: ${componentName}`);
  }

  let component = mount(ComponentDef, {
    target: el,
    props
  });
  elToComponentMap.set(el, component);
}

// mountSvelte();
mountSvelte();

document.addEventListener('turbo:load', function(){
  mountSvelte();
});

// Connects to data-controller="svelte-component"
export default class extends Controller {
  connect() {
    // PageState may not have loaded yet (when soft navigating, this Stimulus controller runs connect() before 'turbo:load', for example)
    addPageStateFromEl();
    // It may be a component that needs to be mounted
    mountComponent(this.element);
  }

  disconnect() {
    let component = elToComponentMap.get(this.element);
    if(component){
      unmount(component);
      elToComponentMap.delete(this.element);
    }
  }
}
