async function setup() {
  if (!navigator.serviceWorker) {
    return;
  }

  unregisterServiceWorkers();

  // if (navigator.serviceWorker.controller) {
  //   // If the service worker is already running, skip to state change
  //   onStateChange();
  // } else {
  //   // Register the service worker, and wait for it to become active
  //   await navigator.serviceWorker.register('/service-worker.js', { scope: './' });
  //   navigator.serviceWorker.addEventListener('controllerchange', onControllerChange);
  // }
}

function unregisterServiceWorkers(){
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}

// function onControllerChange() {
//   navigator.serviceWorker.controller.addEventListener('statechange', onStateChange);
// }

// function onStateChange() {
//   // perform any visual manipulations here
// }

setup();

