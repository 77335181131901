import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['password'];
  passwordChecklist = [
    {
      status: false,
      name: '10_chars'
    },
    {
      status: false,
      name: 'low_case'
    },
    {
      status: false,
      name: 'up_case'
    },
    {
      status: false,
      name: 'num'
    },
    {
      status: false,
      name: 'special_char'
    }
  ];
  strengthColor = {
    0: 'none',
    0.2: 'rgb(237, 140, 112)',
    0.4: 'rgb(235, 188, 108)',
    0.6: 'rgb(198, 209, 115)',
    0.8: 'rgb(126, 204, 131)',
    1: 'rgb(55, 200, 147)'
  };
  populateChecks() {
    this.passwordChecklist.forEach(ele => {
      const { name, status } = ele;
      const element = document.getElementById(`${name}`);
      this.toggleIndicatorColor(element, status);
    });
  }
  initialize() {
    this.populateChecks();
  }
  connect() {
    this.password = this.passwordTarget;
    this.password.addEventListener('keyup', this.validatePassword.bind(this));
    this.validatePassword();
  }

  toggleIndicatorColor(element, status) {
    if (status) {
      element.classList.remove('invalid-check');
      element.classList.add('valid-check');
    } else {
      element.classList.add('invalid-check');
      element.classList.remove('valid-check');
    }
  }

  validatePassword() {
    const password = this.password;
    const { strengthClass } = this.isPasswordGood(password.value);
    document.getElementById('password-progress-bar').style.width = `${strengthClass * 100}%`;
    document.getElementById('password-progress-bar').style.background = this.strengthColor[
      strengthClass
    ];
    this.password.setAttribute('data-password-status', strengthClass);
  }

  isPasswordGood(password) {
    let complexityReq = [
      // minimum of 10 characters
      { regEx: '.{10,}', translation: '10_chars' },
      // 1 lowercase letter
      { regEx: '[a-z]', translation: 'low_case' },
      // 1 uppercase letter
      { regEx: '[A-Z]', translation: 'up_case' },
      // 1 number
      { regEx: '[0-9]', translation: 'num' },
      // 1 special character based on the recommendation from:
      // https://owasp.org/www-community/password-special-characters
      {
        regEx: '[!"#$%&\'()*+,\\-./:;<=>?\\\\@\\[\\]^_`{|}~]',
        translation: 'special_char'
      }
    ];

    const complexityStrengthToClassMapping = {
      0: 0,
      1: 0.2,
      2: 0.4,
      3: 0.6,
      4: 0.8,
      5: 1
    };

    let checkList = this.getComplexityCheckList(password, complexityReq);
    let strength = this.getComplexityStrength(checkList);
    let strengthClass = complexityStrengthToClassMapping[strength];

    return {
      strengthClass
    };
  }

  getComplexityCheckList(password, complexityReq) {
    return complexityReq.map(req => {
      let { regEx, translation } = req;

      this.passwordChecklist.find((ele, index) => {
        if (ele.name === translation) {
          this.passwordChecklist[index].status = this.passwordRegCheck(password, regEx);

          const element = document.getElementById(`${translation}`);
          this.toggleIndicatorColor(element, this.passwordChecklist[index].status);
        }
      });

      return {
        success: this.passwordRegCheck(password, regEx)
      };
    });
  }

  passwordRegCheck(password, reg) {
    if (!password || !reg) {
      return false;
    }

    let check = new RegExp(`(?=${reg})`, 'g');
    return check.test(password);
  }

  getComplexityStrength(complexityCheckList) {
    return complexityCheckList.filter(req => req.success).length;
  }
}
