import { Controller } from '@hotwired/stimulus';

/**
 * This is a hotwire controller that is coupled with an ImageUpoader::Component rails view component
 */

export default class extends Controller {
  connect() {
    this.uploadedFor = this.element.getAttribute('data-uploaded-for');
    this.holder = this.element.closest(`.${this.uploadedFor}-holder`);
    this.onchangeImage();
  }

  onchangeImage() {
    this.element.addEventListener('change', this.handleChange.bind(this));
  }

  handleChange(event) {
    event.preventDefault();
    this.element.submit();
    if (this.holder) {
      this.holder.innerHTML = ''; //remove the content of the image holder for the shimmer below to above
      this.holder.classList.add('shimmer'); //add the shimmer loading class to the image holder
    }
  }
}
