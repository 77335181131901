import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="listing-category-change"
export default class extends Controller {
  static targets = ['select', 'form', 'formInput'];

  handleCategoryChange() {
    this.formInputTarget.value = this.selectTarget.value;
    this.formTarget.requestSubmit();
  }
}
