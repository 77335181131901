import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="listing"
export default class extends Controller {
  static targets = ['title', 'loaderTemplate'];

  show(event) {
    event.preventDefault();
    let url = event.currentTarget.dataset.url;
    this.element.parentNode.querySelector('.details-body').innerHTML =
      this.loaderTemplateTarget.innerHTML;
    this.loadTemplate(url);
    this.titleTarget.innerText = event.currentTarget.dataset.title;
  }

  loadTemplate(url) {
    get(url, {
      responseKind: 'turbo-stream'
    });
  }
}
