import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'optionId',
    'dealTitle',
    'optionTitle',
    'dealRevisions',
    'dealLicensing',
    'dealDeliveryDays',
    'dealAmount',
    'optionDeliveryDays',
    'optionAmount',
    'cart'
  ];

  connect() {
    if (this.hasCart) {
      this._updateTotalAmount();
      this._updateTotalDeliveryDays();
    }
  }

  get hasCart() {
    let carts = document.querySelectorAll('.order-cart');
    return carts.length >= 1;
  }

  selectedDeal(event) {
    if (!this.hasCart) {
      return;
    }

    let selectedDealId = event.currentTarget.dataset.dealId;
    this._updateListingDealId(selectedDealId);
    this._updateSelectedDealTitle();
    this._updateSelectedDealDays();
    this._updateSelectedDealRevisions();
    this._updateSelectedDealLicense();
    this._updateSelectedDealAmount();
    this._updateTotalAmount();
    this._updateTotalDeliveryDays();
  }

  selectedOption(event) {
    if (!this.hasCartTarget) {
      return;
    }

    let option_id = this.optionIdTarget.querySelector('.hidden_option_id').value;
    event.target.classList.add(`available_option_${option_id}`);
    if (event.currentTarget.checked) {
      this._addToListingOptionIdsValue(option_id);
      this._cloneDiv();
      this._updateSelectedOptionTitle(`option_${option_id}`);
      this._updateSelectedOptionDays(`option_${option_id}`);
      this._updateSelectedOptionAmount(`option_${option_id}`);
    }
    if (event.currentTarget.checked == false) {
      this._removeFromlistingOptionIdsValue(option_id);
      let elements = Array.from(document.getElementsByClassName(`option_${option_id}`));
      elements.forEach((element) => {
        element.replaceWith('');
      });
    }
    this._optionHeader();
    this._updateTotalAmount();
    this._updateTotalDeliveryDays();
  }

  _addToListingOptionIdsValue(option_id) {
    let elements = this._getElements('listing_option_ids');
    elements.forEach((element) => {
      element.value += `${option_id},`;
    });
  }

  _removeFromlistingOptionIdsValue(option_id) {
    let elements = this._getElements('listing_option_ids');
    elements.forEach((element) => {
      element.value = element.value.replace(`${option_id},`, '');
    });
  }

  _optionHeader() {
    if (
      document.getElementsByClassName('cart-container')[0].querySelectorAll('.option_details')
        .length > 1
    ) {
      this._showHeader();
    } else {
      this._hideHeader();
    }
  }

  _showHeader() {
    let elements = Array.from(document.getElementsByClassName('options_header'));
    elements.forEach((element) => {
      element.style.display = 'block';
    });
  }

  _hideHeader() {
    let elements = Array.from(document.getElementsByClassName('options_header'));
    elements.forEach((element) => {
      element.style.display = 'none';
    });
  }

  removeOption(event) {
    event.preventDefault();
    let ele = event.currentTarget.parentNode.parentNode.parentNode.parentNode;
    let class_name = ele.querySelector('[name="connected_class"]').value;
    let checkbox_ele = document.querySelector(`.${class_name}`);
    checkbox_ele.checked = false;
    ele.replaceWith('');
    this._optionHeader();
    this._updateTotalAmount();
    this._updateTotalDeliveryDays();
  }

  _cloneDiv() {
    let elements = document
      .getElementsByClassName('cart-container')[0]
      .querySelectorAll('.option_details');
    let option_id = this.optionIdTarget.querySelector('.hidden_option_id').value;
    elements.forEach((element) => {
      if (element.className == 'row gy-2 option_details') {
        let clone = element.cloneNode(true);
        clone.classList.add(`option_${option_id}`);
        clone.style.display = 'block';
        clone.appendChild(this._createHiddenField(`available_option_${option_id}`));
        element.parentNode.appendChild(clone);
      }
    });
  }

  _createHiddenField(value) {
    let hiddenElement = document.createElement('input');
    hiddenElement.setAttribute('type', 'hidden');
    hiddenElement.setAttribute('name', 'connected_class');
    hiddenElement.setAttribute('value', value);
    return hiddenElement;
  }

  _updateTotalAmount() {
    let amounts = this._getElements('total_cart_amount');

    let deal_amount = document.querySelector('.selected_deal_amount').textContent;
    let option_amounts = document
      .getElementsByClassName('cart-container')[0]
      .querySelectorAll('.selected_option_amount');
    let option_total = 0;
    option_amounts.forEach((option_amount) => {
      option_total += Number(option_amount.textContent.replace(/[^0-9.-]+/g, ''));
    });
    let total = Number(deal_amount.replace(/[^0-9.-]+/g, '')) + option_total;
    total = isNaN(total) ? '--' : total;
    amounts.forEach((element) => {
      element.textContent = total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    });
  }

  _updateListingDealId(selectedDealId) {
    let elements = this._getElements('listing_deal_id');

    elements.forEach((element) => {
      element.value = selectedDealId;
    });
  }

  _updateTotalDeliveryDays() {
    let days = this._getElements('total_days_delivery');
    let deal_days = document.querySelector('.selected_deal_days_delivery').textContent;
    let option_days = document
      .getElementsByClassName('cart-container')[0]
      .querySelectorAll('.selected_option_additional_days');
    let option_days_total = 0;
    option_days.forEach((option_day) => {
      let days = isNaN(option_day.textContent) ? 0 : parseInt(option_day.textContent);
      option_days_total += days;
    });
    let total = parseInt(deal_days) + option_days_total;
    days.forEach((element) => {
      element.textContent = total;
    });
  }

  _updateSelectedOptionTitle(class_name) {
    let elements = this._getElements(class_name);
    elements.forEach((element) => {
      element.querySelector('.option_title').textContent = this.optionTitleTarget.textContent;
    });
  }

  _updateSelectedOptionDays(class_name) {
    let elements = this._getElements(class_name);
    elements.forEach((element) => {
      element.querySelector('.selected_option_additional_days').textContent =
        this.optionDeliveryDaysTarget.textContent;
    });
  }

  _updateSelectedOptionAmount(class_name) {
    let elements = this._getElements(class_name);
    elements.forEach((element) => {
      element.querySelector('.selected_option_amount').textContent =
        this.optionAmountTarget.textContent;
    });
  }

  _updateSelectedDealTitle() {
    let elements = this._getElements('deal_title');
    elements.forEach((element) => {
      element.textContent = this.dealTitleTarget.textContent;
    });
  }

  _updateSelectedDealDays() {
    let elements = this._getElements('selected_deal_days_delivery');
    elements.forEach((element) => {
      element.textContent = this.dealDeliveryDaysTarget.textContent;
    });
  }

  _updateSelectedDealRevisions() {
    let elements = this._getElements('selected_deal_revisions');
    elements.forEach((element) => {
      element.textContent = this.dealRevisionsTarget.textContent;
    });
  }

  _updateSelectedDealLicense() {
    let elements = this._getElements('selected_deal_licensing');
    elements.forEach((element) => {
      element.textContent = this.dealLicensingTarget.textContent;
    });
  }

  _updateSelectedDealAmount() {
    let elements = this._getElements('selected_deal_amount');
    elements.forEach((element) => {
      element.textContent = this.dealAmountTarget.textContent;
    });
  }

  _getElements(class_name) {
    let elements = Array.from(document.getElementsByClassName(class_name));
    return elements;
  }
}
