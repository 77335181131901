import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';
import { searchParamsHelper } from '../helpers';
export default class extends Controller {
  static targets = ['main', 'output', 'min', 'max', 'clear'];
  static values = {
    onRangeCloseSubmitPath: String,
    isRange: false
  };

  startDate = null;
  endDate = null;

  connect() {
    let initialTimestampSec = this.hasOutputTarget ? this.outputTarget.value : null;

    let options = JSON.parse(this.mainTarget.dataset.options);
    Object.assign(options, {
      defaultDate: initialTimestampSec ? initialTimestampSec * 1000 : null, // JS likes MS

      onChange: (selectedDates) => {
        if (selectedDates.length && this.hasClearTarget) {
          this.clearTarget.classList.remove('d-none');
        } else if (this.hasClearTarget) {
          this.clearTarget.classList.add('d-none');
        }

        if (this.hasOutputTarget) {
          // Back to Sec for Rails
          this.outputTarget.value = Math.round(+selectedDates[0] / 1000);
        }

        if (this.hasMaxTarget && this.hasMinTarget) {
          this.maxTarget.value = Math.round(+selectedDates[1] / 1000);
          this.minTarget.value = Math.round(+selectedDates[0] / 1000);
        }
      }
    });

    if (this.defaultRangeFromSearchParams.length && this.hasClearTarget) {
      this.clearTarget.classList.remove('d-none');
    }

    if (this.isRangeValue && this.hasOnRangeCloseSubmitPathValue) {
      options.defaultDate = this.defaultRangeFromSearchParams;
      options.onClose = (selectedDates) => {
        // apply any existing filters to query
        let filters = searchParamsHelper.filtersFromParams();
        // does the date range picker appear on a page that shows subscription data
        // if it does - are we also giving the option to toggle between stripe and apple sub data
        // then we expect a hidden input with the following name - if it's checked then we should persist it on updating the date ranges
        let apple_subs = document.querySelector('[name="service_provider_apple"]');
        let body = {
          filters,
          date: {
            to: Math.round(+selectedDates[1] / 1000),
            since: Math.round(+selectedDates[0] / 1000)
          }
        };

        this.startDate = body.date.since;
        this.endDate = body.date.to;

        if (apple_subs) {
          body['service_provider_apple'] = apple_subs.checked;
        }
        put(this.onRangeCloseSubmitPathValue, {
          body: body,
          responseKind: 'turbo-stream'
        });
      };
    }

    this.flatpickr = window.flatpickr(this.mainTarget, options);
  }

  get defaultRangeFromSearchParams() {
    let searchParams = new URLSearchParams(window.location.search);
    let range = [];
    searchParams.forEach((val, key) => {
      if (key.includes('date')) {
        range.push(new Date(Number(val) * 1000).toISOString());
      }
    });

    return range.map((str) => str.split('T')[0]);
  }

  clear() {
    if (this.flatpickr) {
      this.flatpickr.clear();
    }
    if (this.isRangeValue && this.hasOnRangeCloseSubmitPathValue) {
      // cleared range
      let apple_subs = document.querySelector('[name="service_provider_apple"]');
      let body = {};
      if (apple_subs) {
        body['service_provider_apple'] = apple_subs.checked;
        return window.location.replace(
          `${this.onRangeCloseSubmitPathValue}?service_provider_apple=${apple_subs.checked}`
        );
      }
      window.location.replace(this.onRangeCloseSubmitPathValue);
    }
  }
}
