import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['recipient'];

  static values = {
    name: String,
    recipientMap: Object
  };

  // Toggles a class on itself
  toggle(event) {
    event.preventDefault();
    let el = this.element;

    if (this.hasRecipientTarget && !this.hasRecipientMapValue) {
      el = this.recipientTarget;
      el.classList.toggle(this.nameValue);
    } else if (this.hasRecipientMapValue) {
      let clicked = event.currentTarget;
      let recipientId = this.recipientMapValue[clicked.dataset.recipient]['element'];
      el = document.getElementById(recipientId);
      let klass = this.recipientMapValue[clicked.dataset.recipient]['class'];
      el.classList.toggle(klass);
      if (this.recipientMapValue['main']) {
        let mainElement = document.getElementById(this.recipientMapValue['main']['element']);
        mainElement.classList.toggle(klass);
      }
    }
  }
}
