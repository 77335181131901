import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'country',
    'state',
    'stateButton',
    'stateVisibility',
    'postalButton',
    'postalVisibility',
    'postalCode',
    'addCountry'
  ];

  connect() {
    if (this.countryTarget.value !== 'US' || this.countryTargets.length !== 1) {
      this.hideState();
      this.hidePostal();
      this.hideStateButton();
      this.hidePostalButton();
    } else if (this.stateTarget.value === '') {
      this.hideState();
      this.hidePostal();
      this.hidePostalButton();
    } else if (this.stateTarget.value !== '' && this.postalCodeTarget.value === '') {
      this.hideStateButton();
      this.hidePostal();
    } else {
      this.hideStateButton();
      this.hidePostalButton();
    }
  }

  hideState() {
    this.stateVisibilityTarget.hidden = true;
  }

  showState() {
    this.stateVisibilityTarget.hidden = false;
  }

  hidePostal() {
    this.postalVisibilityTarget.hidden = true;
  }

  showPostal() {
    this.postalVisibilityTarget.hidden = false;
  }

  changeCountry(param) {
    let countryVal = param.target.value;
    if (countryVal === 'US' && this.countryTargets.length === 1) {
      this.showStateButton();
    } else {
      this.hideState();
      this.hidePostal();
      this.hideStateButton();
      this.hidePostalButton();
    }
  }

  changeState() {
    this.showPostalButton();
  }

  addCountry() {
    post('/user/challenges/add_country', {
      body: {}
    });
    this.hideState();
    this.hidePostal();
    this.hideStateButton();
    this.hidePostalButton();
  }

  hideStateButton() {
    this.stateButtonTarget.hidden = true;
  }

  showStateButton() {
    this.stateButtonTarget.hidden = false;
  }

  addState() {
    this.hideStateButton();
    this.showState();
  }

  hidePostalButton() {
    this.postalButtonTarget.hidden = true;
  }

  showPostalButton() {
    this.postalButtonTarget.hidden = false;
  }

  addPostal() {
    this.hidePostalButton();
    this.showPostal();
  }
}
