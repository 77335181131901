import { Controller } from '@hotwired/stimulus';

// Assuming an autoplay video with no controls, this controller will pause the video when it's outside the viewport, and resume it when it's inside the viewport
// Connects to data-controller="pause-video-outside-viewport"
export default class extends Controller {

  pausedVideo = false;
  connect() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // if this controller has paused the video previously, play it (so not if it's just not loaded yet, etc)
          if(this.pausedVideo){
            this.element.play();
            this.pausedVideo = false;
          }
        }else{
          // If the video is currently playing, pause it
          if(!this.element.paused){
            this.element.pause();
            this.pausedVideo = true;
          }
        }
      });
    }, options);

    this.intersectionObserver.observe(this.element);
  }
}
