import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// TODO this controller should be renamed since it has very specific logic for user location
export default class extends Controller {
  static targets = ['select', 'postalcode', 'state', 'stateSelectParent'];
  static values = {
    modelId: Number,
    creatorLocationId: Number,
    stateUpdateUrl: String
  };
  showPostalFor = ['GB', 'US', 'CA'];
  showStateFor = ['US', 'CA'];

  connect() {
    if (this.hasPostalcodeTarget) {
      this.postalParent = this.postalcodeTarget.closest('.input');
      this.checkCountryForPostalCode(this.selectTarget.value);
    }
    if (this.hasStateSelectParentTarget) {
      this.checkCountryForState(this.selectTarget.value);
    }
  }

  checkCountryForPostalCode(value) {
    if (this.showPostalFor.includes(value)) {
      this.postalParent.style.display = 'block';
    } else {
      this.postalParent.style.display = 'none';
      // If somebody had entered a postalCode for a country where we support it, but then changed countries
      this.postalcodeTarget.value = '';
    }
  }

  checkCountryForState() {
    console.log('triggered');
    if (this.hasStateSelectParentTarget) {
      if (this.showStateFor.includes(this.selectTarget.value)) {
        post(this.stateUpdateUrlValue, {
          body: {
            country_code: this.selectTarget.value,
            creator_location_id: this.creatorLocationIdValue
          },
          responseKind: 'turbo-stream'
        }).then(() => {
          this.stateSelectParentTarget.style.display = 'block';
        });
      } else {
        this.stateSelectParentTarget.style.display = 'none';
      }
    }
  }
}
