import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="update-button-text"
export default class extends Controller {
  static targets = ['copyButton'];
  static values = {
    url: String,
  };

  call() {
    if(this.urlValue == '')
    {
      this.copyButtonTarget.innerHTML = this.data.get('updatedText');
    }
  }
}
