import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="enable-button-when-checkbox-checked"
export default class extends Controller {
  static targets = ['checkbox', 'button'];

  validate() {
    if(this.checkboxTarget.checked){
      this.buttonTarget.removeAttribute('disabled');
    }else{
      this.buttonTarget.setAttribute('disabled', 'disabled');
    }
  }
}
