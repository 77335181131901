import { Controller } from '@hotwired/stimulus';
import {  findElement } from '../helpers';

// Automatically submit the form with its submit button when any of its inputs change
export default class extends Controller {
  connect() {
    const inputs = this.element.querySelectorAll('input');
    if (inputs) {
      inputs.forEach(element => {
        element.addEventListener('change', () => {
          this.submitForm();
        });
      });
    }
  }

  submitForm(){
    // We can't just do this.element.submit(), because TurboStreams doesn't support it that way
    this.getSubmitButton().click();
  }

  getSubmitButton(){
    return findElement(this.element, 'button[type=submit]');
  }
}
