import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['savedPrompts', 'customOptions', 'savedPromptsButton', 'customOptionsButton'];

  connect() {
    this.toggleSavedPrompts();
  }

  toggleSavedPrompts() {
    if (this.savedPromptsButtonTarget.checked) {
      this.showSavedPrompts();
      this.hideCustomOptions();
    } else {
      this.hideSavedPrompts();
      this.showCustomOptions();
    }
  }

  showSavedPrompts() {
    this.savedPromptsTarget.classList.remove('d-none');
  }

  hideSavedPrompts() {
    this.savedPromptsTarget.classList.add('d-none');
  }

  showCustomOptions() {
    this.customOptionsTarget.classList.remove('d-none');
  }

  hideCustomOptions() {
    this.customOptionsTarget.classList.add('d-none');
  }

  optionChanged() {
    let selectedOption = event.currentTarget.value;
    if (selectedOption === 'custom_options') {
      this.customOptionsButtonTarget.checked = true;
      this.savedPromptsButtonTarget.checked = false;
      this.showCustomOptions();
      this.hideSavedPrompts();
    } else {
      this.customOptionsButtonTarget.checked = false;
      this.savedPromptsButtonTarget.checked = true;
      this.showSavedPrompts();
      this.hideCustomOptions();
    }
  }
}
