import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="paypal-modal"
// the modal will only be present if the user is signed in as creator and has pending earnings
// otherwise this controller should never connect to dom
export default class extends Controller {
  static values = {
    url: String,
    timeout: 180000
  };

  connect() {
    this.element.addEventListener('hidden.bs.modal', () => {
      localStorage.setItem('paypalModalDismissed', true);
      this.setTimeoutToOpen();
    });

    if (localStorage.getItem('paypalModalDismissed')) {
      this.setTimeoutToOpen();
    } else {
      this.open();
    }
  }

  open() {
    // if we are on the user preferences path don't show modal
    if (window.location.pathname === this.urlValue) {
      return;
    }

    let modal = new Modal(this.element);
    if (!modal.isOpened) {
      modal.show();
    }
  }

  redirect() {
    if (this.urlValue) {
      let modal = new Modal(this.element);
      modal.hide();
      window.location.href = this.urlValue;
    }
  }

  // if user dismisses the modal - ask them again in 180000ms (2min)
  setTimeoutToOpen() {
    setTimeout(this.open.bind(this), this.timeoutValue);
  }
}
