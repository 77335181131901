import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap';

export default class extends Controller {
  connect() {
    let offcanvas = new Offcanvas(this.element);
    this.element.addEventListener('turbo:before-stream-render', (event) => {
      if (event.target.target !== 'mixers_flyout_container') {
        offcanvas.hide();
      }
    });
    this.element.addEventListener('click', (event) => {
      if (
        event?.delegateTarget?.id == 'shared_mixer_no_plan_btn' ||
        event?.target?.dataset?.aiAudioTarget == 'selectedVoice'
      ) {
        offcanvas.hide();
      }
    });
  }
}
