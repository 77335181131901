import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="show-help-document"
export default class extends Controller {
  static values = {
    identifier: String
  };

  showHelpDoc(){
    post('/help_documents/show_offcanvas', {
      query: { identifier: this.identifierValue },
      responseKind: 'turbo-stream'
    });
  }
}
