import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="pitch-table-row"
export default class extends Controller {
  static targets = ['rowAction'];

  click(event) {
    const tagNames = ['A', 'INPUT', 'svg', 'path', 'a', 'input'];
    if (tagNames.includes(event.currentTarget.tagName)) {
      return;
    }

    this.rowActionTarget.click();
  }
}
