import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="class-animation-toggler"

// This toggles a button showing/hiding another element with a show/hide animation and then really hiding the el by having .d-none on it
// The button that toggles can have its text altered depending on whether it's shown or hidden
export default class extends Controller {
  static targets = ['recipient', 'button'];

  static values = {
    showAnimation: String,
    hideAnimation: String
  };

  isShown = false;

  connect() {
    // Understands whether it's initially shown by whether the d-none class exists on recipient el
    this.isShown =
      this.recipientTarget?.dataset?.recipientType === 'audio'
        ? !this.recipientTarget.classList.contains('line-clamp-2')
        : !this.recipientTarget.classList.contains('d-none');
    this.refreshLabel();

    this.recipientTarget.addEventListener('animationend', () => {
      if (!this.isShown) {
        if (this.recipientTarget.dataset?.recipientType === 'audio') {
          this.recipientTarget.classList.add('line-clamp-2');
        } else {
          this.recipientTarget.classList.add('d-none');
        }
      }
    });
  }

  refreshLabel() {
    if (this.hasButtonTarget) {
      // using classAnimationTogglerHideText instead of a stimulus name because it looks nicer to have the text of the button on the button rather than on the parent controller elsewhere
      let text = this.isShown
        ? this.buttonTarget.dataset.classAnimationTogglerHideText
        : this.buttonTarget.dataset.classAnimationTogglerShowText;
      if (!text) return;

      this.buttonTarget.innerText = text;
    }
  }

  toggle(event) {
    event.preventDefault();
    if (this.isShown) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.isShown = true;
    this.recipientTarget.classList.add('animated');
    this.recipientTarget.classList.add(this.showAnimationValue);
    this.recipientTarget.classList.remove(this.hideAnimationValue);
    if (this.recipientTarget.dataset?.recipientType === 'audio') {
      this.recipientTarget.classList.remove('line-clamp-2');
    } else {
      this.recipientTarget.classList.remove('d-none');
    }
    this.refreshLabel();
  }

  hide() {
    this.isShown = false;
    this.recipientTarget.classList.add('animated');
    if (this.recipientTarget.dataset?.recipientType === 'audio') {
      this.recipientTarget.classList.add('line-clamp-2');
    } else {
      this.recipientTarget.classList.add(this.hideAnimationValue);
      this.recipientTarget.classList.remove(this.showAnimationValue);
    }
    this.refreshLabel();
  }
}
