import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['otherReasonTextField', 'otherReason'];

  connect() {
    this.hideOtherReasonTextField();
  }

  showOtherReasonTextField() {
    this.otherReasonTextFieldTarget.classList.remove('d-none');
  }

  hideOtherReasonTextField() {
    this.otherReasonTextFieldTarget.classList.add('d-none');
  }
}
