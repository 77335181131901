import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['badge'];

  badgeTargetConnected() {
    setTimeout(this.fetchUnreadMessagesCount.bind(this), 500);
  }

  fetchUnreadMessagesCount() {
    get(this.badgeTarget.dataset.url, {
      responseKind: 'html'
    });
  }
}
