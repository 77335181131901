import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['name', 'counter'];

  characterCounter() {
    let maxlength = Number(this.nameTarget.getAttribute('maxlength'));
    let characters = maxlength - this.nameTarget.value.length;
    this.counterTarget.innerText = characters;
  }
}
