import { Controller } from '@hotwired/stimulus';
import BugsnagPerformance from '@bugsnag/browser-performance';
// Connects to data-controller="bugsnag-performance"
export default class extends Controller {
  static values = { apiKey: String, appVersion: String, releaseStage: String };

  connect() {
    if (this.hasApiKeyValue && this.hasAppVersionValue && this.hasReleaseStageValue) {
      this.initializeBugsnagPerformance();
    }
  }

  initializeBugsnagPerformance() {
    BugsnagPerformance.start({
      apiKey: this.apiKeyValue,
      appVersion: this.appVersionValue,
      releaseStage: this.releaseStageValue,
      networkRequestCallback: (networkRequestInfo) => {
        networkRequestInfo.propagateTraceContext = networkRequestInfo.url?.startsWith(
          window.origin
        );
        return networkRequestInfo;
      }
    });
    console.log('Bugsnag Performance initialized');
  }
}
