
// type: 'danger'/etc
// message: plain text
export function showToast(type, message) {
  let container = document.querySelector('#flash');
  let div = document.createElement('div');
  div.innerHTML = `
      <div data-controller="toast" data-toast-target="toast" class="bg-${type} hide">
        <div class="d-flex">
          <div class="toast-body">${message}</div>
        </div>
      </div>
`;
  container.appendChild(div);
}
