import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['header', 'nav', 'create', 'started', 'support'];
  connect() {
    window.addEventListener('scroll', () => {
      this.adjustHeader();
    });
  }

  adjustHeader() {
    let scrollY = window.scrollY;
    let headerHeight = this.headerTarget.offsetHeight;

    if (scrollY >= headerHeight) {
      this.addOutOfView();
    } else {
      this.removeOutOfView();
    }

    if (scrollY >= headerHeight * 2) {
      this.addSticky();
    } else {
      this.removeSticky();
    }
  }

  addSticky() {
    this.headerTarget.classList.add('stick-it');
  }

  removeSticky() {
    this.headerTarget.classList.remove('stick-it');
  }

  addOutOfView() {
    this.headerTarget.classList.add('out-of-view');
    this.navTarget.classList.add('theme-default');
    this.navTarget.classList.remove('theme-transparent');

    if (this.hasCreateTarget) {
      this.createTarget.classList.add('btn-ghost-dark');
      this.createTarget.classList.remove('btn-ghost-light');
    }

    if (this.hasSupportTarget) {
      this.supportTarget.classList.add('btn-ghost-dark');
      this.supportTarget.classList.remove('btn-ghost-light');
    }

    if (this.hasStartedTarget) {
      this.startedTarget.classList.add('btn-primary');
      this.startedTarget.classList.remove('btn-outline-light');
    }
  }

  removeOutOfView() {
    this.headerTarget.classList.remove('out-of-view');
    this.navTarget.classList.add('theme-transparent');
    this.navTarget.classList.remove('theme-default');

    if (this.hasCreateTarget) {
      this.createTarget.classList.add('btn-ghost-light');
      this.createTarget.classList.remove('btn-ghost-dark');
    }

    if (this.hasSupportTarget) {
      this.supportTarget.classList.add('btn-ghost-light');
      this.supportTarget.classList.remove('btn-ghost-dark');
    }

    if (this.hasStartedTarget) {
      this.startedTarget.classList.add('btn-outline-light');
      this.startedTarget.classList.remove('btn-primary');
    }
  }
}
