import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import { searchParamsHelper } from '../helpers';

// Connects to data-controller="datatable-export"
export default class extends Controller {
  onClick(event) {
    let filters = searchParamsHelper.filtersFromParams();
    let date = searchParamsHelper.dateFromFlatpickr(this.flatpickrControllerInstance);

    let target = event.currentTarget;

    post(target.dataset.exportPath, {
      body: {
        filters,
        date
      },
      responseKind: 'turbo-stream'
    });
  }

  get flatpickrControllerInstance() {
    let flatpickrElement = document.querySelector('[data-controller="flatpickr"]', 'flatpickr');
    if (flatpickrElement) {
      return this.application.getControllerForElementAndIdentifier(flatpickrElement, 'flatpickr');
    }

    return null;
  }
}
