import { Controller } from '@hotwired/stimulus';
import { findElement } from '../helpers';

// Connects to data-controller="tab-navigation"
export default class extends Controller {
  static targets = ['next', 'previous', 'submit', 'navUl', 'tabContent'];
  static values = {
    currentIndex: Number,
    idPrefix: String,
    maxIndex: Number
  };

  activeTab = null;
  connect() {
    this.activeTab = this.tabContentTarget.querySelector('.show.active');
  }

  onInputChange() {
    this._disableButtonIfTabIsRequired(this.activeTab);
  }

  goBack() {
    this.currentIndexValue--;
    this.submitTarget.classList.add('d-none');
    this.nextTarget.classList.remove('d-none');
    if (this.currentIndexValue == 1) {
      this.previousTarget.classList.add('d-none');
    }

    this.navUlTarget
      .querySelectorAll('li.nav-item > a')
      .forEach((a) => a.classList.remove('active'));
    this.tabContentTarget.querySelectorAll('.tab-pane').forEach((tab) => {
      tab.classList.remove('show');
      tab.classList.remove('active');
    });
    let [tabToActivate, navItemToActivate] = this._elementsToActivate();
    navItemToActivate.classList.add('active');
    tabToActivate.classList.add('active');
    tabToActivate.classList.add('show');
    this._disableButtonIfTabIsRequired(tabToActivate);
    this.activeTab = tabToActivate;
  }

  goForward() {
    this.currentIndexValue++;
    if (this.currentIndexValue == this.maxIndexValue) {
      this.submitTarget.classList.remove('d-none');
      this.nextTarget.classList.add('d-none');
    }
    this.previousTarget.classList.remove('d-none');
    this.navUlTarget
      .querySelectorAll('li.nav-item > a')
      .forEach((a) => a.classList.remove('active'));
    this.tabContentTarget.querySelectorAll('.tab-pane').forEach((tab) => {
      tab.classList.remove('active');
      tab.classList.remove('show');
    });
    let [tabToActivate, navItemToActivate] = this._elementsToActivate();
    navItemToActivate.classList.add('active');
    tabToActivate.classList.add('active');
    tabToActivate.classList.add('show');
    this._disableButtonIfTabIsRequired(tabToActivate);
    this.activeTab = tabToActivate;
  }

  _elementsToActivate() {
    let idValue = `#${this.idPrefixValue}${this.currentIndexValue}`;
    let tabToActivate = findElement(this.tabContentTarget, idValue);
    let navItemToActivate = findElement(
      this.navUlTarget,
      `.nav-item > a[data-bs-target="${idValue}"]`
    );
    this.activeTab = tabToActivate;
    return [tabToActivate, navItemToActivate];
  }

  _disableButtonIfTabIsRequired(tabToActivate) {
    let enabled;
    if (tabToActivate.dataset.answerType) {
      switch (tabToActivate.dataset.answerType) {
        case 'freeform':
          enabled = !!tabToActivate.querySelector('textarea')?.value;
          break;
        case 'multiple':
          enabled = Array.from(tabToActivate.querySelectorAll('input[type="checkbox"]')).some(
            (check) => check.checked
          );
          break;
        case 'single':
          enabled = Array.from(tabToActivate.querySelectorAll('input[type="radio"]')).some(
            (radio) => radio.checked
          );
          break;
        case 'upload':
          enabled = !!tabToActivate.querySelector('ul.dropzone-previews > li');
          break;
        default:
          enabled = false;
          break;
      }
    }
    let required = tabToActivate.dataset.required === 'true';

    if (this.currentIndexValue == this.maxIndexValue) {
      this.submitTarget.disabled = !enabled && required;
    } else {
      this.nextTarget.disabled = !enabled && required;
    }
  }
}
